import React from 'react';
import LoadingComponent from '../../atoms/LoadingComponent';
import { CustomDataTable } from '../DataTable';
import {
  discountFormat,
  addDefaultSrc,
  formatMoney,
  validateString,
} from '../../helper/lib';
import { deleteItems, updateStatusActiveItem } from '../../helper/function';
import { useNavigate, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencil,
  faTrash,
  faEye,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import Switch from 'react-switch';
import { toast } from 'react-toastify';

const ItemsTable = ({
  items,
  page,
  limit,
  total,
  isLoadingData,
  onChangePage,
  onChangeLimit,
  getItemsData,
  setIsLoadingData,
  tsx,
}) => {
  let navigate = useNavigate();
  const columns = [
    {
      name: 'Picture',
      selector: 'picture',
      width: '0px',
      class: [],
    },
    {
      name: 'Name',
      selector: 'name',
      width: '30.5%',
      class: [],
      childClass: [],
    },
    {
      name: 'Category',
      selector: 'category',
      width: '13.5%',
      class: [],
      childClass: [],
    },
    {
      name: 'Price',
      selector: 'price',
      width: '14%',
      class: [],
      childClass: [],
    },
    {
      name: 'Discount',
      selector: 'discount',
      width: '9%',
      class: [],
      childClass: [],
    },
    {
      name: 'Stock',
      selector: 'stock',
      width: '9%',
      class: [],
      childClass: [],
    },
    {
      name: 'Active Status',
      selector: 'status',
      width: '10%',
      class: [],
      childClass: [],
    },
    {
      name: 'Options',
      selector: 'options',
      width: '0px',
      class: [],
      childClass: ['visually-hidden'],
    },
  ];

  const deleteItem = async (id) => {
    const response = await deleteItems(id);
    if (response.message === 'Success') navigate('/items');
    window.location.reload();
  };

  const handleChangeStatus = async (checked, event, id) => {
    const loadingToast = toast.loading('Updating...');
    // setIsLoadingData(true)

    // Hit Api
    let payload = {
      isActive: checked,
      productId: parseInt(id),
    };

    try {
      const response = await updateStatusActiveItem(payload);
      if (response.message === 'Success') {
        toast.update(loadingToast, {
          render: 'Product active status updated',
          type: 'success',
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
        getItemsData();
      } else {
        toast.update(loadingToast, {
          render: response?.message ?? 'Something when wrong.',
          type: 'error',
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
        getItemsData();
      }
    } catch (error) {
      toast.update(loadingToast, {
        render: error?.message ?? 'Something when wrong.',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }

    // setIsLoadingData(false)
  };

  return (
    <CustomDataTable
      columns={columns}
      items={items}
      page={page}
      limit={limit}
      total={total}
      isLoadingData={isLoadingData}
      onChangePage={onChangePage}
      onChangeLimit={onChangeLimit}
    >
      {isLoadingData ? (
        <tr>
          <td
            style={{ background: 'white', height: '200px' }}
            colSpan={8}
            rowSpan={3}
          >
            <LoadingComponent />
          </td>
        </tr>
      ) : (
        items.map((item, index) => {
          return (
            <tr key={index}>
              <td>
                <div className="img-wrap img-items overflow-hidden rounded">
                  <img
                    src={
                      item.product_images.length > 0
                        ? `${item.product_images[0].thumbnail_image_url}?tsx=${tsx}`
                        : '/dist/img/brokenImage.jpg'
                    }
                    alt="banner"
                    onError={addDefaultSrc}
                    className="object-fit-cover"
                  />
                </div>
              </td>
              <td>
                <span
                  data-toggle="tooltip"
                  data-placement="top"
                  title={validateString(item.name)}
                >
                  {validateString(
                    item?.name?.length > 50
                      ? item?.name?.substring(0, 50) + '...'
                      : item.name
                  )}
                </span>
              </td>
              <td>{validateString(item.category_name)}</td>
              <td>{formatMoney(item.price)}</td>
              <td>
                {item?.discount_type === 1
                  ? formatMoney(item.discount)
                  : discountFormat(item.discount)}
              </td>
              <td>{validateString(item.total_stock)}</td>
              <td>
                <Switch
                  onChange={handleChangeStatus}
                  checked={item.is_active}
                  id={item.id}
                />
              </td>
              <td>
                <div>
                  <NavLink
                    style={{ margin: '2px' }}
                    to={`/items/edit/${item.id}`}
                    className="btn btn-sm btn-warning"
                    data-bs-toggle="tooltip"
                    title="Edit"
                  >
                    <FontAwesomeIcon icon={faPencil} color="white" />
                  </NavLink>
                  <NavLink
                    style={{ margin: '2px' }}
                    to={`/items/detail/${item.id}`}
                    className="btn btn-sm btn-info"
                    data-bs-toggle="tooltip"
                    title="Detail"
                  >
                    <FontAwesomeIcon icon={faEye} color="white" />
                  </NavLink>
                </div>

                <div>
                  <NavLink
                    style={{ margin: '2px' }}
                    to={`/items/edit/${item.id}?list-varian-only=1`}
                    className="btn btn-sm btn-fill"
                    data-bs-toggle="tooltip"
                    title="Varian"
                  >
                    <FontAwesomeIcon icon={faList} color="white" />
                  </NavLink>
                  <a
                    style={{ margin: '2px' }}
                    onClick={deleteItem.bind(this, item?.id)}
                    className="btn btn-sm btn-danger"
                    data-bs-toggle="tooltip"
                    title="Delete"
                  >
                    <FontAwesomeIcon icon={faTrash} color="white" />
                  </a>
                </div>
              </td>
            </tr>
          );
        })
      )}
    </CustomDataTable>
  );
};

export default ItemsTable;
