/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { useNavigate, Outlet, useLocation, NavLink } from 'react-router-dom';
import { getProfile } from './helper/function';
import { capitalizeEachWord, addDefaultSrc } from './helper/lib';
import { getNotificationTop } from './helper/function';
import { fetchApiGet } from './helper/api';
import { requestPermission, onMessageListener } from './firebase';
import QB from 'quickblox/quickblox';

import './LayoutCss.css';

import $ from 'jquery';
import Skeleton from 'react-loading-skeleton';
const Layout = (props) => {
  const location = useLocation();
  const [isLogged, setIslogged] = useState(false);
  const locationPathOne = location.pathname.split('/')[1];
  const locationPathTwo = location.pathname.split('/')[2];
  const uriPath = locationPathOne === 'designer' ? `/${locationPathOne}/` : '';
  const [currentPageType, setCurrentPageType] = useState(locationPathOne);

  const navigate = useNavigate();

  const getNotificationList = async () => {
    console.log('currentPageType:', currentPageType);
    let res = await getNotificationTop(
      currentPageType === 'designer' ? 2 : 1,
      0
    );
    if (res.meta != null) {
      props.changeDataNotification(res);
      props.setLoading(false);
    } else {
      if (res.message && res.message === 'Unauthorized access') {
        setIslogged(false);
        localStorage.removeItem('token');
        navigate('/login');
        return;
      } else {
        props.changeDataNotification(null);
      }
    }
  };

  const getUserData = async () => {
    const res = await getProfile();

    if (res.meta.total > 0) {
      props.changeDataProfile(res.data);
      props.setLoading(false);
    } else {
      console.error({ res });
      if (res.message && res.message === 'Unauthorized access') {
        setIslogged(false);
        localStorage.removeItem('token');
        navigate('/login');
        return;
      }
    }
  };

  const capitalizeStr = capitalizeEachWord;

  const handleLogout = async () => {
    const res = await fetchApiGet('merchants/session/logout');
    if (res.status === 200 || res.ok) {
      localStorage.removeItem('token');
      props.changeQbNewMessage(null);
      props.changeDataNotification(null);
      props.changeDataProfile(null);
      props.changeQbSession(null);
      props.changeQbUnread(null);
      props.setLoading(false);
      setIslogged(false);
      navigate('/login');
    }
  };

  const conectChatting = async () => {
    let session = QB.service.getSession();
    let userId = session.user_id;
    let password = session.token;
    let params = { userId, password };

    QB.chat.connect(params, function (error, contactList) {
      // Event when get message
      QB.chat.onMessageListener = function (userId, message) {
        console.log('new messages:', userId, message);
        props.changeQbNewMessage({
          sender_id: userId,
          message: message?.body,
          date_sent: parseInt(message?.extension?.date_sent),
          attachments: message?.extension?.attachments ?? [],
        });
      };

      // Event when message on sent
      QB.chat.onSentMessageCallback = function (messageLost, messageSent) {};

      // Event when message on delivered
      QB.chat.onDeliveredStatusListener = function (
        messageId,
        dialogId,
        userId
      ) {};
    });
  };

  const fetchDialogs = async () => {
    let params = {
      created_at: {
        lt: Date.now() / 1000,
      },
      sort_desc: 'last_message_date_sent',
      limit: 10,
    };

    QB.chat.dialog.list(params, function (error, dialogs) {
      // Sukses Get List dialog
      if (error === null) {
        console.log('DIALOGS:', dialogs);
        let unread = 0;
        if (dialogs?.items?.length) {
          for (let i = 0; i < dialogs.items.length; ++i) {
            if (dialogs.items[i].unread_messages_count) {
              unread += dialogs.items[i].unread_messages_count;
            }
          }
        }
        props.changeQbUnread(unread);
        console.log('unread:', unread);
      }
    });
  };

  const handleClickMenuCollapse = () => {
    if ($('div.menubar-enabled').hasClass('menubar-collapsed')) {
      $('div.menubar-enabled').removeClass('menubar-collapsed');
    } else {
      $('div.menubar-enabled').addClass('menubar-collapsed');
    }
  };

  const checkTokenExpired = async () => {
    const response = await getProfile();
    if (response?.message === 'Unauthorized access') {
      return false;
    }

    return true;
  };

  const changeCurrentPageType = (p) => {
    setCurrentPageType(p);
  };

  const _navigateToDetail = (orderNo, id) => {
    if (locationPathOne === 'designer') {
      if (orderNo) {
        navigate(`/designer/project/view/${orderNo}`);
      }
    } else {
      if (orderNo && id) {
        let slug = orderNo.replace(/\//g, '-') + '-' + id;
        navigate(`/orders/detail/${slug}/`);
      }
    }
  };

  const handleClickNotifItem = (item) => {
    _navigateToDetail(item.order_number, item.order_id);
  };

  const menu = () => {
    return locationPathOne !== 'designer' ? (
      <>
        <li
          className={`menu-item ${
            locationPathOne === '' ? 'active' : ''
          } d-flex justify-content-center`}
        >
          <NavLink end to="/" className="menu-link">
            <div>
              <img alt="" src={'/dist/img/custom/home.svg'} />
              <span className="menu-label">Dashboard</span>
            </div>
          </NavLink>
        </li>
        <li
          className={`menu-item ${
            locationPathOne === 'orders' ? 'active' : ''
          } d-flex justify-content-center`}
        >
          <NavLink to="/orders" className="menu-link">
            <div>
              <img alt="" src={'/dist/img/custom/shopping-cart.svg'} />
              <span className="menu-label">Orders</span>
            </div>
          </NavLink>
        </li>
        <li
          className={`menu-item ${
            locationPathOne === 'items' ? 'active' : ''
          } d-flex justify-content-center`}
        >
          <NavLink to="/items" className="menu-link">
            <div>
              <img alt="" src={'/dist/img/custom/armchair-menu.svg'} />
              <span className="menu-label">Products</span>
            </div>
          </NavLink>
        </li>
        <li
          className={`menu-item ${
            locationPathOne === 'item-review' ? 'active' : ''
          } d-flex justify-content-center`}
        >
          <NavLink to="/item-review" className="menu-link">
            <div>
              <img alt="" src={'/dist/img/custom/notepad.svg'} />
              <span className="menu-label">Product Review</span>
            </div>
          </NavLink>
        </li>
        <li
          className={`menu-item ${
            locationPathOne === 'promotions' ? 'active' : ''
          } d-flex justify-content-center`}
        >
          <NavLink to="/promotions" className="menu-link">
            <div>
              <img alt="" src={'/dist/img/custom/tag.svg'} />
              <span className="menu-label">Promotions</span>
            </div>
          </NavLink>
        </li>
        <li
          className={`menu-item ${
            locationPathOne === 'transactions' ? 'active' : ''
          } d-flex justify-content-center`}
        >
          <NavLink to="/transactions" className="menu-link">
            <div>
              <img alt="" src={'/dist/img/custom/transaction.svg'} />
              <span className="menu-label">Transactions</span>
            </div>
          </NavLink>
        </li>
      </>
    ) : (
      <>
        <li
          className={`menu-item ${
            locationPathTwo === '' || locationPathTwo === undefined
              ? 'active'
              : ''
          } d-flex justify-content-center`}
        >
          <NavLink end to="designer/" className="menu-link">
            <div>
              <img alt="" src={'/dist/img/custom/home.svg'} />
              <span className="menu-label">Dashboard</span>
            </div>
          </NavLink>
        </li>
        <li
          className={`menu-item ${
            locationPathTwo === 'project' ? 'active' : ''
          } d-flex justify-content-center`}
        >
          <NavLink to="designer/project" className="menu-link">
            <div>
              <img alt="" src={'/dist/img/custom/mail.svg'} />
              <span className="menu-label">Project</span>
            </div>
          </NavLink>
        </li>
        <li
          className={`menu-item ${
            locationPathTwo === 'portfolio' ? 'active' : ''
          } d-flex justify-content-center`}
        >
          <NavLink to="designer/portfolio" className="menu-link">
            <div>
              <img alt="" src={'/dist/img/custom/palette.svg'} />
              <span className="menu-label">Portofolio</span>
            </div>
          </NavLink>
        </li>
        <li
          className={`menu-item ${
            locationPathTwo === 'project-review' ? 'active' : ''
          } d-flex justify-content-center`}
        >
          <NavLink to="designer/project-review" className="menu-link">
            <div>
              <img alt="" src={'/dist/img/custom/notepad.svg'} />
              <span className="menu-label">Project Review</span>
            </div>
          </NavLink>
        </li>
        <li
          className={`menu-item ${
            locationPathTwo === 'transactions' ||
            locationPathTwo === 'requestdisbursement'
              ? 'active'
              : ''
          } d-flex justify-content-center`}
        >
          <NavLink to="designer/transactions" className="menu-link">
            <div>
              <img alt="" src={'/dist/img/custom/transaction.svg'} />
              <span className="menu-label">Transactions</span>
            </div>
          </NavLink>
        </li>
      </>
    );
  };

  useEffect(() => {
    if (props.dataProfile && isLogged) {
      if (!props.qbSession) {
        console.log('QB init...');
        // Initialize QuickBlox SDK
        QB.init(
          process.env.REACT_APP_QB_APPLICATION_ID,
          process.env.REACT_APP_QB_AUTH_KEY,
          process.env.REACT_APP_QB_AUTH_SECRET,
          process.env.REACT_APP_QB_ACCOUNT_KEY
        );

        // Create Session to QuickBlox
        if (props?.dataProfile?.id !== undefined) {
          QB.createSession(
            {
              login: `${process.env.REACT_APP_QB_MPREFIX}${props?.dataProfile?.id}`,
              password: '12345678Ks@',
            },
            function (error, result) {
              // Sukses Create session
              if (error === null) {
                console.log('.. sukses');
                props.changeQbSession(result);
                fetchDialogs();
                conectChatting();
              }
            }
          );
        }
      }
    }
  }, [props.dataProfile, isLogged, props.qbSession]);

  useEffect(() => {
    if (props.qbNewMessage) {
      if (locationPathOne !== 'messages') {
        let unread = props.qbUnread;
        ++unread;
        console.log('unread:', unread);
        props.changeQbUnread(unread);
      }
    }
  }, [props.qbNewMessage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    console.log('use effect props.qbUnread:', props.qbUnread);
  }, [props.qbUnread]);

  useEffect(() => {
    if (!isLogged) {
      return;
    }

    getNotificationList();

    // Foreground message
    const unsubscribe = onMessageListener().then((payload) => {
      console.log('FCM Payload:', payload);
      console.log('currentPageType:', currentPageType);
      getNotificationList();
    });
    unsubscribe.catch((err) => console.log('FCM failed: ', err));

    // Background message
    const broadcast = new BroadcastChannel('background-message');
    const onBroadcastMessage = (event) => {
      console.log('message from service-worker:', event.data);
      console.log('currentPageType:', currentPageType);
      getNotificationList();
    };
    broadcast.addEventListener('message', onBroadcastMessage);
    return () => {
      broadcast.removeEventListener('message', onBroadcastMessage);
      broadcast.close();
    };
  }, [isLogged, currentPageType]);

  useEffect(() => {
    props.setLoading(true);
    const tokenValue = localStorage.getItem('token');

    if (tokenValue) {
      if (checkTokenExpired) {
        setIslogged(true);
        getNotificationList();
        getUserData();
        console.log('masuk request layout');
        requestPermission();

        props.setLoading(false);
        return;
      }

      props.setLoading(false);
      navigate('/login');
      return;
    }

    navigate('/login');
  }, []);

  useEffect(() => {
    if (window.innerWidth < 960) {
      $('div.menubar-enabled').removeClass('menubar-collapsed');
    } else {
      $('div.menubar-enabled').addClass('menubar-collapsed');
    }

    $(window).resize(function () {
      if (window.innerWidth < 960) {
        $('div.menubar-enabled').removeClass('menubar-collapsed');
      } else {
        $('div.menubar-enabled').addClass('menubar-collapsed');
      }
    });
  }, []);

  useEffect(() => {
    if (props.dataProfile) {
      if (props.dataProfile?.is_complete_profile === false) {
        navigate('/fill-profile-form');
        return;
      }
      if (props.dataProfile?.status === 'APPROVED') {
        if (props.dataProfile?.merchant_type === 'DESIGNER') {
          changeCurrentPageType('designer');
          if (location.pathname === '/' || location.pathname === '/caution') {
            navigate('/designer');
          }
        }
        return;
      }
      navigate('/caution');
    }
  }, [props.dataProfile]);

  if (isLogged) {
    return (
      <div
        style={
          props.loadingGeneral === false
            ? { display: 'block' }
            : { display: 'none' }
        }
      >
        <div className="menubar-enabled wrapper-layout">
          <div
            className="offcanvas offcanvas-star"
            tabIndex="-1"
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
            data-bs-backdrop="false"
          >
            <div className="offcanvas-body h-100 w-100 p-0">
              <div className="menubar d-block bg-white position-relative h-100 w-100">
                <ul className="menu accordion">{menu()}</ul>
              </div>
            </div>
          </div>
          <div className="wrapper px-4">
            <div className="main-navbar navbar-expand-md navbar-light ms-0">
              <div
                className="collapse navbar-collapse d-flex"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto hidden-sm-down w-100 bg-transparent justify-content-start justify-md-content-center align-items-center">
                  <li className="nav-item me-2 d-flex d-lg-none">
                    <a
                      className="font-2xl rounded-circle"
                      data-bs-toggle="offcanvas"
                      href="#offcanvasExample"
                      role="button"
                      aria-controls="offcanvasExample"
                    >
                      <img
                        alt=""
                        src={'/dist/img/custom/list.svg'}
                        className="icon-b-4"
                      />
                    </a>
                  </li>
                  <li className="nav-item me-2 d-flex d-lg-none">
                    <a className="menubar-brand" href="#">
                      <img
                        alt=""
                        src={
                          props.dataProfile?.logo.length > 0
                            ? props.dataProfile?.logo
                            : '/dist/img/brokenImage.jpg'
                        }
                        onError={addDefaultSrc}
                        title="Kisahruang"
                      />
                    </a>
                  </li>

                  <li className="nav-item position-relative d-none d-lg-block">
                    {props.dataProfile ? (
                      <div className="fs-4 fw-bold">
                        Hello,{' '}
                        {capitalizeStr(
                          props.dataProfile?.name === '' ||
                            !props.dataProfile?.name
                            ? props.dataProfile?.alias === '' ||
                              !props.dataProfile?.alias
                              ? props.dataProfile?.email === '' ||
                                !props.dataProfile?.email
                                ? ''
                                : props.dataProfile?.email
                              : props.dataProfile?.alias
                            : props.dataProfile?.name
                        )}
                      </div>
                    ) : (
                      <div className="fs-4 fw-bold">
                        <Skeleton width={200} />
                      </div>
                    )}
                    {props.dataProfile ? (
                      <div className="text-secondary">Welcome Back!</div>
                    ) : (
                      <Skeleton width={150} />
                    )}
                  </li>
                </ul>

                <ul className="navbar-nav bg-transparent d-flex align-items-center">
                  <li className="nav-item me-2 me-sm-4 position-relative">
                    {/* <span className="badge rounded-circle p-1 bg-danger text-danger notif">
                      <div>0</div>
                    </span> */}
                    <NavLink
                      to={`${uriPath}seller-help`}
                      className="font-2xl rounded-circle"
                      href="#"
                    >
                      <img
                        alt=""
                        src={'/dist/img/custom/chat.svg'}
                        className="icon-b-4"
                      />
                    </NavLink>
                  </li>

                  <li className="nav-item me-2 me-sm-4 dropdown">
                    <a
                      className="font-2xl rounded-circle"
                      href="#"
                      id="QBDX05"
                      role="button"
                      data-toggle="dropdown"
                      aria-expanded="false"
                      data-flip="false"
                    >
                      <img
                        alt=""
                        src={'/dist/img/custom/bell.svg'}
                        className="icon-b-4"
                      />
                      <span
                        className={`badge rounded-circle p-1 bg-danger text-danger notif ${
                          props.dataNotification &&
                          props.dataNotification.meta &&
                          props.dataNotification.meta.total > 0
                            ? ''
                            : 'd-none'
                        }`}
                      >
                        <div>
                          {props.dataNotification &&
                          props.dataNotification.meta &&
                          props.dataNotification.meta.total > 0
                            ? props.dataNotification.meta.total
                            : null}
                        </div>
                      </span>
                    </a>

                    <div
                      className="dropdown-menu dropdown-menu-right p-0 border-0 w-300 will-change-0"
                      style={{ width: '300px' }}
                      aria-labelledby="QBDX05"
                    >
                      <div className="card shadow-base rounded-3 notification">
                        <div
                          className="perfect-scrollbar position-relative overflow-auto"
                          style={{ maxHeight: '400px' }}
                        >
                          {props.dataNotification?.data?.length > 0 ? (
                            <ul className="list-style-none p-3">
                              {props.dataNotification.data
                                ?.slice(0, 5)
                                .sort(
                                  (a, b) => new Date(b.date) - new Date(a.date)
                                )
                                .map((item) => {
                                  return (
                                    <li
                                      key={item.id}
                                      onClick={() => handleClickNotifItem(item)}
                                      className="list-popup-notif"
                                    >
                                      <div className="fw-bold fz-14">
                                        {item.title}
                                      </div>
                                      <div className="fz-12 mt-2">
                                        {item.content.length > 80
                                          ? item.content.substring(0, 80) +
                                            '...'
                                          : item.content}
                                      </div>
                                    </li>
                                  );
                                })}
                            </ul>
                          ) : (
                            <div className="d-flex justify-content-center align-items-center p-3">
                              There is no new notification.
                            </div>
                          )}
                        </div>
                        <div className="p-3 bg-white">
                          <NavLink
                            to={`${uriPath}notifications`}
                            className="btn fz-14 btn-fill fw-bolder w-100"
                            href="./notification.html"
                          >
                            View All
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="nav-item me-2 me-sm-4 position-relative">
                    {locationPathOne === 'designer' ? (
                      <NavLink
                        to={`/designer/messages`}
                        className="font-2xl rounded-circle"
                        href="#"
                      >
                        <img
                          alt=""
                          src={'/dist/img/custom/chat-only-2.svg'}
                          className="icon-b-4"
                          style={{ width: '1.3rem' }}
                        />
                        {props.qbUnread ? (
                          <span className="badge rounded-circle p-1 bg-danger text-danger notif">
                            {props.qbUnread}
                          </span>
                        ) : (
                          ''
                        )}
                      </NavLink>
                    ) : (
                      <NavLink
                        to={`/messages`}
                        className="font-2xl rounded-circle"
                        href="#"
                      >
                        <img
                          alt=""
                          src={'/dist/img/custom/chat-only-2.svg'}
                          className="icon-b-4"
                          style={{ width: '1.3rem' }}
                        />
                        {props.qbUnread ? (
                          <span className="badge rounded-circle p-1 bg-danger text-danger notif">
                            {props.qbUnread}
                          </span>
                        ) : (
                          ''
                        )}
                      </NavLink>
                    )}
                  </li>

                  <li className="nav-item dropdown">
                    <a
                      href="#"
                      className="nav-icon av-circle avatar rounded-circle p-0 overflow-hidden object-fit-cover"
                      id="PJXN7R"
                      role="button"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        alt=""
                        src={
                          props.dataProfile?.logo !== '' &&
                          props.dataProfile?.logo
                            ? props.dataProfile?.logo
                            : '/dist/img/brokenImage.jpg'
                        }
                        onError={addDefaultSrc}
                        className="h-100 object-fit-cover"
                      />
                    </a>

                    <div
                      className="dropdown-menu dropdown-menu-right shadow-sm py-2 px-3"
                      aria-labelledby="PJXN7R"
                    >
                      {props.dataProfile?.merchant_type?.toLowerCase() ===
                        'vendor + designer' && (
                        <>
                          {locationPathOne === 'designer' ? (
                            <NavLink
                              to="/"
                              className="dropdown-item fz-14 fw-bold"
                              onClick={() => {
                                changeCurrentPageType('');
                              }}
                            >
                              Go to Vendor Mode
                            </NavLink>
                          ) : (
                            <NavLink
                              to="designer"
                              className="dropdown-item fz-14 fw-bold"
                              onClick={() => {
                                changeCurrentPageType('designer');
                              }}
                            >
                              Go to Designer Mode
                            </NavLink>
                          )}
                          <div className="dropdown-divider"></div>
                        </>
                      )}
                      <NavLink
                        to={`${uriPath}profile`}
                        className="dropdown-item fz-14"
                      >
                        Profile
                      </NavLink>
                      <div className="dropdown-divider"></div>
                      <button
                        id="logoutBtn"
                        onClick={handleLogout}
                        className="dropdown-item fz-14"
                      >
                        Logout
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <Outlet />

            <div
              className="btn p-0 menubar border-0 d-none d-lg-flex shadow-sm"
              id="main-menu"
            >
              <div
                className="menubar-header py-3 w-100"
                onClick={handleClickMenuCollapse}
              >
                <a className="menubar-brand" href="#">
                  <img
                    alt=""
                    src={'/dist/img/logo_kisahruang.png'}
                    title="Kisahruang"
                    className="menubar-logo menubar-label"
                  />
                </a>
                <li className="nav-item d-flex align-items-center justify-content-center btn-menu-control">
                  <a
                    href="#"
                    className="nav-icon font-2xl rounded-circle border-circle"
                  >
                    <img
                      alt=""
                      src={'/dist/img/custom/close.svg'}
                      className="menubar-logo"
                    />
                  </a>
                </li>
              </div>

              <div className="menubar-body d-flex justify-content-center w-100">
                <ul className="menu accordion">{menu()}</ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const mapState = (state) => {
  return {
    dataProfile: state.dataProfile,
    dataNotification: state.dataNotification,
    loadingGeneral: state.loadingGeneral,
    qbSession: state.qbSession,
    qbUnread: state.qbUnread,
    qbNewMessage: state.qbNewMessage,
  };
};

const actionCreators = (dispatch) => {
  return {
    changeDataProfile: (data) =>
      dispatch({ type: 'CHANGE_DATA_PROFILE', payload: data }),
    changeDataNotification: (data) =>
      dispatch({ type: 'CHANGE_DATA_NOTIFICATION', payload: data }),
    setLoading: (data) => dispatch({ type: 'CHANGE_LOADING', payload: data }),
    changeQbSession: (data) =>
      dispatch({ type: 'CHANGE_QB_SESSION', payload: data }),
    changeQbUnread: (data) =>
      dispatch({ type: 'CHANGE_QB_UNREAD', payload: data }),
    changeQbNewMessage: (data) =>
      dispatch({ type: 'CHANGE_QB_NEW_MESSAGE', payload: data }),
  };
};
export default connect(mapState, actionCreators)(Layout);
