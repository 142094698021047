import React from 'react';
import { useEffect, useState, useRef } from 'react';
import './MessagesCss.css';
import PreviewImage from '../../organism/Images/PreviewImage';
import { connect } from 'react-redux';
import {
  validateString,
  isJsonString,
  formatMoney,
  formatDate,
} from '../../helper/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import LoadingComponent from '../../atoms/LoadingComponent';
import QB from 'quickblox/quickblox';

import moment from 'moment';
import 'moment/locale/id';
import { NavLink } from 'react-router-dom';
moment.locale('id');

const Messages = (props) => {
  const messageListRef = useRef(null);
  const [dialogs, setDialogs] = useState([]);
  const [selectedDialog, setSelectedDialog] = useState(null);
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState('');
  const [session, setSession] = useState(null);
  const [isRetrievingMessage, setIsRetrievingMessage] = useState(true);
  const [imageViewUrl, setImageViewUrl] = useState('');
  const [imageAttachments, setImageAttachments] = useState([]);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isLoadMoring, setIsLoadMoring] = useState(false);

  const fetchDialogMessages = (showLoading) => {
    if (selectedDialog?._id !== undefined) {
      if (showLoading === true || showLoading === undefined) {
        setIsRetrievingMessage(true);
      }
      let params = {
        chat_dialog_id: selectedDialog?._id,
        sort_desc: 'date_sent',
        limit: 20,
        skip: 0,
      };

      QB.chat.message.list(params, function (error, messages) {
        // Sukses Get List messages
        if (error === null && messages?.items) {
          console.log('MSG:', messages?.items);
          setMessages(
            messages?.items.sort((a, b) => {
              return a.date_sent - b.date_sent; // Sort in ascending order based on date sent
            })
          );
        }
        if (showLoading === true || showLoading === undefined) {
          setIsRetrievingMessage(false);
        }
      });
    }
  };

  const fetchDialogs = async () => {
    let params = {
      created_at: {
        lt: Date.now() / 1000,
      },
      sort_desc: 'last_message_date_sent',
      limit: 10,
    };

    QB.chat.dialog.list(params, function (error, dialogs) {
      // Sukses Get List dialog
      if (error === null) {
        // list only non-null
        let activeDialogs = [];
        if (dialogs?.items) {
          for (let i = 0; i < dialogs.items.length; ++i) {
            // if (dialogs.items[i].last_message) {
            //   activeDialogs.push(dialogs.items[i]);

            // }
            activeDialogs.push(dialogs.items[i]);
          }
        }

        setDialogs(activeDialogs);

        if (activeDialogs.length > 0) {
          console.log('QB.chat.dialog.list DIALOG:', activeDialogs);
          if (selectedDialog) {
            // do nothing
          } else {
            setSelectedDialog(activeDialogs[0] ?? null);
          }
        } else {
          setIsRetrievingMessage(false);
          console.log(messages?.length, { messages });
        }
      }
    });
  };

  const handleSendMessage = async () => {
    setIsSendingMessage(true);
    let attachments = [];
    try {
      await Promise.all(
        imageAttachments.map(async (imageAttachment) => {
          const fileParams = {
            name: imageAttachment.name,
            file: imageAttachment.file,
            type: imageAttachment.type,
            size: imageAttachment.size,
            public: true,
          };

          try {
            const result = await new Promise((resolve, reject) => {
              QB.content.createAndUpload(fileParams, (error, result) => {
                if (error) {
                  reject(error);
                } else {
                  resolve(result);
                }
              });
            });

            attachments.push({
              id: result.uid,
              type: 'PHOTO',
              'content-type': fileParams?.type,
            });
          } catch (error) {
            console.error('Error uploading attachment:', error);
          }
        })
      );

      const message = {
        type: 'chat',
        body: text ?? '',
        extension: {
          save_to_history: 1,
          dialog_id: selectedDialog._id,
          attachments: attachments,
        },
      };

      message.id = await QB.chat.send(selectedDialog.user_id, message);
      setText('');
      setImageAttachments([]);

      if (message.id) {
        fetchDialogMessages(false);
      }
      setIsSendingMessage(false);
    } catch (error) {
      setIsSendingMessage(false);
      if (error.name === 'ChatNotConnectedError') {
        // Not connected to chat
      } else {
        console.error('Error sending message:', error);
      }
    }
  };

  const handleSendMessageWithEnter = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleChangeDialog = async (dialog) => {
    console.log('handleChangeDialog DIALOG:', dialog);
    setSelectedDialog(dialog);
  };

  const handleImageUpload = async (event) => {
    setImageAttachments([
      ...imageAttachments,
      {
        id: Date.now(),
        name: event?.target?.files[0]?.name,
        file: event?.target?.files[0],
        type: event?.target?.files[0]?.type,
        size: event?.target?.files[0]?.size,
        preview: URL.createObjectURL(event.target.files[0]),
      },
    ]);

    event.target.type = 'text';
    event.target.type = 'file';
  };

  const clearImageSelected = (idImage) => {
    const filtered = imageAttachments.filter((imageAttach, i) => {
      return imageAttach?.id !== idImage;
    });
    setImageAttachments(filtered);
  };

  const handleLoadMore = async () => {
    if (selectedDialog?._id !== undefined) {
      setIsLoadMoring(true);
      let params = {
        chat_dialog_id: selectedDialog?._id,
        sort_desc: 'date_sent',
        limit: 20,
        skip: messages?.length,
      };

      QB.chat.message.list(params, function (error, messageResponse) {
        // Sukses Get List messages
        if (error === null && messageResponse?.items) {
          let newMessages = messageResponse?.items.sort((a, b) => {
            return a.date_sent - b.date_sent; // Sort in ascending order based on date sent
          });
          setMessages([...newMessages, ...messages]);
        }

        setIsLoadMoring(false);
      });
    }
  };

  useEffect(() => {
    setSession(props.qbSession);
    if (props.qbSession) {
      fetchDialogs();
    }
  }, [props.qbSession]);

  useEffect(() => {
    // Scroll chat list to bottom
    if (messageListRef.current) {
      const scrollElement = messageListRef.current;
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }
  }, [messages]);

  const updateQbUnreadAll = () => {
    // hitung ulang all unread
    if (dialogs?.length) {
      let unread = 0;
      for (let i = 0; i < dialogs.length; ++i) {
        unread += dialogs[i].unread_messages_count;
      }
      console.log('unread new:', unread);
      props.changeQbUnread(unread);
    }
  };

  useEffect(() => {
    fetchDialogMessages();

    if (selectedDialog) {
      console.log('Selected Dialog:', selectedDialog);
      let tmpDialogs = [...dialogs];
      const dlgIndex = dialogs.findIndex(
        (x) => x.user_id == selectedDialog.user_id
      );
      let tempDialogItem = dialogs[dlgIndex];
      tempDialogItem.unread_messages_count = 0; // clear unread
      tmpDialogs[dlgIndex] = tempDialogItem;
      setDialogs(tmpDialogs);
      console.log('DIALOGS NEW (selected):', dialogs);
      // hitung ulang all unread
      updateQbUnreadAll();
    }
  }, [selectedDialog]);

  useEffect(() => {
    if (
      selectedDialog &&
      selectedDialog.user_id &&
      props.qbNewMessage &&
      props.qbNewMessage.sender_id
    ) {
      if (selectedDialog.user_id == props.qbNewMessage.sender_id) {
        setMessages([...messages, props.qbNewMessage]);
      } else {
        let tmpDialogs = [...dialogs];
        const dlgIndex = dialogs.findIndex(
          (x) => x.user_id == props.qbNewMessage.sender_id
        );
        if (dlgIndex == -1) {
          fetchDialogs();
        } else {
          let tempDialogItem = dialogs[dlgIndex];
          console.log('tempDialogItem:', tempDialogItem);

          if (props.qbNewMessage.attachments?.length > 0) {
            tempDialogItem.last_message = '${Attachment}';
          } else {
            tempDialogItem.last_message = props.qbNewMessage.message;
          }
          tempDialogItem.last_message_date_sent = props.qbNewMessage.date_sent;
          tempDialogItem.unread_messages_count++;
          tmpDialogs[dlgIndex] = tempDialogItem;
          setDialogs(tmpDialogs);
          console.log('DIALOGS NEW:', dialogs);
          // hitung ulang all unread
          updateQbUnreadAll();
        }
      }
    }
  }, [props.qbNewMessage]);

  /*
    useEffect(() => {
        // hitung ulang all unread selain dialog aktif
        console.log("masuk:", dialogs, selectedDialog.user_id);
        let unread = 0;
        if (dialogs?.items?.length) {
          for (let i=0; i<dialogs.items.length; ++i) {
            if (dialogs.items[i].unread_messages_count && dialogs.items[i].user_id != selectedDialog.user_id) {
              unread += dialogs.items[i].unread_messages_count;
            }
          }
        }
        console.log("unread new:", unread);
        props.changeQbUnread(unread);
    }, [dialogs]);
    */

  return (
    <div className="containerMessage">
      <div className="contactList">
        {dialogs?.map((dialog, index) => {
          return selectedDialog?._id === dialog?._id ? (
            <div className={`contactBox bg-white`} key={index}>
              <div className="boxInsideLeft">
                <h6 className="boxInsideTop">{dialog?.name}</h6>
              </div>
              <div className="boxInsideRight">&nbsp;</div>
            </div>
          ) : (
            <button
              type="button"
              className={`contactBox ${
                selectedDialog?._id === dialog?._id ? 'selectedDialog' : ''
              }`}
              key={index}
              onClick={() => {
                handleChangeDialog(dialog);
              }}
            >
              <div className="boxInsideLeft">
                <h6 className="boxInsideTop">{dialog?.name}</h6>
                <span>
                  {dialog?.last_message === '${Attachment}' ? (
                    <FontAwesomeIcon icon={faImage} />
                  ) : (
                    validateString(dialog?.last_message)
                  )}
                </span>
              </div>
              <div className="boxInsideRight">
                <p>
                  {dialog?.last_message_date_sent !== null &&
                    moment(dialog?.last_message_date_sent, 'X').format('HH:mm')}
                </p>
                <div>
                  {dialog?.unread_messages_count ? (
                    <span className="badge bg-danger">
                      {dialog?.unread_messages_count}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </button>
          );
        })}
      </div>
      <div
        className={`chatContainer ${
          isRetrievingMessage === true ? 'center-message-list' : ''
        }`}
      >
        {(selectedDialog !== null || true) && (
          <>
            {isRetrievingMessage === true && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <LoadingComponent />
              </div>
            )}

            {isRetrievingMessage === false && selectedDialog !== null && (
              <>
                <div className="chatHeader">
                  <div>
                    <h6>{selectedDialog?.name}</h6>
                    <p>Last online 1 minutes ago</p>
                  </div>
                </div>
                <div className="chatList" ref={messageListRef}>
                  {isLoadMoring === true && <LoadingComponent />}
                  {isLoadMoring === false && (
                    <button
                      type="button"
                      className="chatLabelDate"
                      onClick={handleLoadMore}
                      disabled={isLoadMoring}
                    >
                      Load More
                    </button>
                  )}

                  {messages?.map((message, index) => {
                    return (
                      <div key={index}>
                        {index === 0 && (
                          <div className="chatLabelDate">
                            {message?.date_sent &&
                              formatDate(new Date(message?.date_sent * 1000))}
                          </div>
                        )}

                        {index !== 0 && (
                          <>
                            {formatDate(new Date(message?.date_sent * 1000)) !=
                              formatDate(
                                new Date(messages[index - 1]?.date_sent * 1000)
                              ) && (
                              <div className="chatLabelDate">
                                {message?.date_sent &&
                                  formatDate(
                                    new Date(message?.date_sent * 1000)
                                  )}
                              </div>
                            )}
                          </>
                        )}

                        <div
                          className={`${
                            session?.user_id === message?.sender_id
                              ? 'chatBoxRight'
                              : 'chatBoxLeft'
                          }`}
                          key={index}
                        >
                          <div className="chatInsideLeft">
                            <h6 className="chatInsideTop">
                              {session?.user_id === message?.sender_id
                                ? 'You'
                                : selectedDialog?.name}
                            </h6>
                            <span>
                              {message?.message !== '${Attachment}' &&
                                message?.message}
                            </span>

                            {/* Attachments */}
                            {message?.attachments?.length > 0 && (
                              <>
                                {message?.message ? <hr /> : null}
                                <div className="messageAttachmentContainer">
                                  {message?.attachments?.map(
                                    (attachment, indexAttachment) => (
                                      <div key={indexAttachment}>
                                        {/* Attachment Photo */}
                                        {attachment?.type === 'PHOTO' && (
                                          <a
                                            onClick={() => {
                                              setImageViewUrl(
                                                QB.content.publicUrl(
                                                  attachment?.id
                                                )
                                              );
                                            }}
                                          >
                                            <img
                                              src={QB.content.publicUrl(
                                                attachment?.id
                                              )}
                                              alt="img-attachment"
                                              className="imgAttachment"
                                            ></img>
                                          </a>
                                        )}

                                        {/* Attachment Product */}
                                        {attachment?.type === 'PRODUCT' && (
                                          <>
                                            {isJsonString(attachment?.data) && (
                                              <>
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    border: '1px solid #c4c4c4',
                                                    borderRadius: '10px',
                                                    padding: '10px 20px',
                                                    gap: '15px',
                                                  }}
                                                >
                                                  <a
                                                    onClick={() => {
                                                      setImageViewUrl(
                                                        JSON.parse(
                                                          attachment?.data
                                                        )?.product_images[0]
                                                          ?.full_image_url
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={
                                                        JSON.parse(
                                                          attachment?.data
                                                        )?.product_images[0]
                                                          ?.full_image_url
                                                      }
                                                      alt="img-attachment"
                                                      className="imgAttachment"
                                                    ></img>
                                                  </a>

                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      flexDirection: 'column',
                                                      justifyContent: 'center',
                                                    }}
                                                  >
                                                    <NavLink
                                                      to={`/items/add-new/${
                                                        JSON.parse(
                                                          attachment?.data
                                                        )?.id
                                                      }`}
                                                      style={{
                                                        fontWeight: 'bolder',
                                                      }}
                                                    >
                                                      {
                                                        JSON.parse(
                                                          attachment?.data
                                                        )?.name
                                                      }
                                                    </NavLink>

                                                    {/* Berarti Diskon */}
                                                    {JSON.parse(
                                                      attachment?.data
                                                    )?.price !==
                                                      JSON.parse(
                                                        attachment?.data
                                                      )?.price_display && (
                                                      <>
                                                        <small
                                                          style={{
                                                            textDecoration:
                                                              'line-through red',
                                                            color: 'red',
                                                          }}
                                                        >
                                                          {formatMoney(
                                                            JSON.parse(
                                                              attachment?.data
                                                            )?.price
                                                          )}
                                                        </small>
                                                        <span>
                                                          {formatMoney(
                                                            JSON.parse(
                                                              attachment?.data
                                                            )?.price_display
                                                          )}
                                                        </span>
                                                      </>
                                                    )}

                                                    {/* Berarti tidak diskon */}
                                                    {JSON.parse(
                                                      attachment?.data
                                                    )?.price ===
                                                      JSON.parse(
                                                        attachment?.data
                                                      )?.price_display && (
                                                      <span>
                                                        {formatMoney(
                                                          JSON.parse(
                                                            attachment?.data
                                                          )?.price_display
                                                        )}
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          <div className="chatInsideRight">
                            <p>
                              {moment(message?.date_sent, 'X').format('HH:mm')}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* <div className='chatLabelDate'>
                                        June 2022
                                        </div>
                                        <div className='chatBoxRight'>
                                            <div className='chatInsideLeft'>
                                                <h6 className='chatInsideTop'>Designer</h6>
                                                <span>Halo</span>
                                            </div>
                                            <div className='chatInsideRight'>
                                                <p>11:26</p>
                                                <span className='chatInsideBottom'><img src={`/dist/img/custom/chat-readed.svg`} alt="img-status" className='imgStatusReaded' /></span>
                                            </div>
                                        </div>
                                        <div className='chatBoxLeft'>
                                            <div className='chatInsideLeft'>
                                                <h6 className='chatInsideTop'>Buyer</h6>
                                                <span>Halo</span>
                                            </div>
                                            <div className='chatInsideRight'>
                                                <p>11:26</p>
                                            </div>
                                        </div>
                                        <div className='chatBoxRight'>
                                            <div className='chatInsideLeft'>
                                                <h6 className='chatInsideTop'>Designer</h6>
                                                <span>Halo</span>
                                            </div>
                                            <div className='chatInsideRight'>
                                                <span>11:26</span>
                                                <span className='chatInsideBottom'><img src={`/dist/img/custom/chat-sended.svg`} alt="img-status" className='imgStatusSended' /></span>
                                            </div>
                                        </div> 
                                    */}
                </div>
                <div className="chatInputContainer">
                  {/* preview Uploaded Image */}
                  <div className="previewImageContainer">
                    {imageAttachments?.map((image, indexImage) => (
                      <div key={indexImage}>
                        <div className="border rounded-3 p-1 d-flex align-items-center justify-content-between flex-column">
                          <a
                            onClick={() => {
                              setImageViewUrl(image.preview);
                            }}
                            className="d-flex align-items-center"
                          >
                            <div className="img-wrap w-5rem ar-square rounded-3 overflow-hidden d-flex align-items-center justify-content-center">
                              <div className="w-100 h-100">
                                <img
                                  id="imageId"
                                  src={image.preview}
                                  className="object-fit-cover w-5rem ar-square"
                                  alt="Preview"
                                />
                              </div>
                            </div>
                          </a>
                          <button
                            className="btn p-0"
                            onClick={clearImageSelected.bind(this, image?.id)}
                          >
                            <img
                              src="/dist/img/custom/x.svg"
                              className="icon-3"
                              alt="img-delete"
                            />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Sending Loading Message */}
                  {isSendingMessage && <span>Sending...</span>}

                  <div className="chatInputElement">
                    <div className="dropup">
                      <button
                        type="button"
                        className="btnInsideChat"
                        role="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        data-flip="false"
                        disabled={isSendingMessage}
                      >
                        <img
                          src={`/dist/img/custom/plus.svg`}
                          alt="plus-icon"
                        />
                      </button>
                      <div
                        className="dropdown-menu"
                        style={{ width: 'fit-content' }}
                      >
                        <label htmlFor="file-upload" className="btnInsideChat">
                          <img
                            src="/dist/img/custom/image.svg"
                            className="w-4rem"
                            alt="img-upload"
                          />
                        </label>
                        <input
                          className="visually-hidden"
                          id="file-upload"
                          type="file"
                          accept="image/jpeg,image/jpg,image/png"
                          onChange={handleImageUpload}
                        />
                      </div>
                    </div>
                    <input
                      className="inputMessage"
                      placeholder="Type a message here.."
                      value={text}
                      onChange={(event) => {
                        setText(event.target.value);
                      }}
                      onKeyDown={handleSendMessageWithEnter}
                      disabled={isSendingMessage}
                    />
                    <button
                      type="button"
                      className="btnInsideChat"
                      onClick={handleSendMessage}
                      disabled={isSendingMessage}
                    >
                      <img
                        src={`/dist/img/custom/paperplane.svg`}
                        alt="plus-icon"
                      />
                    </button>
                  </div>
                </div>
              </>
            )}

            {isRetrievingMessage === false && selectedDialog === null && (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  <h5>There is no one currently available in the chat.</h5>
                </div>
              </>
            )}
          </>
        )}
      </div>

      {/* Preview Image */}
      <PreviewImage imageUrl={imageViewUrl} setImageViewUrl={setImageViewUrl} />
    </div>
  );
};

const mapState = (state) => {
  return {
    dataProfile: state.dataProfile,
    loadingGeneral: state.loadingGeneral,
    qbSession: state.qbSession,
    qbNewMessage: state.qbNewMessage,
  };
};

const actionCreators = (dispatch) => {
  return {
    setLoading: (data) => dispatch({ type: 'CHANGE_LOADING', payload: data }),
    changeQbUnread: (data) =>
      dispatch({ type: 'CHANGE_QB_UNREAD', payload: data }),
  };
};

export default connect(mapState, actionCreators)(Messages);
