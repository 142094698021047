/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { getAllPromotions } from '../../helper/function';
import PromotionsTable from '../../organism/Promotions/PromotionsTable';
import Select from 'react-select';
import $ from 'jquery';

const Promotions = (props) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [type, setType] = useState('all');
  const [selectedStatus, setSelectedStatus] = useState({
    value: 'all',
    label: 'All Status',
  });
  const [totalCurrent, setTotalCurrent] = useState(0);
  const [retrieve, setRetrieve] = useState(0);
  const [search, setSearch] = useState('');

  const statusOptions = [
    { value: 'all', label: 'All Status' },
    { value: 'open', label: 'Open' },
    { value: 'expired', label: 'Expired' },
  ];

  const customStyles = {
    status: {
      control: (provided, state) => ({
        ...provided,
        width: 150,
      }),
    },
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleChangeLimit = (limit) => {};

  const handleSearchData = (e) => {
    if ($(e.currentTarget).prop('id') === 'button-search') {
      setSearch($('input#search-field').val());
    }

    if (e?.key === 'Enter') {
      setSearch($('input#search-field').val());
    }

    setPage(0);
  };

  const handleChangeStatus = (e) => {
    setSelectedStatus(e);
    setType(e.value);
    setPage(0);
  };

  const handleResetFilter = () => {
    setType('all');
    setPage(0);
    setLimit(10);
    setSearch('');
    setSelectedStatus({ value: 'all', label: 'All Status' });
    $('input#search-field').val('');
  };

  const getPromotionsData = async (page = 0, limit = 10) => {
    setIsLoadingData(true);

    const payload = {
      type: type,
      query: search,
      sort: '',
      offset: page,
      limit: limit,
    };
    const response = await getAllPromotions(payload);

    if (response.message === 'Success' && response.meta.total > 0) {
      setData(response.data);
      setTotal(response.meta.total);
      setTotalCurrent(response.data.length);
      setIsLoadingData(false);
    } else {
      setData([]);
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    getPromotionsData(page, limit);
  }, [page, limit, type, retrieve, search]);

  return (
    <div className="tab-content" id="pills-tabContent">
      {/* <!-- Promotions --> */}
      <div
        className="tab-pane fade show active"
        id="pills-promotions"
        role="tabpanel"
        aria-labelledby="pills-promotions-tab"
      >
        <div className="fs-5 fw-bold mt-2 mb-4">Promotions</div>
        <div className="w-100 d-flex flex-column flex-lg-row justify-content-end align-items-center">
          <div className="pe-0 pe-lg-3 w-lg-100 mb-2 mb-lg-0">
            <div className="mb-2 mb-lg-0">
              <div
                className="input-group search-product flex-nowrap border rounded-pill overflow-hidden w-lg-100"
                style={{ width: '235px' }}
              >
                <input
                  type="text"
                  className="form-control border-0 fz-14 box-shadow-focus-0"
                  placeholder="Search by name"
                  aria-label="Username"
                  aria-describedby="addon-wrapping"
                  id="search-field"
                  onKeyUp={handleSearchData}
                />
                <div className="py-1 px-1">
                  <button
                    onClick={handleSearchData}
                    className="btn btn-secondary border-0 rounded-circle p-0 d-flex align-items-center justify-content-center"
                    type="button"
                    id="button-search"
                    style={{ width: '1.8rem', height: '1.7rem' }}
                  >
                    <img
                      alt=""
                      src="/dist/img/custom/search.svg"
                      style={{ width: '.9rem' }}
                      className="filter-white"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="pe-0 pe-lg-4 w-lg-100 mb-2 mb-lg-0">
            <Select
              className="basic-single"
              classNamePrefix="select"
              name="form-status"
              value={selectedStatus}
              options={statusOptions}
              isSearchable={false}
              onChange={handleChangeStatus}
              styles={customStyles.status}
            />
          </div>
          <div className="ps-0 ps-lg-4 w-lg-100 mt-2 mt-lg-0 border-start border-lg-0">
            <button
              onClick={handleResetFilter}
              className="btn btn-line fw-bold w-lg-100"
            >
              Reset
            </button>
          </div>
        </div>
        {(data.length > 0 || isLoadingData) && (
          <>
            <div className="w-100 mt-3">
              <PromotionsTable
                items={data}
                total={total}
                page={page}
                limit={limit}
                isLoadingData={isLoadingData}
                onChangePage={handleChangePage}
                onChangeLimit={handleChangeLimit}
                retrieve={retrieve}
                setRetrieve={setRetrieve}
              />
            </div>
          </>
        )}

        {/* <!-- Tidak Ada Isinya --> */}
        {data.length === 0 && isLoadingData === false && (
          <>
            {/* <!-- Empty --> */}
            <div className="w-100 empty-wrap d-flex align-items-center justify-content-center">
              <div className="d-grid px-3 px-sm-0">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="img-wrap img-wrap-empty">
                    <img alt="" src={'/dist/img/empty/empty-product.png'} />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-4">
                  <div className="fs-6 fw-bold text-center">
                    No Promotion Found!
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapState = (state) => {
  return {
    loadingGeneral: state.loadingGeneral,
  };
};

const actionCreators = (dispatch) => {
  return {
    setLoading: (data) => dispatch({ type: 'CHANGE_LOADING', payload: data }),
  };
};

export default connect(mapState, actionCreators)(Promotions);
