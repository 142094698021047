
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyAyOauPj2yGxvjnAeWldMw78MQwUMsZtCc",
  authDomain: "kisahruang-app.firebaseapp.com",
  projectId: "kisahruang-app",
  storageBucket: "kisahruang-app.appspot.com",
  messagingSenderId: "253646045050",
  appId: "1:253646045050:web:79547ec5e40696ffaa60c3",
  measurementId: "G-04NXNYSDYV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = () => {
  console.log("Requesting User Permission......");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification User Permission Granted.");
      getToken(messaging, { vapidKey: "BLDaLKHvl_ARatY9Hrr6M7QpI1BlOaN-tdlKjhshIzeBxTptAJUp0_wxs5WRfnZJiLzNDMlPlRsBd1FJp7cIu3U" })
        .then((currentToken) => {
          
          console.log('Client Token: ', currentToken);
          if (currentToken !== localStorage.getItem("firebase_token")) {
            localStorage.setItem("firebase_token", currentToken);
            window.location.reload();
          }

        })
        .catch((err) => {
          if (err && err.status) {
            console.log('An error occurred when requesting to receive the token.', err);  
          } else {
            console.log('An error occurred, Service-Worker not found.', err);
            window.location.reload();
          }
          
        });
    } else {
      console.log("User Permission Denied.");
    }
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
