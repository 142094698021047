/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getProfile,
  getProvinces,
  getCities,
  updateProfile,
  getListRoomStyle,
  getBankList,
} from '../helper/function';
import DocUpload from '../organism/DocUpload/DocUpload';
import { IsAlert } from '../atoms/IsAlert';
import { getFileExtension } from '../helper/lib';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, Link } from 'react-router-dom';
import * as Yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import ModalAgreement from '../organism/ModalAgreement/ModalAgreement';

const FillProfileForm = (props) => {
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nama wajib diisi.'),
    email: Yup.string()
      .required('Email wajib diisi.')
      .email('Email wajib format email.'),
    about_merchant: Yup.string().required('Bio merchant wajib diisi.'),
    experience: Yup.string().required('Pengalaman wajib diisi'),
    availableStatus: Yup.string().required('Status wajib diisi.'),
    domain: Yup.string()
      .required('Website wajib diisi.')
      .url('Format website tidak valid.'),
    instagram: Yup.string()
      .required('Instagram wajib diisi.')
      .url('Format instagram URL tidak valid.'),
    styleProject: Yup.array().required('Style project wajib diisi.'),
    address: Yup.string().required('Alamat wajib diisi.'),
    region_id: Yup.number().required('Provinsi wajib diisi.'),
    district_id: Yup.number().required('Kota wajib diisi.'),
    postal_code: Yup.string().required('Kode pos wajib diisi.'),
    phone: Yup.string()
      .required('Nomor telepon wajib diisi.')
      .test(
        'no-repeated-digits',
        'Nomor telepon tidak boleh terdiri dari angka yang berulang.',
        (value) => {
          const uniqueDigits = new Set(value.replace(/\D/g, ''));
          return uniqueDigits.size > 1;
        }
      ),
    wh_address: Yup.string(),
    wh_region_id: Yup.number(),
    wh_district_id: Yup.number(),
    wh_postal_code: Yup.string(),
    wh_phone: Yup.string().test(
      'no-repeated-digits',
      'Nomor telepon gudang tidak boleh terdiri dari angka yang berulang.',
      (value) => {
        const uniqueDigits = new Set(value.replace(/\D/g, ''));
        return uniqueDigits.size > 1;
      }
    ),
    merchant_bank_id: Yup.number().required('Bank harus diisi.'),
    bank_branch: Yup.string().required('Nama cabang bank wajib diisi.'),
    bank_account_number: Yup.string()
      .required('Nomor rekening wajib diisi.')
      .matches(/^[0-9]+$/, 'Hanya angka saja.'),
    bank_account_name: Yup.string().required(
      'Nama pemilik rekening wajib diisi.'
    ),
    pic_name: Yup.string()
      .required('Nama PIC wajib diisi.')
      .matches(/^[A-Za-z\s]+$/, 'Hanya kombinasi huruf dan spasi saja.'),
    pic_email: Yup.string()
      .required('Email PIC wajib diisi.')
      .email('Email PIC wajib format email.'),
    pic_phone: Yup.string()
      .required('Nomor telepon PIC wajib diisi.')
      .test(
        'no-repeated-digits',
        'Nomor telepon PIC tidak boleh terdiri dari angka yang berulang.',
        (value) => {
          const uniqueDigits = new Set(value.replace(/\D/g, ''));
          return uniqueDigits.size > 1;
        }
      ),
    company_name: Yup.string().required('Nama perusahaan wajib diisi.'),
    company_director_name: Yup.string().required(
      'Nama pemilik perusahaan wajib diisi.'
    ),
    category: Yup.string()
      .required('Kategori wajib diisi.')
      .matches(/^[A-Za-z\s]+$/, 'Hanya kombinasi huruf dan spasi saja.'),
    docs: Yup.mixed().required('CV/Portofolio wajib diisi.'),
  });

  const [banksOptions, setBanksOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [citiesWarehouseOptions, setCitiesWarehouseOptions] = useState([]);
  const [provinceWarehouseOptions, setProvinceWarehouseOptions] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedWarehouseProvince, setSelectedWarehouseProvince] =
    useState(null);
  const [selectedWarehouseCity, setSelectedWarehouseCity] = useState(null);
  const [postalCode, setPostalCode] = useState('');
  const [warehousePostalCode, setWarehousePostalCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [warehousePhoneNumber, setWarehousePhoneNumber] = useState('');
  const [picPhoneNumber, setPicPhoneNumber] = useState('');
  const [selectedAvailableStatus, setSelectedAvailableStatus] = useState('');
  const [styles, setStyles] = useState([]);
  const [styleSelected, setStyleSelected] = useState([]);
  const [samplePreview, setSamplePreview] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorDoc, setErrorDoc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showUploadInput, setShowUploadInput] = useState(true);
  const [agreement, setAgreement] = useState(false);

  const customStyles = {
    city: {
      control: (provided, state) => ({
        ...provided,
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
    },
    province: {
      control: (provided, state) => ({
        ...provided,
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
    },
  };

  const getBankData = async () => {
    const response = await getBankList();
    if (response.message === 'Success' && response.data.length > 0) {
      const bank = response.data.map((item) => {
        return { value: item.id, label: item.name };
      });
      setBanksOptions(bank);
    }
  };

  const getProvinceData = async () => {
    const response = await getProvinces();
    if (response.message === 'Success' && response.data.length > 0) {
      let province = response.data.map((item) => {
        return { value: item.id, label: item.name };
      });
      setProvinceOptions(province);
      setProvinceWarehouseOptions(province);
    } else {
      setProvinceOptions([]);
    }
  };

  const getCitiesData = async (id = 1, type = 'office', firstLoad = false) => {
    const response = await getCities(id);
    if (response.message === 'Success' && response.data.length > 0) {
      let cities = response.data.map((item) => {
        return { value: item.id, label: item.name };
      });
      if (type === 'office') setCitiesOptions(cities);
      if (type === 'warehouse') setCitiesWarehouseOptions(cities);
      if (selectedProvince && type === 'office' && !firstLoad)
        handleChangeCity(cities[0]);
      if (selectedWarehouseProvince && type === 'warehouse' && !firstLoad)
        handleChangeWarehouseCity(cities[0]);
    } else {
      if (type === 'office') setCitiesOptions([]);
      if (type === 'warehouse') setCitiesWarehouseOptions([]);
    }
  };

  const getUserData = async () => {
    const res = await getProfile();

    if (res.meta.total > 0) {
      if (res?.data?.is_complete_profile === true) {
        navigate('/');
      }
      props.changeDataProfile(res.data);
    } else {
      console.error({ res });
    }
  };

  const handleChangeBank = (e) => {
    setSelectedBank(e);
    setValue('merchant_bank_id', e.value);
  };

  const handleChangeProvince = (e) => {
    setSelectedProvince(e);
    setValue('region_id', e.value);
    getCitiesData(e.value);
  };

  const handleChangeCity = (e) => {
    setSelectedCity(e);
    setValue('district_id', e.value);
  };

  const handleChangeWarehouseProvince = (e) => {
    setSelectedWarehouseProvince(e);
    setValue('wh_region_id', e.value);
    getCitiesData(e.value, 'warehouse');
  };

  const handleChangeWarehouseCity = (e) => {
    setSelectedWarehouseCity(e);
    setValue('wh_district_id', e.value);
  };

  const handleChangeAvailableStatus = (e) => {
    setSelectedAvailableStatus(e.target.value);
    setValue('availableStatus', e.target.value);
  };

  const handleChangeStyle = (selectedOption) => {
    setStyleSelected(selectedOption);

    // Set value
    let currentValue = [];

    selectedOption.map((option, index) => currentValue.push(option.value));

    if (currentValue?.length > 0) {
      setValue('styleProject', JSON.stringify(currentValue));
    } else {
      setValue('styleProject', '');
    }
  };

  const getStyleLists = async () => {
    const response = await getListRoomStyle();
    if (response.message === 'Success') {
      let styleTags = [];
      response?.data.map((val, index) =>
        styleTags.push({
          value: val?.id,
          label: val?.name,
        })
      );
      setStyles(styleTags);

      // set selected style
      if (props.dataProfile?.styleProject) {
        let parsed = JSON.parse(props.dataProfile?.styleProject);
        let selecteds = [];

        parsed.forEach((data, index) => {
          let selectedData = styleTags.find(
            (styleTag) => parseInt(styleTag.value) === parseInt(data)
          );
          if (selectedData) {
            selecteds.push(selectedData);
          }
        });
        setStyleSelected(selecteds);
      }
    }
  };

  const onChangePostCode = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      setValue('postal_code', e.target.value);
      setPostalCode(e.target.value);
    }
  };

  const onChangeWarehousePostCode = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      setValue('wh_postal_code', e.target.value);
      setWarehousePostalCode(e.target.value);
    }
  };

  const onChangePhoneNumber = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      setValue('phone', e.target.value);
      setPhoneNumber(e.target.value);
    }
  };

  const onChangeWarehousePhoneNumber = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      setValue('wh_phone', e.target.value);
      setWarehousePhoneNumber(e.target.value);
    }
  };

  const onChangePicPhoneNumber = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      setValue('pic_phone', e.target.value);
      setPicPhoneNumber(e.target.value);
    }
  };

  const handleUpload = async (files) => {
    if (samplePreview.length + files.length > 1) {
      IsAlert.fire({
        title: 'Error',
        text: 'Maximum Upload 1 Files',
        icon: 'error',
      });

      return;
    }
    files.forEach(async (file) => {
      const fileExtension = await getFileExtension(file.name);
      const fsize = Math.round((file.size / 1000000) * 100) / 100;
      if (!['pdf'].includes(fileExtension.toLowerCase())) {
        // Alert
        IsAlert.fire({
          title: 'Error',
          text: 'Please upload file with the following extension only: pdf',
          icon: 'error',
        });

        return;
      }

      if (fsize > 3.0) {
        IsAlert.fire({
          title: 'Error',
          text: 'Please upload doc with a size of 3 MB or less per file',
          icon: 'error',
        });
        return;
      }

      const previewImage = URL.createObjectURL(file);
      const base64 = await convertBase64(file);

      setSamplePreview((prev) => [
        ...prev,
        {
          name: file.name,
          size: fsize,
          preview: previewImage,
          file: base64,
          extension: fileExtension,
        },
      ]);

      setValue('docs', [base64]);

      return;
    });
  };

  const convertBase64 = async (file, isBlob) => {
    let blob = file;
    if (isBlob) {
      blob = await file.blob();
    }
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(blob);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const clearImageSelected = (index) => {
    const filtered = samplePreview.filter((_, i) => {
      return i !== index;
    });
    setSamplePreview(filtered);
  };

  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setLoading(true);

    let payload = {
      name: data.name,
      email: data.email,
      about_merchant: data.about_merchant,
      experience: data.experience,
      availableStatus: data.availableStatus,
      domain: data.domain,
      instagram: data.instagram,
      styleProject: JSON.stringify(data.styleProject),
      address: data.address,
      region_id: data.region_id,
      district_id: data.district_id,
      postal_code: data.postal_code,
      phone: data.phone,
      wh_address: data.wh_address,
      wh_region_id: data.wh_region_id,
      wh_district_id: data.wh_district_id,
      wh_postal_code: data.wh_postal_code,
      wh_phone: data.wh_phone,
      pic_name: data.pic_name,
      pic_phone: data.pic_phone,
      pic_email: data.pic_email,
      bank_name: data.bank_name,
      bank_branch: data.bank_branch,
      bank_account_number: data.bank_account_number,
      bank_account_name: data.bank_account_name,
      company_name: data.company_name,
      company_director_name: data.company_director_name,
      category: data.category,
      docs: data.docs,
      // photos: null,
    };

    // if (samplePreview && samplePreview.length > 0) {
    //   payload.docs = samplePreview[0];
    // }

    const response = await updateProfile(payload);

    if (response?.message === 'Success') {
      setLoading(false);
      IsAlert.fire({
        title: 'Success',
        text: 'Data profile berhasil diperbarui.',
        icon: 'success',
        showConfirmButton: false,
        timer: 1000,
      });

      setTimeout(() => {
        getUserData();
      }, 1000);

      return;
    }

    IsAlert.fire({
      title: 'Error',
      text: response?.message,
      icon: 'error',
    });
    setLoading(false);
  };

  useEffect(() => {
    getProvinceData();
    if (!props.dataProfile) {
      getUserData();
    }

    // set profile
    setValue('name', props.dataProfile?.name);
    setValue('email', props.dataProfile?.email);
  }, [props.dataProfile]);

  useEffect(() => {
    getStyleLists();
    getBankData();
    getProvinceData();
  }, []);

  useEffect(() => {
    if (props.dataProfile) {
      if (props.dataProfile?.is_complete_profile === true) {
        navigate('/');
      }
    }
  }, []);

  useEffect(() => {
    // show hide box image upload
    if (samplePreview.length >= 1) {
      setShowUploadInput(false);
    } else {
      if (showUploadInput) {
        return;
      }
      setShowUploadInput(true);
    }
  }, [samplePreview]);

  return (
    <>
      <ModalAgreement />
      {loading ? (
        <Stack
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            color: 'brown100',
            backgroundColor: '#ffffff',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 100000,
            height: '100vh',
            width: '100vw',
          }}
        >
          <CircularProgress color="inherit" />
        </Stack>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="p-5">
          <h2 className="fw-bold text-center mb-5">Complete your profile!</h2>
          <div className="fs-5 fw-bold mb-4">Profile Information</div>

          <div className="d-sm-flex mb-3">
            <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
              <label className="form-label d-flex fw-semibold">
                Name<div className="text-danger ps-1">*</div>
              </label>
              <input
                type="text"
                className="form-control"
                {...register('name')}
                placeholder="Input name"
              />

              {errors.name ? (
                <span className="text-danger">{errors.name.message}</span>
              ) : null}
            </div>
            <div className="col-12 col-sm-6 ps-sm-2">
              <label className="form-label d-flex fw-semibold">
                Email<div className="text-danger ps-1">*</div>
              </label>
              <input
                type="text"
                className="form-control"
                {...register('email')}
                placeholder="Input email address"
              />

              {errors.email ? (
                <span className="text-danger">{errors.email.message}</span>
              ) : null}
            </div>
          </div>

          <div className="d-sm-flex mb-3">
            <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
              <label className="form-label d-flex fw-semibold">
                Company Legal Name<div className="text-danger ps-1">*</div>
              </label>
              <input
                type="text"
                className="form-control"
                {...register('company_name')}
                placeholder="Input company name"
              />

              {errors.company_name ? (
                <span className="text-danger">
                  {errors.company_name.message}
                </span>
              ) : null}
            </div>
            <div className="col-12 col-sm-6 ps-sm-2">
              <label className="form-label d-flex fw-semibold">
                Company Director / Owner Name
                <div className="text-danger ps-1">*</div>
              </label>
              <input
                type="text"
                className="form-control"
                {...register('company_director_name')}
                placeholder="Input company director name/owner name"
              />

              {errors.company_director_name ? (
                <span className="text-danger">
                  {errors.company_director_name.message}
                </span>
              ) : null}
            </div>
          </div>

          <div className="d-sm-flex mb-3">
            <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
              <label className="form-label d-flex fw-semibold">
                Bio<div className="text-danger ps-1">*</div>
              </label>

              <textarea
                className="form-control"
                rows="3"
                {...register('about_merchant')}
                placeholder="Input biodata"
              ></textarea>
              {errors.about_merchant ? (
                <span className="text-danger">
                  {errors.about_merchant.message}
                </span>
              ) : null}
            </div>
            <div className="col-12 col-sm-6 ps-sm-2">
              <label className="form-label d-flex fw-semibold">
                Experience (Years)<div className="text-danger ps-1">*</div>
              </label>

              <input
                type="number"
                className="form-control"
                {...register('experience')}
                placeholder="Input experience"
                min={0}
              />
              {errors.experience ? (
                <span className="text-danger">{errors.experience.message}</span>
              ) : null}
            </div>
          </div>

          <div className="d-sm-flex mb-3">
            <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
              <label className="form-label d-flex fw-semibold">
                Available Status<div className="text-danger ps-1">*</div>
              </label>

              <select
                value={selectedAvailableStatus}
                onChange={handleChangeAvailableStatus}
                className="form-select"
                placeholder="Input available status"
              >
                <option value="">Pilih</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Nearly Full">Nearly Full</option>
              </select>
              {errors.availableStatus ? (
                <span className="text-danger">
                  {errors.availableStatus.message}
                </span>
              ) : null}
            </div>
            <div className="col-12 col-sm-6 ps-sm-2">
              <label className="form-label d-flex fw-semibold">
                Website (URL)<div className="text-danger ps-1">*</div>
              </label>
              <input
                type="text"
                className="form-control"
                {...register('domain')}
                placeholder="e.g: https://www.google.com"
              />

              {errors.domain ? (
                <span className="text-danger">{errors.domain.message}</span>
              ) : null}
            </div>
          </div>

          <div className="d-sm-flex mb-3">
            <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
              <label className="form-label d-flex fw-semibold">
                Instagram Profile (URL)
                <div className="text-danger ps-1">*</div>
              </label>
              <input
                type="text"
                className="form-control"
                {...register('instagram')}
                placeholder="e.g: https://www.instagram.com/xxx"
              />

              {errors.instagram ? (
                <span className="text-danger">{errors.instagram.message}</span>
              ) : null}
            </div>
            <div className="col-12 col-sm-6 ps-sm-2">
              <label className="form-label d-flex fw-semibold">
                Category<div className="text-danger ps-1">*</div>
              </label>
              <input
                type="text"
                className="form-control"
                {...register('category')}
                placeholder="Input category (e.g. : designer, furniture etc. )"
              />

              {errors.category ? (
                <span className="text-danger">{errors.category.message}</span>
              ) : null}
            </div>
          </div>

          <div className="d-sm-flex mb-3">
            <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
              <label className="form-label d-flex fw-semibold">
                Style<div className="text-danger ps-1">*</div>
              </label>
              <Select
                isMulti
                options={styles}
                value={styleSelected}
                onChange={handleChangeStyle}
                menuPlacement="auto"
                placeholder="Select styles"
              />
              {errors.styleProject ? (
                <span className="text-danger">
                  {errors.styleProject.message}
                </span>
              ) : null}
            </div>
          </div>

          <div className="fs-5 fw-bold mt-5 mb-4">Office Location</div>

          <div className="d-sm-flex mb-3">
            <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
              <label className="form-label d-flex fw-semibold">
                Province<div className="text-danger ps-1">*</div>
              </label>
              <Controller
                name="region_id"
                control={control}
                render={({ field }) => (
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={selectedProvince}
                    placeholder="Select Province"
                    options={provinceOptions}
                    isSearchable={true}
                    styles={customStyles.province}
                    menuPortalTarget={document.body}
                    onChange={handleChangeProvince}
                  />
                )}
              />
              {errors.region_id ? (
                <span className="text-danger">{errors.region_id.message}</span>
              ) : null}
            </div>
            <div className="col-12 col-sm-6 ps-sm-2">
              <label className="form-label d-flex fw-semibold">
                City<div className="text-danger ps-1">*</div>
              </label>
              <Controller
                name="district_id"
                control={control}
                render={({ field }) => (
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={selectedCity}
                    placeholder="Select City"
                    options={citiesOptions}
                    isSearchable={true}
                    styles={customStyles.city}
                    menuPortalTarget={document.body}
                    onChange={handleChangeCity}
                  />
                )}
              />
              {errors.district_id ? (
                <span className="text-danger">
                  {errors.district_id.message}
                </span>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="d-sm-flex mb-3">
            <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
              <label className="form-label d-flex fw-semibold">
                Postal code<div className="text-danger ps-1">*</div>
              </label>
              <input
                type="text"
                className="form-control"
                {...register('postal_code')}
                value={postalCode}
                onChange={onChangePostCode}
                maxLength={5}
                placeholder="Input postal code"
              />
              {errors.postal_code ? (
                <span className="text-danger">
                  {errors.postal_code.message}
                </span>
              ) : null}
            </div>
            <div className="col-12 col-sm-6 ps-sm-2">
              <label className="form-label d-flex fw-semibold">
                Phone Number<div className="text-danger ps-1">*</div>
              </label>
              <input
                type="text"
                className="form-control"
                {...register('phone')}
                value={phoneNumber}
                onChange={onChangePhoneNumber}
                maxLength={15}
                placeholder="Input phone number"
              />
              {errors.phone ? (
                <span className="text-danger">{errors.phone.message}</span>
              ) : null}
            </div>
          </div>

          <div className="d-sm-flex mb-3">
            <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
              <label className="form-label d-flex fw-semibold">
                Address<div className="text-danger ps-1">*</div>
              </label>
              <textarea
                className="form-control"
                rows="3"
                {...register('address')}
                placeholder="Input address"
              ></textarea>
              {errors.address ? (
                <span className="text-danger">{errors.address.message}</span>
              ) : null}
            </div>
          </div>

          <div className="fs-5 fw-bold mt-5 mb-4">Warehouse Location</div>

          <div className="d-sm-flex mb-3">
            <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
              <label className="form-label d-flex fw-semibold">Province</label>
              <Controller
                name="wh_region_id"
                control={control}
                render={({ field }) => (
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={selectedWarehouseProvince}
                    placeholder="Select Province"
                    options={provinceWarehouseOptions}
                    isSearchable={true}
                    styles={customStyles.province}
                    menuPortalTarget={document.body}
                    onChange={handleChangeWarehouseProvince}
                  />
                )}
              />
              {errors.wh_region_id ? (
                <span className="text-danger">
                  {errors.wh_region_id.message}
                </span>
              ) : null}
            </div>
            <div className="col-12 col-sm-6 ps-sm-2">
              <label className="form-label d-flex fw-semibold">City</label>
              <Controller
                name="wh_district_id"
                control={control}
                render={({ field }) => (
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={selectedWarehouseCity}
                    placeholder="Select City"
                    options={citiesWarehouseOptions}
                    isSearchable={true}
                    styles={customStyles.city}
                    menuPortalTarget={document.body}
                    onChange={handleChangeWarehouseCity}
                  />
                )}
              />
              {errors.wh_district_id ? (
                <span className="text-danger">
                  {errors.wh_district_id.message}
                </span>
              ) : null}
            </div>
          </div>

          <div className="d-sm-flex mb-3">
            <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
              <label className="form-label d-flex fw-semibold">
                Postal code
              </label>
              <input
                type="text"
                className="form-control"
                {...register('wh_postal_code')}
                value={warehousePostalCode}
                onChange={onChangeWarehousePostCode}
                maxLength={5}
                placeholder="Input postal code"
              />
              {errors.wh_postal_code ? (
                <span className="text-danger">
                  {errors.wh_postal_code.message}
                </span>
              ) : null}
            </div>
            <div className="col-12 col-sm-6 ps-sm-2">
              <label className="form-label d-flex fw-semibold">
                Phone Number
              </label>
              <input
                type="text"
                className="form-control"
                {...register('wh_phone')}
                value={warehousePhoneNumber}
                onChange={onChangeWarehousePhoneNumber}
                maxLength={15}
                placeholder="Input phone number"
              />
              {errors.wh_phone ? (
                <span className="text-danger">{errors.wh_phone.message}</span>
              ) : null}
            </div>
          </div>

          <div className="d-sm-flex mb-3">
            <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
              <label className="form-label d-flex fw-semibold">Address</label>
              <textarea
                className="form-control"
                rows="3"
                {...register('wh_address')}
                placeholder="Input address"
              ></textarea>
              {errors.wh_address ? (
                <span className="text-danger">{errors.wh_address.message}</span>
              ) : null}
            </div>
          </div>

          <div className="fs-5 fw-bold mt-5 mb-4">PIC Info</div>
          <div className="d-sm-flex mb-3">
            <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
              <label className="form-label d-flex fw-semibold">
                PIC Name<div className="text-danger ps-1">*</div>
              </label>
              <input
                type="text"
                className="form-control"
                {...register('pic_name')}
                placeholder="Input PIC name"
              />

              {errors.pic_name ? (
                <span className="text-danger">{errors.pic_name.message}</span>
              ) : null}
            </div>
            <div className="col-12 col-sm-6 ps-sm-2">
              <label className="form-label d-flex fw-semibold">
                PIC Phone Number<div className="text-danger ps-1">*</div>
              </label>

              <input
                type="text"
                className="form-control"
                {...register('pic_phone')}
                value={picPhoneNumber}
                onChange={onChangePicPhoneNumber}
                maxLength={15}
                placeholder="Input PIC phone number"
              />

              {errors.pic_phone ? (
                <span className="text-danger">{errors.pic_phone.message}</span>
              ) : null}
            </div>
          </div>
          <div className="d-sm-flex mb-3">
            <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
              <label className="form-label d-flex fw-semibold">
                PIC Email<div className="text-danger ps-1">*</div>
              </label>
              <input
                type="text"
                className="form-control"
                {...register('pic_email')}
                placeholder="Input PIC email"
              />

              {errors.pic_email ? (
                <span className="text-danger">{errors.pic_email.message}</span>
              ) : null}
            </div>
          </div>

          <div className="fs-5 fw-bold mt-5 mb-4">For Disbursement Purpose</div>
          <div className="d-sm-flex mb-3">
            <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
              <label className="form-label d-flex fw-semibold">
                Bank Name<div className="text-danger ps-1">*</div>
              </label>
              <Controller
                name="merchant_bank_id"
                control={control}
                render={({ field }) => (
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={selectedBank}
                    placeholder="Select Bank"
                    options={banksOptions}
                    isSearchable={true}
                    menuPortalTarget={document.body}
                    onChange={handleChangeBank}
                  />
                )}
              />
              {errors.merchant_bank_id ? (
                <span className="text-danger">
                  {errors.merchant_bank_id.message}
                </span>
              ) : null}
            </div>
            <div className="col-12 col-sm-6 ps-sm-2">
              <label className="form-label d-flex fw-semibold">
                Bank Branch<div className="text-danger ps-1">*</div>
              </label>
              <input
                type="text"
                className="form-control"
                {...register('bank_branch')}
                placeholder="Input bank branch name"
              />

              {errors.bank_branch ? (
                <span className="text-danger">
                  {errors.bank_branch.message}
                </span>
              ) : null}
            </div>
          </div>

          <div className="d-sm-flex mb-3">
            <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
              <label className="form-label d-flex fw-semibold">
                Bank Account Number<div className="text-danger ps-1">*</div>
              </label>
              <input
                type="text"
                className="form-control"
                {...register('bank_account_number')}
                placeholder="Input bank account number"
              />

              {errors.bank_account_number ? (
                <span className="text-danger">
                  {errors.bank_account_number.message}
                </span>
              ) : null}
            </div>
            <div className="col-12 col-sm-6 ps-sm-2">
              <label className="form-label d-flex fw-semibold">
                Bank Account Name<div className="text-danger ps-1">*</div>
              </label>
              <input
                type="text"
                className="form-control"
                {...register('bank_account_name')}
                placeholder="Input bank account name"
              />

              {errors.bank_account_name ? (
                <span className="text-danger">
                  {errors.bank_account_name.message}
                </span>
              ) : null}
            </div>
          </div>

          <div className="fs-5 fw-bold mt-5 mb-4">Attachment</div>
          <div>
            <label className="form-label d-flex fw-semibold">
              CV/Portofolio Designer<div className="text-danger ps-1">*</div>
            </label>

            {showUploadInput ? (
              <DocUpload
                upload={handleUpload}
                samplePreviews={samplePreview}
                maxFile={1}
                maxSizePerFile={3}
              />
            ) : null}

            {/* {errorDoc ? <span className="text-danger">{errorDoc}</span> : null} */}
            {errors.docs ? (
              <span className="text-danger mt-2">{errors.docs.message}</span>
            ) : null}
          </div>

          <div className="mt-3 mb-3 d-flex justify-content-center">
            <div className="w-100 row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mt-3 me-0">
              {samplePreview.map((image, index) => {
                return (
                  <div key={index}>
                    <div className="border rounded-3 p-3 d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="img-wrap w-5rem ar-square rounded-3 overflow-hidden d-flex align-items-center justify-content-center">
                          <div className="w-100 h-100">
                            <img
                              id="imageId"
                              src={image.preview}
                              className="object-fit-cover w-5rem ar-square"
                              alt="Preview"
                            />
                          </div>
                        </div>
                        <div className="col ms-3">
                          <p className="mb-0 text-wrap-1">{image.name}</p>
                          <div className="fz-14 text-secondary mt-3">
                            {image.size} Mb
                          </div>
                        </div>
                      </div>
                      <button className="btn p-0">
                        <img
                          src="/dist/img/custom/x.svg"
                          className="icon-3"
                          alt="img-delete"
                          onClick={clearImageSelected.bind(this, index)}
                        />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="mb-3 ">
            <div className="d-flex flex-row align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 576 512"
              >
                {/* <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--> */}
                <path d="M528.3 46.5H388.5c-48.1 0-89.9 33.3-100.4 80.3-10.6-47-52.3-80.3-100.4-80.3H48c-26.5 0-48 21.5-48 48v245.8c0 26.5 21.5 48 48 48h89.7c102.2 0 132.7 24.4 147.3 75 .7 2.8 5.2 2.8 6 0 14.7-50.6 45.2-75 147.3-75H528c26.5 0 48-21.5 48-48V94.6c0-26.4-21.3-47.9-47.7-48.1zM242 311.9c0 1.9-1.5 3.5-3.5 3.5H78.2c-1.9 0-3.5-1.5-3.5-3.5V289c0-1.9 1.5-3.5 3.5-3.5h160.4c1.9 0 3.5 1.5 3.5 3.5v22.9zm0-60.9c0 1.9-1.5 3.5-3.5 3.5H78.2c-1.9 0-3.5-1.5-3.5-3.5v-22.9c0-1.9 1.5-3.5 3.5-3.5h160.4c1.9 0 3.5 1.5 3.5 3.5V251zm0-60.9c0 1.9-1.5 3.5-3.5 3.5H78.2c-1.9 0-3.5-1.5-3.5-3.5v-22.9c0-1.9 1.5-3.5 3.5-3.5h160.4c1.9 0 3.5 1.5 3.5 3.5v22.9zm259.3 121.7c0 1.9-1.5 3.5-3.5 3.5H337.5c-1.9 0-3.5-1.5-3.5-3.5v-22.9c0-1.9 1.5-3.5 3.5-3.5h160.4c1.9 0 3.5 1.5 3.5 3.5v22.9zm0-60.9c0 1.9-1.5 3.5-3.5 3.5H337.5c-1.9 0-3.5-1.5-3.5-3.5V228c0-1.9 1.5-3.5 3.5-3.5h160.4c1.9 0 3.5 1.5 3.5 3.5v22.9zm0-60.9c0 1.9-1.5 3.5-3.5 3.5H337.5c-1.9 0-3.5-1.5-3.5-3.5v-22.8c0-1.9 1.5-3.5 3.5-3.5h160.4c1.9 0 3.5 1.5 3.5 3.5V190z" />
              </svg>
              <button
                type="button"
                class="btn"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                style={{ textDecoration: 'underline' }}
              >
                File Syarat dan ketentuan kerjasama
              </button>
              {/* <Link
                to="/agreement"
                target="_blank"
                style={{ textDecoration: 'underline' }}
              >
                File Syarat dan ketentuan kerjasama
              </Link> */}
            </div>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={agreement}
              onChange={(event) => {
                setAgreement(event.target.checked);
              }}
            />
            <label className="form-check-label" for="flexCheckDefault">
              Dengan Ini saya telah mengisi data dengan sesuai dan benar, serta
              telah membaca dan menyetujui lampiran File Syarat dan Ketentuan
              Kerjasama.
            </label>
          </div>

          <div className="d-flex flex-row justify-content-end mt-5">
            <button
              type="submit"
              className="btn fw-semibold btn-fill"
              disabled={loading === true || !agreement}
            >
              Submit
            </button>
          </div>
        </form>
      )}
    </>
  );
};

const mapState = (state) => {
  return {
    dataProfile: state.dataProfile,
    loadingGeneral: state.loadingGeneral,
  };
};

const actionCreators = (dispatch) => {
  return {
    setLoading: (data) => dispatch({ type: 'CHANGE_LOADING', payload: data }),
    changeDataProfile: (data) =>
      dispatch({ type: 'CHANGE_DATA_PROFILE', payload: data }),
  };
};

export default connect(mapState, actionCreators)(FillProfileForm);
