import React, { useEffect, useRef } from 'react';
import * as pdfjsLib from 'pdfjs-dist/webpack';

const PdfViewer = ({ fileUrl }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const fetchAndRenderPDF = async () => {
      const loadingTask = pdfjsLib.getDocument(fileUrl);
      const pdf = await loadingTask.promise;
      const numPages = pdf.numPages;
      const container = containerRef.current;

      // Clear previous pages
      container.innerHTML = '';

      for (let pageNum = 1; pageNum <= numPages; pageNum++) {
        const page = await pdf.getPage(pageNum);
        const scale = 1.5;
        const viewport = page.getViewport({ scale });

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        container.appendChild(canvas);

        const renderContext = {
          canvasContext: context,
          viewport,
        };
        await page.render(renderContext).promise;
      }
    };

    fetchAndRenderPDF();

    // Disable right-click context menu
    const container = containerRef.current;
    container.addEventListener('contextmenu', (e) => e.preventDefault());

    return () => {
      // Clean up event listener
      container.removeEventListener('contextmenu', (e) => e.preventDefault());
    };
  }, [fileUrl]);

  return (
    <div
      ref={containerRef}
      className="d-flex flex-column align-items-center"
      style={{ position: 'relative' }}
    >
      <div className="pdf-overlay" />
    </div>
  );
};

export default PdfViewer;
