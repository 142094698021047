import React from 'react';
import { connect } from 'react-redux';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import 'react-loading-skeleton/dist/skeleton.css';
import Layout from './Layout';
import Activate from './screen/Activate';
import Loading from './organism/General/Loading';
import ForgotPassword from './screen/ForgotPassword';
import FillProfileForm from './screen/FillProfileForm';
import Agreement from './screen/Agreement';
import Caution from './screen/Caution';
import Login from './screen/Login';
import Register from './screen/Register';
import ResetPassword from './screen/ResetPassword';
import ResetPasswordEmail from './screen/ResetPasswordEmail';
import Dashboard from './screen/Dashboard';
import PortfolioList from './screen/WebDesign/Portfolio/PortfolioList';
import AddPortfolio from './screen/WebDesign/Portfolio/AddPortfolio';
import DetailPortfolio from './screen/WebDesign/Portfolio/DetailPortfolio';
import OrdersLayout from './screen/Orders/OrdersLayout';
import OrderDetail from './screen/Orders/OrderDetail';
import OrderList from './screen/Orders/OrderList';
import PromotionsLayout from './screen/Promotions/PromotionsLayout';
import Promotions from './screen/Promotions/Promotions';
import PromotionsDetail from './screen/Promotions/PromotionsDetail';
import MyPromotionsDetail from './screen/Promotions/MyPromotionsDetail';
import MyPromo from './screen/Promotions/MyPromo';
import PromotionProduct from './screen/Promotions/PromotionProduct';
import ItemsLayout from './screen/Items/ItemsLayout';
import ItemList from './screen/Items/ItemList';
import PendingReview from './screen/Items/PendingReview';
import DiscountType from './screen/Items/DiscountType';
import AddItem from './screen/Items/AddItem';
import DetailItem from './screen/Items/DetailItem';
import AddVariant from './screen/Items/Variants/AddVariant';
import SellerHelp from './screen/SellerHelp';
import ItemReview from './screen/ItemReview/ItemReview';
import Notifications from './screen/Notifications';
import Transactions from './screen/Transactions/Transactions';
import TransactionsDetail from './screen/Transactions/TransactionsDetail';
import RequestDisbursement from './screen/Transactions/RequestDisbursement';
import RequestDisbursementDetail from './screen/Transactions/RequestDisbursementDetail';
import ProfileLayout from './screen/Profile/ProfileLayout';
import Profile from './screen/Profile/Profile';
import BankAccount from './screen/Profile/BankAccount';
import ChangePassword from './screen/Profile/ChangePassword';
import Courier from './screen/Profile/Courier';
import Setting from './screen/Profile/Setting';
import ProjectLayout from './screen/WebDesign/Project/ProjectLayout';
import ListProject from './screen/WebDesign/Project/ListProject';
import ProjectDetail from './screen/WebDesign/Project/ProjectDetail';
import ProjectDetailRoom from './screen/WebDesign/Project/ProjectDetailRoom';
import UpdateProjectNote from './screen/WebDesign/Project/UpdateProjectNote';
import AddProjectDesign from './screen/WebDesign/Project/AddProjectDesign';
import DetailProjectDesign from './screen/WebDesign/Project/DetailProjectDesign';
import UploadMaterialListProject from './screen/WebDesign/Project/UploadMaterialListProject';
import Messages from './screen/Messages/Messages';

// Web Design
import WebDesignlayout from './screen/WebDesign/WebDesignLayout';
import DashboardWd from './screen/WebDesign/DashboardWd';
import TransactionsDesigner from './screen/WebDesign/Transactions/TransactionsDesigner';
import TransactionsDetailDesigner from './screen/WebDesign/Transactions/TransactionsDetailDesigner';
import RequestDisbursementDesigner from './screen/WebDesign/Transactions/RequestDisbursementDesigner';
import RequestDisbursementDetailDesigner from './screen/WebDesign/Transactions/RequestDisbursementDetailDesigner';
import ProjectReview from './screen/WebDesign/ProjectReview/ProjectReview';

const RouterLayout = () => {
  return (
    <BrowserRouter>
      <Loading />
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path="orders" element={<OrdersLayout />}>
              <Route index element={<OrderList />} />
              <Route path="detail/:slug" element={<OrderDetail />} />
            </Route>
            <Route path="items" element={<ItemsLayout />}>
              <Route exact index element={<ItemList />} />
              <Route path="pending-review" element={<PendingReview />} />
              <Route path="discount-type" element={<DiscountType />} />
            </Route>
            <Route path="items/add-new" element={<AddItem />} />
            <Route path="items/edit/:id" element={<AddItem />} />
            <Route
              path="items/add-new/variant/add-new/:productID"
              element={<AddVariant />}
            />
            <Route
              path="items/add-new/variant/add-new/:productID/:variantID"
              element={<AddVariant />}
            />
            <Route path="items/detail/:id" element={<DetailItem />} />
            <Route path="promotions" element={<PromotionsLayout />}>
              <Route index element={<Promotions />} />
              <Route path="my-promo" element={<MyPromo />} />
              <Route path="promotion-product" element={<PromotionProduct />} />
            </Route>
            <Route path="promotions/view/:id" element={<PromotionsDetail />} />
            <Route
              path="promotions/my-promo/view/:id"
              element={<MyPromotionsDetail />}
            />
            <Route path="item-review" element={<ItemReview />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="transactions/:id" element={<TransactionsDetail />} />
            <Route
              path="requestdisbursement"
              element={<RequestDisbursement />}
            />
            <Route
              path="requestdisbursement/:id"
              element={<RequestDisbursementDetail />}
            />
            {/* <Route path="/profile" element={<Profile />} /> */}
            <Route path="profile" element={<ProfileLayout />}>
              <Route index element={<Profile />} />
              <Route path="bank-account" element={<BankAccount />} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="courier" element={<Courier />} />
              <Route path="setting" element={<Setting />} />
            </Route>
            <Route path="/seller-help" element={<SellerHelp />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/designer/messages" element={<Messages />} />

            {/* Web Designer */}
            <Route path="designer" element={<WebDesignlayout />}>
              <Route index element={<DashboardWd />} />
              <Route path="portfolio" element={<PortfolioList />} />
              <Route path="portfolio/add-new" element={<AddPortfolio />} />
              <Route path="portfolio/view/:id" element={<DetailPortfolio />} />
              {/* <Route path="portfolio/update/:id" element={<UpdatePortfolio />} /> */}
              <Route path="portfolio/update/:id" element={<AddPortfolio />} />

              {/* Project Review */}
              <Route path="project-review" element={<ProjectReview />} />

              {/* Project */}
              <Route path="project" element={<ProjectLayout />}>
                <Route index element={<ListProject />} />
                <Route path="view/:orderNo/:id" element={<ProjectDetail />} />
                <Route path="view/:orderNo" element={<ProjectDetailRoom />} />
                <Route
                  path="update-note/:orderNo/:projectID"
                  element={<UpdateProjectNote />}
                />
                <Route
                  path="add-design/:orderNo/:projectID"
                  element={<AddProjectDesign />}
                />
                {/* For Edit */}
                <Route
                  path="add-design/:orderNo/:projectID/:id"
                  element={<AddProjectDesign />}
                />
                <Route
                  path="detail-design/:orderNo/:id/:projectID"
                  element={<DetailProjectDesign />}
                />
                <Route
                  path="upload-material-list/:orderNo/:projectID"
                  element={<UploadMaterialListProject />}
                />
              </Route>

              {/* Transactions */}
              <Route path="transactions" element={<TransactionsDesigner />} />
              <Route
                path="transactions/:id"
                element={<TransactionsDetailDesigner />}
              />
              <Route
                path="requestdisbursement"
                element={<RequestDisbursementDesigner />}
              />
              <Route
                path="requestdisbursement/:id"
                element={<RequestDisbursementDetailDesigner />}
              />

              {/* SellerHelp */}
              <Route path="seller-help" element={<SellerHelp />} />

              {/* Notifications */}
              <Route path="notifications" element={<Notifications />} />

              {/* Profile */}
              <Route path="profile" element={<ProfileLayout />}>
                <Route index element={<Profile />} />
                <Route path="bank-account" element={<BankAccount />} />
                <Route path="change-password" element={<ChangePassword />} />
                <Route path="courier" element={<Courier />} />
                <Route path="setting" element={<Setting />} />
              </Route>
            </Route>
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/activate/:code" element={<Activate />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/email-sent" element={<ResetPasswordEmail />} />
          <Route path="/reset-password/:code" element={<ResetPassword />} />
          <Route path="/fill-profile-form" element={<FillProfileForm />} />
          <Route path="/agreement" element={<Agreement />} />
          <Route path="/caution" element={<Caution />} />
        </Routes>
      </SnackbarProvider>
    </BrowserRouter>
  );
};
const mapState = (state) => {
  return {
    loadingGeneral: state.loadingGeneral,
  };
};

const actionCreators = (dispatch) => {
  return {};
};
export default connect(mapState, actionCreators)(RouterLayout);
