/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PdfViewer from '../organism/PdfViewer/PdfViewer';

const Agreement = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (props.dataProfile) {
      if (props.dataProfile?.is_complete_profile === true) {
        navigate('/');
      }
    }
  }, []);

  return (
    <div className="h-100 w-100">
      <PdfViewer
        fileUrl={
          'https://kisahruang.s3.ap-southeast-1.amazonaws.com/internal/Perjanjian_Kerjasama.pdf'
        }
      />
    </div>
  );
};

export default Agreement;
