import React from 'react';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getOrderDetail,
  updateStatusOrder,
  packedStatusOrder,
  shippedStatusOrder,
  getVendorCourier,
  getTimeSlotList,
  getTrackingListOrder,
  requestResolveOrder,
} from '../../helper/function';
import LoadingComponent from '../../atoms/LoadingComponent';
import {
  formatDate,
  formatMoney,
  getFileExtension,
  formatDateTimeSecond,
} from '../../helper/lib';
import { IsAlert } from '../../atoms/IsAlert';
import FileUpload from '../../organism/FileUpload/FileUpload';
import VideoThumbnail from 'react-video-thumbnail';
import ArrowLeft from '../../assets/img/custom/caret-left-light.svg';

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './styles/timeline-style.css';

import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

const OrderDetail = (props) => {
  const regexRemoveBase64 = /^data:.+;base64,/;
  const [data, setData] = useState();
  const [id, setId] = useState();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [couriers, setCouriers] = useState([]);
  const [subDistrict, setSubDistrict] = useState('');
  const [timeSlots, setTimeSlots] = useState([]);
  const [timeSlot, setTimeSlot] = useState('');
  const [trackings, setTrackings] = useState([]);

  const [showUploadInputImage, setShowUploadInputImage] = useState(true);
  const [showUploadInputVideo, setShowUploadInputVideo] = useState(true);
  const [samplePreview, setSamplePreview] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  // Preview Videos
  const [sampleVideos, setSampleVideos] = useState([]);

  let navigate = useNavigate();
  let { slug } = useParams();

  const getIdFromSlug = (slug) => {
    let id = slug.split('-').pop();
    return id;
  };

  const validateString = (string) =>
    string === null || string === undefined || string === '' ? '-' : string;

  const getCourierData = async () => {
    setIsLoadingData(true);
    let res = await getVendorCourier();
    if (res.meta.total > 0 && res.message === 'Success') {
      setCouriers(res.data.filter((c) => c.is_active));
      setIsLoadingData(false);
    } else {
      setIsLoadingData(false);
      // navigate("/orders");
    }
  };

  const getTimeSlotData = async () => {
    setIsLoadingData(true);
    let res = await getTimeSlotList();
    if (res.meta.total > 0 && res.message === 'Success') {
      setTimeSlots(res?.data?.data?.time_slots);
      setIsLoadingData(false);
    } else {
      setIsLoadingData(false);
      // navigate("/orders");
    }
  };

  const getTrackingOrder = async (shipperOrderId) => {
    setIsLoadingData(true);
    let res = await getTrackingListOrder(shipperOrderId);
    if (res.meta.total > 0 && res.message === 'Success') {
      setTrackings(res?.data?.data?.trackings);
      setIsLoadingData(false);
    } else {
      setIsLoadingData(false);
      // navigate("/orders");
    }
  };

  const getOrderDetailData = async () => {
    setIsLoadingData(true);
    let res = await getOrderDetail(id);
    if (res.meta.total > 0 && res.message === 'Success') {
      setData(res.data);
      setId(id);
      setIsLoadingData(false);

      if (res?.data?.status === 'Confirmed') {
        getCourierData();
      }

      if (res?.data?.status.toLowerCase() === 'packing') {
        getTimeSlotData();
      }

      // Get data tracking
      if (res?.data?.shipper_order_id) {
        getTrackingOrder(res?.data?.shipper_order_id);
      }
    } else {
      setIsLoadingData(false);
      navigate('/orders');
    }
  };

  //   const handleUpload = async (event) => {
  //     const fileExtension = await getFileExtension(event.target.files[0].name);
  //     const fsize =
  //       Math.round((event?.target?.files[0]?.size / 1000000) * 100) / 100;
  //     if (!['jpeg', 'jpg', 'png'].includes(fileExtension.toLowerCase())) {
  //       // Alert
  //       IsAlert.fire({
  //         title: 'Error',
  //         text: 'Please upload file with the following extension only: jpeg, jpg, png',
  //         icon: 'error',
  //       });
  //     } else if (fsize > 3.0) {
  //       // Alert
  //       IsAlert.fire({
  //         title: 'Error',
  //         text: 'Please upload image with a size of 3 MB or less per file',
  //         icon: 'error',
  //       });
  //     } else {
  //       const previewImage = URL.createObjectURL(event.target.files[0]);
  //       const base64 = await convertBase64(event.target.files[0]);
  //       setSamplePreview([
  //         ...samplePreview,
  //         {
  //           name: event?.target?.files[0]?.name,
  //           size: fsize,
  //           preview: previewImage,
  //           file: base64,
  //         },
  //       ]);
  //     }
  //   };

  const handleUpload = async (files) => {
    if (samplePreview.length + files.length > 1) {
      IsAlert.fire({
        title: 'Error',
        text: 'Maximum Upload 1 Files',
        icon: 'error',
      });

      return;
    }
    files.forEach(async (file) => {
      const fileExtension = await getFileExtension(file.name);
      const fsize = Math.round((file.size / 1000000) * 100) / 100;
      if (!['jpeg', 'jpg', 'png'].includes(fileExtension.toLowerCase())) {
        // Alert
        IsAlert.fire({
          title: 'Error',
          text: 'Please upload file with the following extension only: jpeg, jpg, png',
          icon: 'error',
        });

        return;
      }

      if (fsize > 3.0) {
        IsAlert.fire({
          title: 'Error',
          text: 'Please upload image with a size of 3 MB or less per file',
          icon: 'error',
        });
        return;
      }

      const previewImage = URL.createObjectURL(file);
      const base64 = await convertBase64(file);

      setSamplePreview((prev) => [
        ...prev,
        {
          name: file.name,
          size: fsize,
          preview: previewImage,
          file: base64,
        },
      ]);

      return;
    });
  };

  const convertBase64 = async (file, isBlob) => {
    let blob = file;
    if (isBlob) {
      blob = await file.blob();
    }
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(blob);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const clearImageSelected = (index) => {
    const filtered = samplePreview.filter((_, i) => {
      return i !== index;
    });
    setSamplePreview(filtered);
  };

  const handleRejectOrder = async () => {
    IsAlert.fire({
      title: 'Reject',
      text: 'Are you sure want to reject this order?',
      icon: 'warning',
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Reject',
    }).then(async function (isConfirm) {
      if (isConfirm.isConfirmed) {
        let res = await updateStatusOrder(id, 'reject');
        if (res.meta.total > 0 && res.message === 'Success') {
          navigate('/orders');
        } else {
        }
      }
    });
  };

  const handleConfirmOrder = async () => {
    IsAlert.fire({
      title: 'Accept',
      text: 'Are you sure want to accept this order?',
      icon: 'warning',
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Accept',
    }).then(async function (isConfirm) {
      if (isConfirm.isConfirmed) {
        let res = await updateStatusOrder(id, 'confirmed');
        if (res.meta.total > 0 && res.message === 'Success') {
          navigate('/orders');
        } else {
        }
      }
    });
  };

  const handleProcessOrder = async () => {
    // if (subDistrict === "") {
    //     IsAlert.fire({
    //         title: "Process",
    //         text: "Please select one sub district option!",
    //         icon: "warning",
    //         showCancelButton: false,
    //         focusConfirm: true,
    //         confirmButtonText: "Confirm",
    //     })

    //     return;
    // }

    IsAlert.fire({
      title: 'Process',
      text: 'Are you sure want to process this order?',
      icon: 'warning',
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Process',
    }).then(async function (isConfirm) {
      if (isConfirm.isConfirmed) {
        setIsLoadingData(true);
        let itemsData = [];
        let totalPrice = 0;
        let totalLength = 0;
        let totalWidth = 0;
        let totalWeight = 0;
        let totalHeight = 0;

        data?.items?.map((item, index) => {
          itemsData.push({
            name: item?.name,
            price: parseInt(item?.price),
            qty: parseInt(item?.quantity),
          });

          //
          totalPrice += parseInt(item?.price) * parseInt(item?.quantity);
          totalLength += parseInt(item?.lenght) * parseInt(item?.quantity);
          totalWidth += parseInt(item?.width) * parseInt(item?.quantity);
          totalWeight += parseInt(item?.weight) * parseInt(item?.quantity);
          totalHeight += parseInt(item?.height) * parseInt(item?.quantity);

          return;
        });

        let detailsShipper = {
          consignee: {
            name: data?.shipping_address?.recipient,
            phone_number: data?.shipping_address?.phone,
          },
          consigner: {
            name: data?.seller_name,
            phone_number: data?.seller_phone,
          },
          courier: {
            cod: false,
            rate_id: parseInt(data?.courier_service_code),
            use_insurance: true,
          },
          coverage: 'domestic',
          destination: {
            address:
              data?.shipping_address?.address +
              data?.shipping_address?.address2,
            area_id: parseInt(data?.shipping_address?.subdistrict_id),
            lat: '',
            lng: '',
          },
          external_id: data?.order_number,
          origin: {
            address: data?.seller_address,
            area_id: parseInt(data?.subdistrict_id),
            lat: '',
            lng: '',
          },
          package: {
            price: totalPrice,
            length: totalLength,
            width: totalWidth,
            weight: totalWeight,
            package_type: 2,
            items: itemsData,
            height: totalHeight,
          },
          payment_type: 'postpay',
        };

        let res = await packedStatusOrder(parseInt(id), detailsShipper);

        if (res.meta.total > 0 && res.message === 'Success') {
          getOrderDetailData();
          IsAlert.fire({
            title: 'Success',
            text: 'Process order success.',
            icon: 'success',
            showCancelButton: false,
            focusConfirm: true,
            confirmButtonText: 'Confirm',
          });
        } else {
          IsAlert.fire({
            title: 'Error',
            text: res?.message ?? 'Something when wrong.',
            icon: 'error',
            showCancelButton: false,
            focusConfirm: true,
            confirmButtonText: 'Confirm',
          });
        }

        setIsLoadingData(false);
      }
    });
  };

  const handleShippingOrder = async () => {
    if (timeSlot === '') {
      IsAlert.fire({
        title: 'Failed',
        text: 'Please select one of time slot option!',
        icon: 'warning',
        showCancelButton: false,
        focusConfirm: true,
        confirmButtonText: 'Confirm',
      });

      return;
    }

    IsAlert.fire({
      title: 'Shipping',
      text: 'Are you sure want to ship this order?',
      icon: 'warning',
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Confirm',
      reverseButtons: true,
    }).then(async function (isConfirm) {
      if (isConfirm.isConfirmed) {
        props.setLoading(true);

        let imagesUpload = [];
        let videoUpload = '';

        samplePreview.map((image) => {
          imagesUpload.push(image?.file.replace(regexRemoveBase64, ''));
          return;
        });

        sampleVideos.map((video) => {
          videoUpload = video?.file.replace(regexRemoveBase64, '');
          return;
        });

        let payloadShipped = {
          id: parseInt(id),
          order_pickup: {
            data: {
              order_activation: {
                order_id: [data?.shipper_order_id],
                pickup_time: timeSlot,
              },
            },
          },
          images: imagesUpload,
          video: videoUpload,
        };

        let res = await shippedStatusOrder(payloadShipped);
        if (res.meta.total > 0 && res.message === 'Success') {
          getOrderDetailData();
          setSamplePreview([]);
          setSampleVideos([]);
          setTimeSlot('');
          IsAlert.fire({
            title: 'Success',
            text: 'Shipping order success.',
            icon: 'success',
            showCancelButton: false,
            focusConfirm: true,
            confirmButtonText: 'Confirm',
          });
        } else {
          IsAlert.fire({
            title: 'Error',
            text: res?.message ?? 'Something when wrong.',
            icon: 'error',
            showCancelButton: false,
            focusConfirm: true,
            confirmButtonText: 'Confirm',
          });
        }
        props.setLoading(false);
      }
    });
  };

  //   const handleUploadVideo = async (event) => {
  //     const fileExtension = await getFileExtension(event.target.files[0].name);
  //     const fsize =
  //       Math.round((event?.target?.files[0]?.size / 1000000) * 100) / 100;

  //     if (!['mov', 'mp4', 'avi'].includes(fileExtension.toLowerCase())) {
  //       // Alert
  //       IsAlert.fire({
  //         title: 'Error',
  //         text: 'Please upload file with the following extension only: mov, mp4, avi',
  //         icon: 'error',
  //       });
  //     } else if (fsize > 3.0) {
  //       // Alert
  //       IsAlert.fire({
  //         title: 'Error',
  //         text: 'Please upload video with a size of 3 MB or less per file',
  //         icon: 'error',
  //       });
  //     } else {
  //       const previewVideo = URL.createObjectURL(event.target.files[0]);
  //       const base64 = await convertBase64(event.target.files[0]);
  //       setSampleVideos([
  //         ...sampleVideos,
  //         {
  //           name: event?.target?.files[0]?.name,
  //           size: fsize,
  //           preview: previewVideo,
  //           file: base64,
  //         },
  //       ]);
  //     }
  //   };

  const handleUploadVideo = async (files) => {
    if (sampleVideos.length + files.length > 1) {
      IsAlert.fire({
        title: 'Error',
        text: 'Maximum Upload 1 Files',
        icon: 'error',
      });

      return;
    }
    files.forEach(async (file) => {
      const fileExtension = await getFileExtension(file.name);
      const fsize = Math.round((file.size / 1000000) * 100) / 100;
      if (!['mov', 'mp4', 'avi'].includes(fileExtension.toLowerCase())) {
        // Alert
        IsAlert.fire({
          title: 'Error',
          text: 'Please upload file with the following extension only: mov, mp4, avi',
          icon: 'error',
        });

        return;
      }

      if (fsize > 3.0) {
        IsAlert.fire({
          title: 'Error',
          text: 'Please upload video with a size of 3 MB or less per file',
          icon: 'error',
        });
        return;
      }

      const previewVideo = URL.createObjectURL(file);
      const base64 = await convertBase64(file);

      setSampleVideos((prev) => [
        ...prev,
        {
          name: file.name,
          size: fsize,
          preview: previewVideo,
          file: base64,
        },
      ]);

      return;
    });
  };

  const clearVideoSelected = (index) => {
    const filtered = sampleVideos.filter((_, i) => {
      return i !== index;
    });
    setSampleVideos(filtered);
  };

  const handleRequestResolve = async () => {
    IsAlert.fire({
      title: 'Request Resolve',
      text: 'Are you sure want to request resolve?',
      icon: 'warning',
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Request',
    }).then(async function (isConfirm) {
      if (isConfirm.isConfirmed) {
        props.setLoading(true);

        // hit api
        let payload = {
          id: parseInt(id),
        };
        let res = await requestResolveOrder(payload);
        if (res.message === 'Updated') {
          IsAlert.fire({
            title: 'Success',
            text: 'Request resolve success.',
            icon: 'success',
            showCancelButton: false,
            focusConfirm: true,
            confirmButtonText: 'Confirm',
          });
        } else {
          IsAlert.fire({
            title: 'Error',
            text: res?.message ?? 'Something when wrong.',
            icon: 'error',
            showCancelButton: false,
            focusConfirm: true,
            confirmButtonText: 'Confirm',
          });
        }

        props.setLoading(false);
      }
    });
  };

  useEffect(() => {
    let id = getIdFromSlug(slug);
    setId(id);
  }, [slug]);

  useEffect(() => {
    if (id) {
      getOrderDetailData();
    }
  }, [id]);

  useEffect(() => {
    // show hide box image upload
    if (samplePreview.length >= 1) {
      setShowUploadInputImage(false);
    } else {
      if (showUploadInputImage) {
        return;
      }
      setShowUploadInputImage(true);
    }
  }, [samplePreview]);

  useEffect(() => {
    // show hide box video upload
    if (sampleVideos.length >= 1) {
      setShowUploadInputVideo(false);
    } else {
      if (showUploadInputVideo) {
        return;
      }
      setShowUploadInputVideo(true);
    }
  }, [sampleVideos]);

  return (
    <div className="container-fluid mb-4 px-0 tab-navigasi">
      {isLoadingData ? (
        <LoadingComponent classList={['vh-100']} />
      ) : (
        <>
          <div className="mb-4 px-4 px-md-0">
            <a
              onClick={() => navigate('/orders')}
              className="fs-5 fw-bold d-flex flex-row align-items-center"
            >
              <img alt="back-button" src={ArrowLeft} className="icon-4 me-2" />
              <span>Order Detail</span>
            </a>
          </div>

          {data?.status?.toLowerCase() === 'order verified' && (
            <div className="mb-md-5 px-4 px-md-0 border-bottom border-md-0 border-2px mt-4 mt-md-3 pb-4">
              <div className="w-100 d-flex align-items-center justify-content-between rounded-3 b-shadow shadow-md-down-0 p-0 p-md-4">
                <div className="d-none d-md-block">
                  <div className="fw-bold">Order Confirmation</div>
                </div>
                <div className="d-flex flex-column-reverse flex-md-row w-md-100">
                  <button
                    onClick={handleRejectOrder}
                    className="btn btn-line w-md-100 fw-bold mt-3 mt-md-0"
                  >
                    Reject Order
                  </button>
                  <button
                    onClick={handleConfirmOrder}
                    className="btn btn-fill ms-md-3 w-md-100"
                  >
                    Accept Order
                  </button>
                </div>
              </div>
            </div>
          )}

          {data?.status?.toLowerCase() === 'confirmed' && (
            <>
              <div className="mb-md-5 px-4 px-md-0 border-bottom border-md-0 border-2px mt-4 mt-md-3 pb-4">
                <div className="w-100 d-flex align-items-center justify-content-between rounded-3 b-shadow shadow-md-down-0 p-0 p-md-4">
                  <div className="d-none d-md-block">
                    <div className="fw-bold">Order Confirmation</div>
                  </div>
                  <div className="d-flex flex-column-reverse flex-md-row w-md-100">
                    <button
                      className="btn btn-fill ms-md-3 w-md-100"
                      onClick={handleProcessOrder}
                    >
                      Processing
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="modalProcessing"
                tabIndex="-1"
                aria-labelledby="modalProcessingLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content p-3">
                    <div className="modal-header border-0">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="w-100 empty-wrap h-unset d-flex flex-column">
                        <label className="form-label d-flex fw-semibold">
                          Sub District<div className="text-danger ps-1">*</div>
                        </label>
                        <select
                          className="form-control"
                          value={subDistrict}
                          onChange={(event) => {
                            setSubDistrict(event.target.value);
                          }}
                        >
                          <option value="">Choose Sub District</option>
                          {couriers.map((item, index) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.subdistrict_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="modal-footer border-0 position-relative">
                      <div className="w-50 m-0 p-1">
                        <button
                          type="button"
                          className="btn w-100 btn-line fw-semibold"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="w-50 m-0 p-1">
                        <button
                          type="button"
                          className="btn w-100 m-0 m-sm-1 btn-fill fw-semibold"
                          data-bs-dismiss="modal"
                          onClick={handleProcessOrder}
                        >
                          Process
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {data?.status?.toLowerCase() === 'packing' && (
            // https://www.youtube.com/watch?v=3pQY--GR2sI
            // https://www.youtube.com/watch?v=0TTa5Ulmgds
            <>
              <div className="mb-md-5 px-4 px-md-0 border-bottom border-md-0 border-2px mt-4 mt-md-3 pb-4">
                <div className="w-100 d-flex align-items-center justify-content-between rounded-3 b-shadow shadow-md-down-0 p-0 p-md-4">
                  <div className="row d-flex flex-column-reverse flex-md-row w-md-100">
                    <div className="d-flex ">
                      <div className="w-100 px-2">
                        <span className="fw-bold d-block text-secondary mb-2">
                          Photo
                        </span>
                        <p className="text-secondary">
                          Attach a photo of product packaging
                        </p>
                        {/* <div className="w-100">
                          <label
                            htmlFor="file-upload"
                            className="upload-file-custom"
                          >
                            <div>
                              <div className="w-100 d-flex align-items-center justify-content-center">
                                <div className="img-wrap">
                                  {isEdit && (
                                    <img
                                      src="../../dist/img/custom/image.svg"
                                      className="w-4rem"
                                      alt="img-upload"
                                    />
                                  )}
                                  {!isEdit && (
                                    <img
                                      src="../../../dist/img/custom/image.svg"
                                      className="w-4rem"
                                      alt="img-upload"
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="w-100 d-flex align-items-center justify-content-center mt-4">
                                <p className="mb-0 text-center">
                                  Drop your photos here, or{' '}
                                  <strong>browse</strong>
                                </p>
                              </div>
                              <div className="w-100 d-flex align-items-center justify-content-center mt-1">
                                <p className="mb-0 text-center text-secondary fz-14">
                                  Max. 3MB per file with format .jpeg, .jpg,
                                  .png (Max. 1 files)
                                </p>
                              </div>
                            </div>
                          </label>
                          <input
                            className="visually-hidden"
                            id="file-upload"
                            type="file"
                            accept="image/*"
                            onChange={handleUpload}
                            disabled={samplePreview.length >= 1}
                          />
                        </div> */}
                        {showUploadInputImage ? (
                          <FileUpload
                            upload={handleUpload}
                            samplePreviews={samplePreview}
                            maxFile={1}
                            maxSizePerFile={3}
                          />
                        ) : null}

                        <div className="d-flex justify-content-center">
                          <div className="w-100 row row-cols-1 row-cols-md-1 row-cols-lg-1 g-3 mt-3 me-0">
                            {samplePreview.map((image, index) => {
                              return (
                                <div key={index}>
                                  <div className="border rounded-3 p-3 d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                      <div className="img-wrap w-5rem ar-square rounded-3 overflow-hidden d-flex align-items-center justify-content-center">
                                        <div className="w-100 h-100">
                                          <img
                                            id="imageId"
                                            src={image.preview}
                                            className="object-fit-cover w-5rem ar-square"
                                            alt="Preview"
                                          />
                                        </div>
                                      </div>
                                      <div className="col ms-3">
                                        <p className="mb-0 text-wrap-1">
                                          {image.name}
                                        </p>
                                        <div className="fz-14 text-secondary mt-3">
                                          {image.size} Mb
                                        </div>
                                      </div>
                                    </div>
                                    <button className="btn p-0">
                                      {isEdit && (
                                        <img
                                          src="../dist/img/custom/x.svg"
                                          className="icon-3"
                                          alt="img-delete"
                                          onClick={clearImageSelected.bind(
                                            this,
                                            index
                                          )}
                                        />
                                      )}
                                      {!isEdit && (
                                        <img
                                          src="../../../dist/img/custom/x.svg"
                                          className="icon-3"
                                          alt="img-delete"
                                          onClick={clearImageSelected.bind(
                                            this,
                                            index
                                          )}
                                        />
                                      )}
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="w-100 px-2">
                        <span className="fw-bold d-block text-secondary mb-2">
                          Video
                        </span>
                        <p className="text-secondary">
                          Attach the product packaging video
                        </p>
                        {/* <div className="w-100">
                          <label
                            htmlFor="video-upload"
                            className="upload-file-custom"
                          >
                            <div>
                              <div className="w-100 d-flex align-items-center justify-content-center">
                                <div className="img-wrap">
                                  <img
                                    src="../../../dist/img/custom/upload-video.svg"
                                    className="w-4rem"
                                    alt="img-upload-video"
                                  />
                                </div>
                              </div>
                              <div className="w-100 d-flex align-items-center justify-content-center mt-4">
                                <p className="mb-0 text-center">
                                  Drop your videos here, or{' '}
                                  <strong>browse</strong>
                                </p>
                              </div>
                              <div className="w-100 d-flex align-items-center justify-content-center mt-1">
                                <p className="mb-0 text-center text-secondary fz-14">
                                  Max. 3MB, 1 file with format .MOV, .MP4, .AVI
                                </p>
                              </div>
                            </div>
                          </label>
                          <input
                            className="visually-hidden"
                            id="video-upload"
                            type="file"
                            accept="video/*"
                            onChange={handleUploadVideo}
                            disabled={sampleVideos.length >= 1}
                          />
                        </div> */}
                        {showUploadInputVideo ? (
                          <FileUpload
                            id={'video-upload'}
                            upload={handleUploadVideo}
                            samplePreviews={sampleVideos}
                            maxFile={1}
                            maxSizePerFile={3}
                            video
                          />
                        ) : null}

                        <div className="d-flex justify-content-center">
                          <div className="w-100 row row-cols-1 row-cols-md-1 row-cols-lg-1 g-3 mt-3 me-0">
                            {sampleVideos.map((video, index) => {
                              return (
                                <div key={index}>
                                  <div className="border rounded-3 p-3 d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                      <div className="img-wrap w-5rem ar-square rounded-3 overflow-hidden d-flex align-items-center justify-content-center">
                                        <div className="w-100 h-100">
                                          <VideoThumbnail
                                            videoUrl={video.preview}
                                          />
                                        </div>
                                      </div>
                                      <div className="col ms-3">
                                        <p className="mb-0 text-wrap-1">
                                          {video.name}
                                        </p>
                                        <div className="fz-14 text-secondary mt-3">
                                          {video.size} Mb
                                        </div>
                                      </div>
                                    </div>
                                    <button className="btn p-0">
                                      {isEdit && (
                                        <img
                                          src="../dist/img/custom/x.svg"
                                          className="icon-3"
                                          alt="img-delete"
                                          onClick={clearVideoSelected.bind(
                                            this,
                                            index
                                          )}
                                        />
                                      )}
                                      {!isEdit && (
                                        <img
                                          src="../../../dist/img/custom/x.svg"
                                          className="icon-3"
                                          alt="img-delete"
                                          onClick={clearVideoSelected.bind(
                                            this,
                                            index
                                          )}
                                        />
                                      )}
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-100 d-flex align-items-center justify-content-between rounded-3 p-0 p-md-4">
                      <div className="d-none d-md-block">
                        <div className="fw-bold"></div>
                      </div>
                      <div className="d-flex flex-column-reverse flex-md-row w-md-100">
                        <button
                          className="btn btn-fill ms-md-3 w-md-100"
                          disabled={!(samplePreview.length > 0)}
                          data-bs-toggle="modal"
                          data-bs-target="#modalShipping"
                        >
                          Shipping
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="modalShipping"
                tabIndex="-1"
                aria-labelledby="modalShippingLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content p-3">
                    {/* <div className="modal-header border-0">
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div> */}
                    <div className="modal-body">
                      <div className="w-100 empty-wrap h-unset d-flex flex-column">
                        <label className="form-label d-flex fw-semibold">
                          Pickup Time Slot
                          <div className="text-danger ps-1">*</div>
                        </label>
                        <select
                          className="form-control"
                          value={timeSlot}
                          onChange={(event) => {
                            setTimeSlot(event.target.value);
                          }}
                        >
                          <option value="">Choose Pickup Time Slot</option>
                          {timeSlots.map((item, index) => {
                            return (
                              <option key={index} value={item.start_time}>
                                {moment(item.start_time).format(
                                  'Do MMMM HH:mm:ss'
                                )}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="modal-footer border-0 position-relative">
                      <div className="w-50 m-0 p-1">
                        <button
                          type="button"
                          className="btn w-100 btn-line fw-semibold"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="w-50 m-0 p-1">
                        <button
                          type="button"
                          className="btn w-100 m-0 m-sm-1 btn-fill fw-semibold"
                          data-bs-dismiss="modal"
                          onClick={handleShippingOrder}
                          disabled={!timeSlot}
                        >
                          Process Shipping
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {(data?.status?.toLowerCase() === 'on delivery' ||
            data?.status?.toLowerCase() === 'received by customer' ||
            data?.status?.toLowerCase() === 'Completed') && (
            <div className="mb-md-5 px-4 px-md-0 border-bottom border-md-0 border-2px mt-4 mt-md-3 pb-2">
              <div className="w-100 rounded-3 flex-column flex-md-row-reverse b-shadow shadow-md-down-0 d-flex p-0 p-md-4">
                <VerticalTimeline
                  layout="1-column-left"
                  animate={false}
                  lineColor="black"
                >
                  {trackings.map((tracking, index) => {
                    return (
                      <VerticalTimelineElement className="vertical-timeline-element--work">
                        <div className="d-flex justify-content-between">
                          <div className="mt-0">
                            <p className="mt-0">
                              {tracking?.shipper_status?.name}
                            </p>
                            <span>{tracking?.shipper_status?.description}</span>
                          </div>
                          <span
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {moment(tracking?.created_date).format(
                              'Do MMMM HH:mm'
                            )}
                          </span>
                        </div>
                      </VerticalTimelineElement>
                    );
                  })}
                </VerticalTimeline>
              </div>
            </div>
          )}

          <div className="mb-md-5 px-4 px-md-0 border-bottom border-md-0 border-2px mt-4 mt-md-3 pb-2">
            <div className="w-100 rounded-3 flex-column flex-md-row-reverse b-shadow shadow-md-down-0 d-flex p-0 p-md-4">
              <div className="col-2 w-md-100 border-bottom border-md-0 pb-3 pb-md-0">
                {data?.print_label &&
                  data?.status?.toLowerCase() !== 'new order' &&
                  data?.status?.toLowerCase() !== 'confirmed' &&
                  data?.status?.toLowerCase() !== 'packing' && (
                    <>
                      <p className="fz-14 fw-bold text-secondary mb-2 d-none d-md-block">
                        PRINT LABEL SHIPPING DETAIL
                      </p>
                      <a
                        href={data?.print_label}
                        target="_blank"
                        className="btn m-0 m-sm-1 btn-fill fw-semibold"
                        rel="noreferrer"
                      >
                        Print
                      </a>
                    </>
                  )}
              </div>
              <div className="col-2 w-md-100 border-bottom border-md-0 pb-3 pb-md-0">
                {data?.print_label &&
                  data?.status?.toLowerCase() !== 'new order' &&
                  data?.status?.toLowerCase() !== 'confirmed' &&
                  data?.status?.toLowerCase() !== 'packing' &&
                  data?.status?.toLowerCase() !== 'waiting for pickup' && (
                    <>
                      <p className="fz-14 fw-bold text-secondary mb-2 d-none d-md-block">
                        RESI NO
                      </p>
                      <span>{data?.tracking_number}</span>
                    </>
                  )}
              </div>
              <div className="col-2 w-md-100 border-bottom border-md-0 pb-3 pb-md-0">
                <p className="fz-14 fw-bold text-secondary mb-2 d-none d-md-block">
                  STATUS
                </p>
                <div className="badge-gray">{data?.status}</div>
                {/* {data?.status?.toLowerCase() == "order verified" && <div className="badge-yellow">New Order</div>}
                            {data?.status?.toLowerCase() == "confirmed" && <div className="badge-green">Confirmed</div>}
                            {data?.status?.toLowerCase() == "packing" && <div className="badge-green">Processing</div>}
                            {data?.status?.toLowerCase() == "packed" && <div className="badge-gray">{data?.status}</div>}
                            {data?.status?.toLowerCase() == "shipped" && <div className="badge-gray">{data?.status}</div>}
                            {data?.status?.toLowerCase() == "cancel" && <div className="badge-red">{data?.status}</div>} */}
              </div>
              <div className="col-6 w-md-100 pt-3 pt-md-0">
                <div className="row row-cols-1 row-cols-md-2 gx-3">
                  <div className="col">
                    <div className="w-100 d-flex align-items-start d-md-block">
                      <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">
                        ORDER NO
                      </p>
                      <p className="col-6 col-md-12 text-end text-md-start mb-2">
                        {data?.order_number}
                      </p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="w-100 d-flex align-items-start d-md-block">
                      <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">
                        ORDER DATE
                      </p>
                      <p className="col-6 col-md-12 text-end text-md-start mb-2">
                        {validateString(
                          formatDateTimeSecond(new Date(data?.date))
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {validateString(data?.complain_date) !== '-' &&
            data?.status?.toLowerCase() !== 'refund' && (
              <div className="mb-md-5 px-4 px-md-0 border-bottom border-md-0 border-2px mt-4 mt-md-3 pb-2">
                <div className="w-100 rounded-3 flex-column flex-md-row-reverse b-shadow shadow-md-down-0 d-flex p-0 p-md-4">
                  {data?.status?.toLowerCase() === 'order complain' && (
                    <div className="col-3 w-md-100 border-bottom border-md-0 pb-3 pb-md-0">
                      <button
                        type="button"
                        className="btn m-0 m-sm-1 btn-fill fw-semibold"
                        onClick={handleRequestResolve}
                      >
                        Request Resolve
                      </button>
                    </div>
                  )}
                  <div
                    className={`col-${
                      data?.status?.toLowerCase() === 'order complain'
                        ? '3'
                        : '6'
                    } w-md-100 border-bottom border-md-0 pb-3 pb-md-0`}
                  >
                    <p className="fz-14 fw-bold text-secondary mb-2 d-none d-md-block">
                      COMPLAIN DURATION
                    </p>
                    <p className="col-6 col-md-12 text-end text-md-start mb-2">
                      {validateString(data?.complain_duration) === '-'
                        ? '-'
                        : validateString(data?.complain_duration)}
                    </p>
                  </div>
                  <div className="col-6 w-md-100 pt-3 pt-md-0">
                    <div className="row row-cols-1 row-cols-md-2 gx-3">
                      <div className="col">
                        <div className="w-100 d-flex align-items-start d-md-block">
                          <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">
                            COMPLAIN DATE
                          </p>
                          <p className="col-6 col-md-12 text-end text-md-start mb-2">
                            {validateString(data?.complain_date) === '-'
                              ? '-'
                              : validateString(data?.complain_date)}
                          </p>
                        </div>
                      </div>
                      <div className="col">
                        <div className="w-100 d-flex align-items-start d-md-block">
                          <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">
                            RESOLVE DATE
                          </p>
                          <p className="col-6 col-md-12 text-end text-md-start mb-2">
                            {validateString(data?.resolve_date) === '-'
                              ? '-'
                              : validateString(data?.resolve_date)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          <div className="w-100 d-flex align-items-lg-stretch flex-column flex-lg-row">
            <div className="col-12 col-lg-6 mb-md-5 px-4 pe-md-0 ps-md-0 pe-lg-3 border-bottom border-md-0 border-2px mt-4 mt-md-3 pb-2">
              <div className="w-100 rounded-3 b-shadow shadow-md-down-0 p-0 p-md-4 h-100">
                <div className="fw-bold mb-4">Shipping Detail</div>
                <div className="d-flex flex-column flex-md-row flex-lg-column">
                  <div className="col-lg-12 w-md-100">
                    <div className="row row-cols-1 row-cols-md-2 gx-3">
                      <div className="col mb-3">
                        <div className="w-100 d-flex align-items-start d-md-block">
                          <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">
                            COURIER
                          </p>
                          <p className="col-6 col-md-12 text-end text-md-start mb-2">
                            {validateString(data?.courier_name)}
                          </p>
                        </div>
                      </div>
                      <div className="col mb-3">
                        <div className="w-100 d-flex align-items-start d-md-block">
                          <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">
                            DISPATCH DATE
                          </p>
                          <p className="col-6 col-md-12 text-end text-md-start mb-2">
                            {data?.dispatch_date
                              ? formatDate(new Date(data?.dispatch_date))
                              : '-'}
                          </p>
                        </div>
                      </div>
                      <div className="col mb-3">
                        <div className="w-100 d-flex align-items-start d-md-block">
                          <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">
                            NAME
                          </p>
                          <p className="col-6 col-md-12 text-end text-md-start mb-2">
                            {validateString(data?.shipping_address?.recipient)}
                          </p>
                        </div>
                      </div>
                      <div className="col mb-3">
                        <div className="w-100 d-flex align-items-start d-md-block">
                          <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">
                            ADDRESS
                          </p>
                          <p className="col-6 col-md-12 text-end text-md-start mb-2">
                            {validateString(data?.shipping_address?.address)}
                          </p>
                        </div>
                      </div>
                      <div className="col mb-3">
                        <div className="w-100 d-flex align-items-start d-md-block">
                          <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">
                            PROVINCE
                          </p>
                          <p className="col-6 col-md-12 text-end text-md-start mb-2">
                            {validateString(
                              data?.shipping_address?.province_name
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col mb-3">
                        <div className="w-100 d-flex align-items-start d-md-block">
                          <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">
                            CITY
                          </p>
                          <p className="col-6 col-md-12 text-end text-md-start mb-2">
                            {validateString(data?.shipping_address?.city_name)}
                          </p>
                        </div>
                      </div>
                      <div className="col mb-3">
                        <div className="w-100 d-flex align-items-start d-md-block">
                          <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">
                            DISTRICT
                          </p>
                          <p className="col-6 col-md-12 text-end text-md-start mb-2">
                            {validateString(
                              data?.shipping_address?.district_name
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col mb-3">
                        <div className="w-100 d-flex align-items-start d-md-block">
                          <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">
                            SUB DISTRICT
                          </p>
                          <p className="col-6 col-md-12 text-end text-md-start mb-2">
                            {validateString(
                              data?.shipping_address?.subdistrict_name
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col mb-3">
                        <div className="w-100 d-flex align-items-start d-md-block">
                          <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">
                            POST CODE
                          </p>
                          <p className="col-6 col-md-12 text-end text-md-start mb-2">
                            {validateString(
                              data?.shipping_address?.postal_code
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col mb-3">
                        <div className="w-100 d-flex align-items-start d-md-block">
                          <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">
                            PHONE
                          </p>
                          <p className="col-6 col-md-12 text-end text-md-start mb-2">
                            {validateString(data?.shipping_address?.phone)}
                          </p>
                        </div>
                      </div>
                      {/* <div className="col mb-3">
                                            <div className="w-100 d-flex align-items-start d-md-block">
                                                <p className="col-6 col-md-12 fz-14 fw-bold text-secondary mb-2">ORDER NOTE</p>
                                                <p className="col-6 col-md-12 text-end text-md-start mb-2">{validateString(data?.order_notes)}</p>
                                            </div>
                                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-md-5 px-4 pe-md-0 ps-md-0 border-bottom border-md-0 border-2px mt-4 mt-md-3 pb-2">
              <div className="w-100 rounded-3 b-shadow shadow-md-down-0 p-0 p-md-4 h-100">
                <div className="fw-bold mb-4">Payment</div>
                <div className="d-flex flex-column flex-md-row flex-lg-column">
                  <div className="col-lg-12 w-md-100">
                    <div className="row row-cols-1 gx-3">
                      <div className="col">
                        <div className="w-100 d-flex align-items-start">
                          <p className="col-6 fz-14 fw-bold text-secondary mb-2">
                            TOTAL AMOUNT
                          </p>
                          <p className="col-6 text-end mb-2">
                            {validateString(
                              formatMoney(data?.sub_total, 'Rp.', 0, ',', '.')
                            )}
                          </p>
                        </div>
                      </div>
                      {/* <div className="col">
                                            <div className="w-100 d-flex align-items-start a">
                                                <p className="col-6 fz-14 fw-bold text-secondary mb-2">TOTAL DISCOUNT</p>
                                                <p className="col-6 text-end mb-2">{validateString(formatMoney(data?.discount, "Rp.", 0, ",", "."))}</p>
                                            </div>
                                        </div> */}
                      <div className="col">
                        <div className="w-100 d-flex align-items-start">
                          <p className="col-6 fz-14 fw-bold text-secondary mb-2">
                            VOUCHER
                          </p>
                          <p className="col-6 text-end mb-2">
                            {validateString(
                              formatMoney(data?.voucher, 'Rp.', 0, ',', '.')
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col">
                        <div className="w-100 d-flex align-items-start">
                          <p className="col-6 fz-14 fw-bold text-secondary mb-2">
                            SHIPPING (+Insurance)
                          </p>
                          <p className="col-6 text-end mb-2">
                            {validateString(
                              formatMoney(data?.shipping, 'Rp.', 0, ',', '.')
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col">
                        <div className="w-100 d-flex align-items-start">
                          <p className="col-6 fz-14 fw-bold text-secondary mb-2">
                            SERVICE FEE
                          </p>
                          <p className="col-6 text-end mb-2">
                            {validateString(
                              formatMoney(data?.service_fee, 'Rp.', 0, ',', '.')
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col">
                        <div className="w-100 d-flex align-items-start">
                          <p className="col-6 fz-14 fw-bold text-secondary mb-2">
                            TOTAL AMOUNT AFTER
                          </p>
                          <p className="col-6 text-end mb-2">
                            {validateString(
                              formatMoney(data?.total, 'Rp.', 0, ',', '.')
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-100 rounded-3 b-shadow shadow-md-down-0 p-0 p-md-4 h-100">
            <div className="w-100 px-4 mt-4 mt-md-3 px-md-0">
              <table
                id="table_id2"
                className="table table-striped"
                style={{ width: '100%' }}
              >
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th>Name</th>
                    <th>Variant</th>
                    <th>Qty</th>
                    <th className="w-15-persen">Price</th>
                    <th>Discount</th>
                    <th>Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.items?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{validateString(item?.sku)}</td>
                        <td>{validateString(item?.name)}</td>
                        <td>
                          {validateString(item?.size + ' - ' + item?.color)}
                        </td>
                        <td>{validateString(item?.quantity)}</td>
                        <td>
                          {validateString(
                            formatMoney(item?.price, 'Rp.', 0, ',', '.')
                          )}
                        </td>
                        <td>
                          {validateString(
                            formatMoney(
                              item?.discount_amount,
                              'Rp.',
                              0,
                              ',',
                              '.'
                            )
                          )}
                        </td>
                        <td>
                          {validateString(
                            formatMoney(item?.total, 'Rp.', 0, ',', '.')
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapState = (state) => {
  return {
    loadingGeneral: state.loadingGeneral,
  };
};

const actionCreators = (dispatch) => {
  return {
    setLoading: (data) => dispatch({ type: 'CHANGE_LOADING', payload: data }),
  };
};

export default connect(mapState, actionCreators)(OrderDetail);
