import { useState } from 'react';
import ScrollTracker from '../ScrollTracker/ScrollTracker';
import PdfViewer from '../PdfViewer/PdfViewer';

const ModalAgreement = () => {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  function handleUpdateScrollPercentage(value) {
    setScrollPercentage(value);
  }

  return (
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5 fw-bold" id="exampleModalLabel">
              Agreement
            </h1>
            {/* <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button> */}
          </div>
          <div class="modal-body">
            <ScrollTracker handleIndicator={handleUpdateScrollPercentage}>
              <PdfViewer
                fileUrl={
                  'https://kisahruang.s3.ap-southeast-1.amazonaws.com/internal/Perjanjian_Kerjasama.pdf'
                }
              />
            </ScrollTracker>
          </div>
          <div class="modal-footer">
            <button
              disabled={scrollPercentage !== 100}
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Understand
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAgreement;
