import React from "react";
import { useEffect, useState } from "react";

const PreviewImage = ({ imageUrl, setImageViewUrl }) => {
    const [isShow, setIsShow] = useState(false);

    useEffect(() => {
        if (imageUrl) {
            setIsShow(true)
        }
    }, [imageUrl]);

    useEffect(() => {
        if (isShow === false) {
            setImageViewUrl("")
        }
    }, [isShow]);

    return (
        <div style={{
            display: isShow ? 'block' : 'none',
            position: 'fixed',
            zIndex: 2000,
            paddingTop: '50px',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            overflow: 'auto',
            backgroundColor: 'rgba(0,0,0,0.4)'
        }}>
            <div style={{
                backgroundColor: '#fefefe',
                margin: 'auto',
                padding: '20px',
                border: '1px solid #888',
                width: '50%',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid rgb(204 204 204)',
                    marginBottom: '5%'
                }}>
                    <h4>Preview Image</h4>
                    <span style={{
                        color: '#aaaaaa',
                        float: 'right',
                        fontSize: '28px',
                        fontWeight: 'bold',
                        textDecoration: 'none',
                        cursor: 'pointer'
                    }} onClick={() => { setIsShow(false) }}>&times;</span>
                </div>

                {(imageUrl) && (
                    <img src={imageUrl} alt="gambar-preview" style={{
                        maxHeight: '600px',
                        width: '100%',
                        objectFit: 'contain'
                    }} />
                )}
            </div>
        </div>
    )
}

export default PreviewImage;