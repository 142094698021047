import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';
import { getListPortfolio, setCoverPortfolio } from '../../../helper/function';
import { connect } from 'react-redux';

const Portfolio = (props) => {
  const [portfolio, setPortfolio] = useState([]);
  const [coverId, setCoverId] = useState(null);
  const [mode, setMode] = useState('list');
  const [tsx, setTsx] = useState(Date.now());
  const getListPortfolioData = async () => {
    const response = await getListPortfolio();
    if (response.message === 'Success') {
      setPortfolio(response.data);
    } else {
      setPortfolio([]);
    }
  };

  const handleSetCoverId = (e) => {
    setCoverId(e.target.value);
  };

  const submitSetCoverPortfolio = async () => {
    const response = await setCoverPortfolio(coverId);
    if (response.message === 'Success') {
      setMode('list');
      setCoverId(null);
    }
  };

  useEffect(() => {
    getListPortfolioData();
  }, []);

  const CardList = (props) => (
    <div className="col-6 p-2 d-flex flex-row">
      {props?.mode === 'list' ? null : (
        <input
          type="radio"
          name="cover-portfolio"
          value={props?.id}
          className="me-3"
          onChange={(e) => handleSetCoverId(e)}
          checked={props?.coverId === props?.id.toString()}
        />
      )}

      <div className="rounded-lg border w-100 d-flex align-items-center p-3 mt-3 shadow-sm">
        <div className="col-7 col-md-5 pe-2 d-flex align-items-center justify-content-center">
          <div className="img-wrap" style={{ width: '200px', height: '200px' }}>
            <img alt="" src={`${props.image}?tsx=${tsx}`} />
          </div>
        </div>
        <div className="col-5 col-md-7 pe-md-5 d-flex flex-row align-items-center">
          <div className="w-100">
            <div className="mb-2 fz-14 fw-bold">NAME</div>
            <div className="mb-2 fz-14 ">{props.name}</div>
            <div className="mb-2 fz-12 fw-bold">
              <NavLink
                to={`/designer/portfolio/view/${props.id}`}
                className="badge-yellow"
                style={{ fontSize: 'unset' }}
              >
                View
              </NavLink>
            </div>
            <div className="mb-2">&nbsp;</div>
            <div className="mb-2 fz-14 fw-bold ml-3">STYLE</div>
            <div className="mb-2 fz-14 ">
              {props.style && (
                <>
                  {props.style.replaceAll('[', '').replaceAll(']', '') === '' &&
                    '-'}

                  {props.style
                    .replaceAll('[', '')
                    .replaceAll(']', '')
                    .replaceAll(',', ', ')}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return portfolio.length > 0 ? (
    <div className="container-fluid mb-4 px-0">
      <div className="d-flex flex-row mb-4">
        <div className="fs-5 fw-bold mt-2 mb-4">Portfolio List</div>
      </div>
      <div className="d-flex flex-row-reverse">
        <NavLink
          to="/designer/portfolio/add-new"
          className="btn btn-fill fw-bold w-lg-100 me-0 me-lg-3 w-lg-100 mb-3 mb-lg-0"
        >
          Add New Portfolio
        </NavLink>
        {mode === 'set-cover' ? (
          <button
            disabled={coverId === null}
            type="button"
            className="btn btn-fill fw-bold w-lg-100 me-0 me-lg-3 w-lg-100 mb-3 mb-lg-0"
            onClick={submitSetCoverPortfolio}
          >
            Submit Cover Portfolio
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-fill fw-bold w-lg-100 me-0 me-lg-3 w-lg-100 mb-3 mb-lg-0"
            onClick={() => setMode('set-cover')}
          >
            Set Cover Portfolio
          </button>
        )}
      </div>

      <div className="d-flex flex-row flex-wrap">
        {portfolio.map((item, index) => (
          <React.Fragment key={index}>
            {/* {index % 2 === 0 && <div className="row">} */}
            <CardList
              id={item.id}
              name={item.nameTitle}
              style={item.styleDesign}
              image={item?.photos[0]?.full_image_url}
              coverId={coverId}
              mode={mode}
            />
            {/* {index % 2 !== 0 && <div>} */}
          </React.Fragment>
        ))}
      </div>
    </div>
  ) : (
    <div className="container-fluid mb-4 px-0">
      <div className="d-flex flex-row mb-4">
        <div className="fs-5 fw-bold mt-2 mb-4">Portfolio List</div>
      </div>
      <div className="d-flex flex-row-reverse">
        <NavLink
          to="/designer/portfolio/add-new"
          className="btn btn-fill fw-bold w-lg-100 me-0 me-lg-3 w-lg-100 mb-3 mb-lg-0"
        >
          Add New Portfolio
        </NavLink>
      </div>
      <div
        className="d-flex flex-row align-items-center h-100"
        style={{ marginTop: '75px' }}
      >
        <div className="align-self-center mx-auto">
          <div className="img-wrap" style={{ width: '300px', height: '300px' }}>
            <img
              src="../dist/img/empty/empty-product.png"
              alt="img-empty-product"
            />
          </div>
          <div className="d-flex align-items-center justify-content-center mt-3 fw-bold">
            No Portfolio Found!
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => {
  return {
    loadingGeneral: state.loadingGeneral,
  };
};

const actionCreators = (dispatch) => {
  return {
    setLoading: (data) => dispatch({ type: 'CHANGE_LOADING', payload: data }),
  };
};

export default connect(mapState, actionCreators)(Portfolio);
