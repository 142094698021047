import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { fetchApiPost } from "../helper/api";
import $ from "jquery";
import { connect } from "react-redux";
import { IsAlert } from "../atoms/IsAlert";
import { requestPermission, onMessageListener } from "../firebase";

// import { ErrorMessage } from '@hookform/error-message';

const Login = (props) => {
  let navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email harus diisi")
      .email("Harus format email"),
    password: Yup.string().required("Password harus diisi"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const [errorMessage, seterrorMessage] = React.useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = async (data) => {
    props.setLoading(true);
    data.device_model = "linux";
    data.device_type = "WEB";
    data.device_id = localStorage.getItem("firebase_token");
    var req = await fetchApiPost("merchants/session/login", data);
    var res = await req.json();
    if (res.meta.total > 0) {
      localStorage.setItem("token", res.data.access_token);
      props.changeDataProfile(res.profile_data);
      props.setLoading(false);
      IsAlert.fire({
        title: "Success",
        text: res.message,
        icon: "success",
        showCancelButton: false,
        focusConfirm: true,
        confirmButtonText: "Done",
        backdrop: false,
      }).then(function (isConfirm) {
        if (isConfirm.isConfirmed) {
          let merchantType = res?.data?.profile_data?.merchant_type.toLowerCase();

          if(merchantType === 'vendor' || merchantType === 'vendor + designer') {
            navigate('/')
          } else {
            navigate('/designer')
          }
        }
      });
      // navigate('/')
    } else {
      props.setLoading(false);
      seterrorMessage(res.message);
    }
  };
  React.useEffect(() => {
    //localStorage.clear();
    showHidePassword();
    console.log("masuk request login");
    requestPermission();
  }, []);
  const showHidePassword = () => {
    $("i#show").click(function () {
      $(this).addClass("d-none").removeClass("d-block");
      $(this).parent().find("i#hide").addClass("d-block").removeClass("d-none");
      $(this).parent().parent().parent().find("input").attr("type", "text");
    });
    $("i#hide").click(function () {
      $(this).addClass("d-none").removeClass("d-block");
      $(this).parent().find("i#show").addClass("d-block").removeClass("d-none");
      $(this).parent().parent().parent().find("input").attr("type", "password");
    });
  };
  return (
    <div className="wrap-content signin">
      <div className="row h-100">
        <div className="col-12 h-100 position-relative form-parent">
          <div className="d-flex align-items-center justify-content-center form-wrap px-5 px-md-0">
            <form method="post" onSubmit={handleSubmit(onSubmit)}>
              <div className="d-flex align-items-center justify-content-center mb-3 head">
                <div className="img-wrap">
                  <img src="./dist/img/logo_kisahruang.png" alt="img-logo" />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center mb-3">
                <span className="pe-1 text-gray fs-3 text-center fw-bold">
                  Welcome Back!
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-center mb-5">
                <span className="pe-1 text-gray text-center fz-14">
                  Enter your email and password below to log in.
                </span>
              </div>
              <div className="mb-3">
                <label className="form-labe fz-12 fw-bold">Email</label>
                <input
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                  {...register("email", {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                  type="text"
                />
                {errors.email && (
                  <div className="invalid-feedback">
                    {errors.email?.message}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label fz-12 fw-bold">Password</label>
                <div
                  className={`d-flex border bg-white rounded password ${
                    errors.password ? "is-invalid" : ""
                  }`}
                >
                  <input
                    {...register("password")}
                    className={`form-control border-0 ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    type="password"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                  <span
                    className="input-group-text bg-white border-0"
                    id="basic-addon1"
                  >
                    <i className="fas fa-eye-slash text-secondary" id="show" />
                    <i
                      className="fas fa-eye text-secondary d-none"
                      id="hide"
                    />
                  </span>
                </div>
                {errors.password && (
                  <div className="invalid-feedback">
                    {errors.password?.message}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <Link
                  to="/forgot-password"
                  className="link-dark fz-14 fw-semibold"
                >
                  Forgot your password?
                </Link>
              </div>
              {errorMessage && (
                <div
                  className="text-danger alert alert-danger d-flex align-items-center py-2 border-0"
                  role="alert"
                >
                  <img
                    src="./dist/img/custom/info-danger.svg"
                    style={{ width: "23px" }}
                    alt="warning"
                  />
                  <div className="fz-14 ps-2">{errorMessage}</div>
                </div>
              )}
              <button type="submit" className="btn w-100 fw-semibold btn-fill">
                Login
              </button>
              <div className="d-flex align-items-center justify-content-center mt-3 fz-14 ask">
                <span className="pe-1">Don't have an account?</span>
                <span className="">
                  <Link to="/register" className="link-dark fw-semibold">
                    Sign Up
                  </Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => {
  return {};
};

const actionCreators = (dispatch) => {
  return {
    changeDataProfile: (data) => dispatch({ type: "CHANGE_DATA_PROFILE", payload: data }),
    setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
  };
};
export default connect(mapState, actionCreators)(Login);
