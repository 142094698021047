import React from "react";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import ModalAddCourier from "../../organism/Profile/Courier/modals/ModalAddCourier";
import { getVendorCourier, activateCourier, deleteCourier, activateAllCouriers } from '../../helper/function';
import { IsAlert } from "../../atoms/IsAlert";
import LoadingComponent from "../../atoms/LoadingComponent";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import $ from "jquery";

const Courier = (props) => {

    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isAllCourierActive, setIsAllCourierActive] = useState(false);

    const getVendorCourierData = async () => {
        setIsLoadingData(true);
        setData([]);
        const response = await getVendorCourier();
        if (response.message === "Success" && response.data.length > 0) {
            setIsLoadingData(false);
            // sort by courier name
            let data = response.data.sort((a, b) => {
                if (a.courier_name < b.courier_name) { return -1; }
                if (a.courier_name > b.courier_name) { return 1; }
                return 0;
            })

            setData(data);
        } else {
            setIsLoadingData(false);
        }
    }

    const checkIsAllCourierActive = () => {
        let dataActive = data.filter((item) => item?.is_active === true)

        setIsAllCourierActive(dataActive?.length === data?.length);
    }

    const handleEdit = (e) => {
        let dataToBeEdited = data.filter((item) => item.id === e.id)[0];
        setDataEdit(dataToBeEdited);
    }

    const handleAddNewCourier = () => {
        setDataEdit(null);
    }

    const handleCourierActivation = (id, e) => {
        IsAlert.fire({
            title: "Warning",
            text: `Are you sure want to ${e.target.checked ? "activate" : "deactivate"} this courier ?`,
            icon: "warning",
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonText: e.target.checked ? "Activate" : "Deactivate",
        }).then(function (isConfirm) {
            if (isConfirm.isConfirmed) {
                let dataSend = {
                    id: id,
                    is_active: e.target.checked
                }

                const loadingToast = toast.loading(`${e.target.checked ? "Activating" : "Deactivating"} courier...`)

                activateCourier(dataSend)
                    .then((response) => {

                        if (response.message === "Success") {
                            getVendorCourierData();
                            toast.update(loadingToast, { render: `Courier ${e.target.checked ? "Activated" : "Deactivated"}`, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: true });
                        } else {
                            e.target.checked = !e.target.checked;
                            toast.update(loadingToast, { render: response.message, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: true });
                        }
                    })
                    .catch((error) => {
                        e.target.checked = !e.target.checked;
                    })

            } else {
                e.target.checked = !e.target.checked;
            }

        });
    }

    const handleCourierDelete = (item) => {
        IsAlert.fire({
            title: "Warning",
            text: "Are you sure want to delete this courier ?",
            icon: "warning",
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonText: "Delete",
        }).then(function (isConfirm) {
            if (isConfirm.isConfirmed) {
                let dataSend = {
                    id: item.id,
                }

                const loadingToast = toast.loading("Deleting courier...")
                deleteCourier(dataSend)
                    .then((response) => {
                        if (response.message === "Success") {
                            getVendorCourierData();
                            toast.update(loadingToast, { render: "Courier deleted", type: "success", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: true });
                        } else {
                            toast.update(loadingToast, { render: response.message, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: true });
                        }
                    })
                    .catch((error) => {
                    })
            } else {
            }

        });
    }

    const activateAllCourier = (e) => {
        setIsAllCourierActive(e.target.checked)
        IsAlert.fire({
            title: "Warning",
            text: `Are you sure want to ${e.target.checked ? "activate" : "deactivate"} all couriers ?`,
            icon: "warning",
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonText: e.target.checked ? "Activate All" : "Deactivate All",
        }).then(function (isConfirm) {
            if (isConfirm.isConfirmed) {
                const loadingToast = toast.loading(`${e.target.checked ? "Activating" : "Deactivating"} all courier...`)
                activateAllCouriers()
                    .then((response) => {
                        if (response.message === "Success") {
                            getVendorCourierData();
                            toast.update(loadingToast, { render: `All Courier ${e.target.checked ? "Activated" : "Deactivated"}`, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: true });
                            $('.courier-checkbox').prop('checked', e.target.checked)
                        } else {
                            e.target.checked = !e.target.checked;
                            setIsAllCourierActive(!e.target.checked)
                        }
                    })
                    .catch((error) => {
                        e.target.checked = !e.target.checked;
                        setIsAllCourierActive(!e.target.checked)
                    })
            } else {
                e.target.checked = !e.target.checked;
                setIsAllCourierActive(!e.target.checked)
            }
        });
    }

    useEffect(() => {
        getVendorCourierData();
    }, [])

    useEffect(() => {
        checkIsAllCourierActive();
    }, [data])

    return (
        <>
            <div className="tab-content" id="pills-tabContent">
                {/* <!-- Courier --> */}
                <div className="tab-pane fade show active" id="pills-courier" role="tabpanel" aria-labelledby="pills-courier-tab">
                    <div className="fs-5 fw-bold mt-2 mb-4">
                        Manage Own Delivery Courier
                    </div>
                    <div className="d-md-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <input className="form-check-input mt-0 me-3 icon-hw-2 check-yellow" type="checkbox" checked={isAllCourierActive} onChange={activateAllCourier} id="checkbox-all-courier" />
                            <label className="form-check-label" for="checkbox-all-courier">
                                {(isAllCourierActive) && "Deactivate All Couriers"}
                                {(!isAllCourierActive) && "Activate All Couriers"}
                            </label>
                        </div>
                        <div className="d-flex flex-column-reverse flex-md-row w-md-100 mt-4 mt-md-0">
                            <button className="btn btn-line w-md-100 fw-bold mt-3 mt-md-0" type="button" data-bs-toggle="modal" data-bs-target="#ModalAddCourier" onClick={handleAddNewCourier}>
                                Add More Courier
                            </button>
                        </div>
                    </div>
                    <div className="w-100 mt-3">
                        {(data.length > 0 || isLoadingData) && (
                            <table id="table_id2" className="table table-striped" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th className="">No</th>
                                        <th className="min-width-80">Courier Name</th>
                                        <th className="min-width-80">Origin Province</th>
                                        <th className="min-width-80">Origin City</th>
                                        <th className="min-width-80">Origin District</th>
                                        <th className="min-width-80">Origin Subdistrict</th>
                                        <th className="">
                                            <div className="d-flex align-items-center justify-content-center w-100">
                                                Courier Activation
                                            </div>
                                        </th>
                                        <th className="w-9-persen">
                                            <span className="visually-hidden">
                                                Options
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="">{index + 1}</td>
                                                <td className="min-width-80 w-20">{item.courier_name}</td>
                                                <td className="min-width-80 w-20">{item.province_name}</td>
                                                <td className="min-width-80 w-20">{item.city_name}</td>
                                                <td className="min-width-80 w-20">{item.district_name}</td>
                                                <td className="min-width-80 w-20">{item.subdistrict_name}</td>
                                                <td className="w-10">
                                                    <div className="d-flex align-items-center justify-content-center w-100">
                                                        <input className="form-check-input mt-0 icon-hw-2 courier-checkbox" type="checkbox" defaultChecked={item.is_active} onChange={(e) => handleCourierActivation(item.id, e)} id={`courierData${item?.id}`} />
                                                    </div>
                                                </td>
                                                <td className="w-7-persen">
                                                    <div className='d-flex' style={{ gap: '8px' }}>
                                                        <button type="button" className='btn btn-sm btn-warning' title='Edit' data-bs-toggle="modal" data-bs-target="#ModalAddCourier" onClick={() => handleEdit(item)}>
                                                            <FontAwesomeIcon icon={faPencil} color="white" />
                                                        </button>
                                                        <button type="button" onClick={() => handleCourierDelete(item)} className="btn btn-sm btn-danger" data-bs-toggle="tooltip" title='Delete'>
                                                            <FontAwesomeIcon icon={faTrash} color="white" />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        )}
                        {data.length === 0 && isLoadingData && (
                            <LoadingComponent classList={['py-4']} />
                        )}
                        {data.length === 0 && !isLoadingData && (
                            <div className="w-100 empty-wrap d-flex align-items-center justify-content-center">
                                <div className="d-grid px-3 px-sm-0">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className="img-wrap img-wrap-empty">
                                            <img alt="" src={"/dist/img/empty/empty-product.png"} />
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center mt-4">
                                        <div className="fs-6 fw-bold text-center">
                                            No Courier Found!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <ModalAddCourier
                handleClose={() => { }}
                closeOnClickBackdrop={() => { }}
                onSubmit={() => getVendorCourierData()}
                dataEdit={dataEdit}
            />
        </>
    )
}

const mapState = (state) => {
    return {
        loadingGeneral: state.loadingGeneral
    };
};

const actionCreators = (dispatch) => {
    return {
        setLoading: (data) => dispatch({ type: "CHANGE_LOADING", payload: data }),
    };
};

export default connect(mapState, actionCreators)(Courier);
