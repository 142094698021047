/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from 'react';
import './ScrollTracker.modules.css';

const ScrollTracker = ({ children, handleIndicator }) => {
  const innerContentRef = useRef(null);
  //const [scrollPercentage, setScrollPercentage] = useState(0);

  const updateScrollIndicator = () => {
    const innerContent = innerContentRef.current;
    const maxScrollTop = innerContent.scrollHeight - innerContent.clientHeight;
    const scrollPercent = (innerContent.scrollTop / maxScrollTop) * 100;
    //setScrollPercentage(scrollPercent);
    handleIndicator(scrollPercent);
  };

  useEffect(() => {
    const innerContent = innerContentRef.current;
    innerContent.addEventListener('scroll', updateScrollIndicator);

    return () => {
      innerContent.removeEventListener('scroll', updateScrollIndicator);
    };
  }, []);

  return (
    <div className="scrollableDiv">
      <div className="content" ref={innerContentRef}>
        <div className="inner-content">{children}</div>
      </div>
      {/* <div className="scrollTracker">
        <div
          className="scrollIndicator"
          style={{ width: `${scrollPercentage}%` }}
        ></div>
      </div> */}
    </div>
  );
};

export default ScrollTracker;
