/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchApiPostPatchNoToken } from '../helper/api';
import { useSnackbar } from 'notistack';

const Activate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { code } = useParams();

  const getActivate = async () => {
    const req = await fetchApiPostPatchNoToken('merchants/session/activate', {
      code: code,
    });
    const res = await req.json();
    if (res.meta.total > 0) {
      enqueueSnackbar('Akun berhasil diaktifkan, silahkan tunggu', {
        variant: 'success',
        preventDuplicate: true,
      });
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    } else {
      enqueueSnackbar(res.message, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  React.useEffect(() => {
    let mount = true;
    if (mount) {
      getActivate();
    }
    return () => {
      mount = false;
    };
  }, []);

  return (
    <div className="vh-100 w-100 d-flex align-items-center justify-content-center">
      <p>Prosses Activate</p>
    </div>
  );
};

export default Activate;
