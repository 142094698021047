import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import $ from 'jquery';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfThumbnail = ({ url }) => {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleRenderSuccess = () => {
    $('.react-pdf__Page__textContent').hide();
  };

  return (
    <div className="overflow-hidden">
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          pageNumber={1}
          noData={true}
          renderAnnotationLayer={false}
          height={220}
          onRenderSuccess={handleRenderSuccess}
        />
      </Document>
    </div>
  );
};

export default PdfThumbnail;
