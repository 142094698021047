/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getProfile } from '../helper/function';
import { useNavigate } from 'react-router-dom';
import { fetchApiGet } from '../helper/api';

const Caution = (props) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    const res = await fetchApiGet('merchants/session/logout');
    if (res.status === 200 || res.ok) {
      localStorage.removeItem('token');
      props.changeQbNewMessage(null);
      props.changeDataNotification(null);
      props.changeDataProfile(null);
      props.changeQbSession(null);
      props.changeQbUnread(null);
      props.setLoading(false);
      navigate('/login');
    }
  };

  const getUserData = async () => {
    const res = await getProfile();

    if (res.meta.total > 0) {
      props.changeDataProfile(res.data);
    } else {
      console.error({ res });
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (props.dataProfile) {
      if (props.dataProfile?.status === 'APPROVED') {
        navigate('/');
      }
    }
  }, [props.dataProfile]);

  return (
    <div className="wrap-content signin">
      <div className="row h-100">
        <div className="col-12 h-100 position-relative form-parent">
          <div className="d-flex align-items-center justify-content-center form-wrap px-5 px-md-0">
            <div>
              <div className="d-flex align-items-center justify-content-center mb-3 head">
                <div className="img-wrap">
                  <img src="./dist/img/logo_kisahruang.png" alt="img-logo" />
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center mb-3">
                <span className="pe-1 text-gray fs-3 text-center fw-bold">
                  Welcome Back!
                </span>
              </div>

              <div className="d-flex align-items-center justify-content-center mb-3">
                <span className="pe-1 text-gray text-center fz-14">
                  Your account has not been approved.
                  <br />
                  Please contact our support team.
                  <br />
                  Thank you for choosing to be a part of kisahruang!
                </span>
              </div>

              <div className="my-4 d-flex flex-row justify-content-center">
                <button
                  type="button"
                  className="link-dark fw-semibold btn-fill px-3 py-1 rounded"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>

              <div className="d-flex align-items-center justify-content-center mb-1">
                <span className="pe-1 text-gray text-center fw-bold">
                  Our Contacts
                </span>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                <img
                  className="icon-3 me-1"
                  src="./dist/img/custom/mail.svg"
                  alt="img-mail"
                />
                <span className="pe-1 text-secondary text-center fz-14">
                  kisahruang@mail.com
                </span>
              </div>

              <div className="ps-sm-3 pt-1 pt-sm-0 d-flex align-items-center justify-content-center">
                <img
                  className="icon-3 me-1"
                  src="./dist/img/custom/phone.svg"
                  alt="img-phone"
                />
                <span className="pe-1 text-secondary text-center fz-14">
                  081234567809
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => {
  return {
    dataProfile: state.dataProfile,
    loadingGeneral: state.loadingGeneral,
  };
};

const actionCreators = (dispatch) => {
  return {
    setLoading: (data) => dispatch({ type: 'CHANGE_LOADING', payload: data }),
    changeDataProfile: (data) =>
      dispatch({ type: 'CHANGE_DATA_PROFILE', payload: data }),
    changeDataNotification: (data) =>
      dispatch({ type: 'CHANGE_DATA_NOTIFICATION', payload: data }),
    changeQbSession: (data) =>
      dispatch({ type: 'CHANGE_QB_SESSION', payload: data }),
    changeQbUnread: (data) =>
      dispatch({ type: 'CHANGE_QB_UNREAD', payload: data }),
    changeQbNewMessage: (data) =>
      dispatch({ type: 'CHANGE_QB_NEW_MESSAGE', payload: data }),
  };
};

export default connect(mapState, actionCreators)(Caution);
