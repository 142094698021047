import React, { useState } from 'react';
import { fetchApiPost } from '../helper/api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import infoDanger from '../assets/img/custom/info-danger.svg';
import logo from '../assets/img/logo_kisahruang.png';

const Register = (props) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nama harus diisi'),
    email: Yup.string()
      .required('Email harus diisi')
      .email('Harus format email'),
    password: Yup.string().required('Password harus diisi'),
    confirmPassword: Yup.string()
      .required('Konfirmasi password wajib diisi.')
      .oneOf(
        [Yup.ref('password')],
        'Konfirmasi password harus sama dengan password.'
      ),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const [errorMessage, setErrorMessage] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const handlePassword = (type) => {
    if (type === 'password') {
      setShowPassword(!showPassword);
      return;
    }

    setShowConfirmPassword(!showConfirmPassword);
  };

  const onSubmit = async ({ name, email, password, confirmPassword }, e) => {
    e.preventDefault();
    props.setLoading(true);
    const payload = {
      name: name,
      email: email,
      password: password,
      confirm_password: confirmPassword,
    };

    const request = await fetchApiPost('merchants/session/signup', payload);
    const response = await request.json();

    if (response?.meta?.total > 0) {
      setSubmitted(true);
      props.setLoading(false);
    } else {
      props.setLoading(false);
      setErrorMessage(response?.message);
    }
  };

  return (
    <main className="register-container">
      <div className="register--form">
        <header className="mb-3">
          <div className="register--header-logo-container mb-3">
            <img
              src={logo}
              alt="app-logo"
              className="register--header-logo-img"
            />
          </div>
          {submitted ? null : (
            <h2 className="pe-1 text-gray fs-3 text-center fw-bold">
              Register
            </h2>
          )}
        </header>
        {submitted ? (
          <>
            <h3 className="text-gray text-center fw-bold">
              Please check your email
            </h3>
            <div className="d-flex align-items-center justify-content-center mt-3 fz-14 ask">
              <span className="pe-1">kembali ke halaman</span>
              <span className="">
                <Link
                  to="/login"
                  className="link-dark fw-semibold btn-fill px-3 py-1 rounded"
                >
                  Login
                </Link>
              </span>
            </div>
          </>
        ) : (
          <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <label className="form-label fz-12 fw-bold">Name</label>
              <input
                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                {...register('name')}
                type="text"
                autocomplete="off"
              />
              {errors.name && (
                <div className="invalid-feedback">{errors.name?.message}</div>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label fz-12 fw-bold">Email</label>
              <input
                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                {...register('email', {
                  required: true,
                  pattern: /^\S+@\S+$/i,
                })}
                type="text"
                autocomplete="off"
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email?.message}</div>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label fz-12 fw-bold">Password</label>
              <div
                className={`d-flex border bg-white rounded password ${
                  errors.password ? 'is-invalid' : ''
                }`}
              >
                <input
                  {...register('password')}
                  className={`form-control border-0 ${
                    errors.password ? 'is-invalid' : ''
                  }`}
                  type={showPassword ? 'text' : 'password'}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  autocomplete="off"
                />
                <span
                  className="input-group-text bg-white border-0"
                  id="basic-addon1"
                  onClick={() => handlePassword('password')}
                >
                  {showPassword ? (
                    <i className="fas fa-eye-slash text-secondary" />
                  ) : (
                    <i className="fas fa-eye text-secondary" />
                  )}
                </span>
              </div>
              {errors.password && (
                <div className="invalid-feedback">
                  {errors.password?.message}
                </div>
              )}
            </div>

            <div className="mb-5">
              <label className="form-label fz-12 fw-bold">
                Confirm Password
              </label>
              <div
                className={`d-flex border bg-white rounded password ${
                  errors.confirmPassword ? 'is-invalid' : ''
                }`}
              >
                <input
                  {...register('confirmPassword')}
                  className={`form-control border-0 ${
                    errors.confirmPassword ? 'is-invalid' : ''
                  }`}
                  type={showConfirmPassword ? 'text' : 'password'}
                  aria-label="Confirm Password"
                  aria-describedby="basic-addon1"
                  autocomplete="off"
                />
                <span
                  className="input-group-text bg-white border-0"
                  id="basic-addon1"
                  onClick={() => handlePassword('confirm_password')}
                >
                  {showConfirmPassword ? (
                    <i className="fas fa-eye-slash text-secondary" />
                  ) : (
                    <i className="fas fa-eye text-secondary" />
                  )}
                </span>
              </div>
              {errors.confirmPassword && (
                <div className="invalid-feedback">
                  {errors.confirmPassword?.message}
                </div>
              )}
            </div>

            {errorMessage && (
              <div
                className="text-danger alert alert-danger d-flex align-items-center py-2 border-0"
                role="alert"
              >
                <img src={infoDanger} style={{ width: '23px' }} alt="warning" />
                <div className="fz-14 ps-2">{errorMessage}</div>
              </div>
            )}

            <button type="submit" className="btn w-100 fw-semibold btn-fill">
              Register
            </button>
          </form>
        )}
      </div>
    </main>
  );
};

const mapState = (state) => {
  return {};
};

const actionCreators = (dispatch) => {
  return {
    setLoading: (data) => dispatch({ type: 'CHANGE_LOADING', payload: data }),
  };
};

export default connect(mapState, actionCreators)(Register);
