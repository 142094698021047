import {
  fetchApiDelete,
  fetchApiGet,
  fetchApiGetNoToken,
  fetchApiPostPatch,
  fetchApiPostToken,
  fetchApiAddItems,
  getMainUrl,
  fetchApiDeleteToken,
} from './api';

async function getProfile() {
  let req = await fetchApiGet(`merchants/session/profile`);
  const res = await req.json();
  return res;
}

const getDashboard = async () => {
  let req = await fetchApiGet(`merchants/dashboard`);
  let res = await req.json();
  return res;
};

const getNotification = async (actorType, offset) => {
  let req = await fetchApiGet(
    `merchants/dashboard/notification?actor_type=${actorType}&offset=${offset}`
  );
  let res = await req.json();
  return res;
};

const getNotificationTop = async (actorType) => {
  let req = await fetchApiGet(
    `merchants/notification/top?actor_type=${actorType}`
  );
  let res = await req.json();
  return res;
};

const updateNotification = async (id) => {
  let req = await fetchApiGet(`merchants/dashboard/notification/${id}`);
  let res = await req.json();
  return res;
};

const readAllNotification = async (id) => {
  let req = await fetchApiGet(`merchants/notification/read_all`);
  let res = await req.json();
  return res;
};

const switchActive = async (id, isActive) => {
  let param = {
    id_vendor: id,
    is_store_open: isActive,
  };
  let req = await fetchApiPostToken(`merchants/dashboard/set_active`, param);
  let res = await req.json();
  return res;
};

const getOrders = async (
  type = 'all',
  page = 0,
  limit = 2,
  search = '',
  date = '',
  dateEnd = ''
) => {
  let req = await fetchApiGet(
    `merchants/order/lists?type=${type}&offset=${page}&limit=${limit}&query=${search}&sdate=${date}&edate=${dateEnd}`
  );
  let res = await req.json();
  return res;
};

const getOrderDetail = async (id) => {
  let req = await fetchApiGet(`merchants/order/detail/${id}`);
  let res = await req.json();
  return res;
};

const rejectOrder = async (id) => {
  let param = {
    id: id,
  };
  let req = await fetchApiPostToken(`merchants/order/reject`, param);
  let res = await req.json();
  return res;
};

const confirmOrder = async (id) => {
  let param = {
    id: id,
  };
  let req = await fetchApiPostToken(`merchants/order/confirm`, param);
  let res = await req.json();
  return res;
};

const updateStatusOrder = async (id, status) => {
  let param = {
    id: id,
    status: status,
  };

  let req = await fetchApiAddItems(`merchants/order/vendor_update`, param);
  let res = await req.json();
  return res;
};

const shippedStatusOrder = async (payload) => {
  let req = await fetchApiAddItems(`merchants/order/shipped`, payload);
  let res = await req.json();
  return res;
};

const requestResolveOrder = async (payload) => {
  let param = {
    id: payload?.id,
  };

  let req = await fetchApiPostToken(`merchants/order/request_resolve`, param);
  let res = await req.json();
  return res;
};

const packedStatusOrder = async (id, shipper) => {
  let param = {
    id: id,
    shipper_order_domestic: shipper,
  };

  let req = await fetchApiPostToken(`merchants/order/processing`, param);
  let res = await req.json();
  return res;
};

const getItems = async (
  type = 'all',
  page = 0,
  limit = 2,
  search = '',
  category = 0
) => {
  let req = await fetchApiGet(
    `merchants/products/lists?type=${type}&offset=${page}&limit=${limit}&query=${search}&category=${category}`
  );
  let res = await req.json();
  return res;
};
/* Product */

async function getRoomType() {
  let req = await fetchApiGet(`merchants/room_type`);
  const res = await req.json();
  return res;
}

async function getCategory() {
  let req = await fetchApiGet(`merchant/cms/list_category_v2`);
  const res = await req.json();
  return res;
}

async function getSubCategory(categoryId) {
  let req = await fetchApiGet(`merchant/cms/list_subcategory_v2/${categoryId}`);
  const res = await req.json();
  return res;
}

async function getSubSubCategory(subCategoryId) {
  let req = await fetchApiGet(
    `merchant/cms/list_sub_subcategory_v2/${subCategoryId}`
  );
  const res = await req.json();
  return res;
}

/* End Product */

/* Category */

const getVendorCategories = async () => {
  let req = await fetchApiGet(`merchant/cms/list_category_v2`);
  let res = await req.json();
  return res;
};

/* End Category */

/* Profile */

const uploadBanner = async (data) => {
  let req = await fetchApiPostPatch(`merchants/session/update_banner`, data);
  let res = await req.json();
  return res;
};
const uploadProfilePhoto = async (data) => {
  let req = await fetchApiPostPatch(`merchants/session/update_logo`, data);
  let res = await req.json();
  return res;
};

const updateProfile = async (data) => {
  let req = await fetchApiPostPatch(`merchants/session/update`, data);
  let res = await req.json();
  return res;
};

const getBenefits = async () => {
  try {
    const req = await fetchApiGet(`merchants/session/profile-cp-benefit`);
    const res = await req.json();
    return {
      ...res,
      status: 'success',
    };
  } catch (error) {
    return { status: 'error', message: 'Network error' };
  }
};

const addBenefit = async (data) => {
  try {
    const req = await fetchApiPostToken(
      `merchants/session/profile-cp-benefit`,
      data
    );
    const res = await req.json();
    return {
      ...res,
      status: 'success',
      message: 'Benefit added successfully',
    };
  } catch (error) {
    return { status: 'error', message: 'Benefit added failed' };
  }
};

const updateBenefit = async (data) => {
  try {
    const req = await fetchApiPostPatch(
      `merchants/session/profile-cp-benefit/update/${data?.id}`,
      { name: data?.name }
    );
    const res = await req.json();
    return {
      ...res,
      status: 'success',
      message: 'Benefit updated successfully',
    };
  } catch (error) {
    return { status: 'error', message: 'Benefit update failed' };
  }
};

const deleteBenefit = async (data) => {
  try {
    const req = await fetchApiDeleteToken(
      `merchants/session/profile-cp-benefit/delete/${data?.id}`,
      {}
    );
    const res = await req.json();
    return {
      ...res,
      status: 'success',
      message: 'Benefit deleted successfully',
    };
  } catch (error) {
    return { status: 'error', message: 'Benefit delete failed' };
  }
};

/* Bank Account */

const getBankList = async () => {
  let req = await fetchApiGet('general/banks');
  let res = await req.json();
  return res;
};

const getVendorBank = async () => {
  let req = await fetchApiGet('merchants/bank/list');
  let res = await req.json();
  return res;
};

const addVendorBank = async (data) => {
  let req = await fetchApiPostToken(`merchants/bank/add`, data);
  let res = await req.json();
  return res;
};

const updateVendorBank = async (data) => {
  let req = await fetchApiPostToken(`merchants/bank/update`, data);
  let res = await req.json();
  return res;
};

const deleteVendorBank = async (data) => {
  let req = await fetchApiPostToken(`merchants/bank/delete`, data);
  let res = await req.json();
  return res;
};

const activateVendorBank = async (data) => {
  let req = await fetchApiPostToken(`merchants/bank/activation`, data);
  let res = await req.json();
  return res;
};
/* End Bank Account */

/* Courier */
const getVendorCourier = async () => {
  let req = await fetchApiGet(`merchants/courier/list`);
  let res = await req.json();
  return res;
};

const addCourier = async (data) => {
  let req = await fetchApiPostToken(`merchants/courier/add`, data);
  let res = await req.json();
  return res;
};

const editCourier = async (data) => {
  let req = await fetchApiPostPatch(`merchants/courier/edit`, data);
  let res = await req.json();
  return res;
};

const activateCourier = async (data) => {
  let req = await fetchApiPostPatch(`merchants/courier/activattion`, data);
  let res = await req.json();
  return res;
};

const activateAllCouriers = async () => {
  let req = await fetchApiGet(`merchants/courier/all/check`);
  let res = await req.json();
  return res;
};

const deleteCourier = async (data) => {
  let req = await fetchApiDelete(`merchants/courier/delete`, data);
  let res = await req.json();
  return res;
};

const getTimeSlotList = async () => {
  let req = await fetchApiGet(`shipper/order/timeslot/jakarta`);
  let res = await req.json();
  return res;
};

/* End Courier */

/* Setting - Brands */
const getVendorBrand = async () => {
  let req = await fetchApiGet(`merchants/brand/list`);
  let res = await req.json();
  return res;
};

const getVendorBrandList = async () => {
  let req = await fetchApiGet(`merchants/brand/dropdown/list`);
  let res = await req.json();
  return res;
};

const updateSettingProfile = async (payload) => {
  let req = await fetchApiPostToken(
    `merchants/session/update_setting`,
    payload
  );
  let res = await req.json();
  return res;
};
/* End Setting - Brands */

async function getProvinces() {
  let req = await fetchApiGetNoToken('merchants/cms/state/region');
  const res = await req.json();
  return res;
}

async function getCities(provinceId = 1) {
  let req = await fetchApiGetNoToken(
    `merchants/cms/state/district/${provinceId}`
  );
  const res = await req.json();
  return res;
}

async function getDistrict(cityId = 1) {
  let req = await fetchApiGetNoToken(`merchants/cms/state/township/${cityId}`);
  const res = await req.json();
  return res;
}

async function getSubdistrict(districtId = 1) {
  let req = await fetchApiGetNoToken(
    `merchants/cms/state/village/${districtId}`
  );
  const res = await req.json();
  return res;
}

async function getCourier() {
  let req = await fetchApiGet(`merchants/shipper/courier/list`);
  const res = await req.json();
  return res;
}

async function getKecamatan(id) {
  var req = await fetchApiGetNoToken('users/cms/state/city/' + id);
  const res = await req.json();
  return res;
}
async function getKelurahan(id) {
  var req = await fetchApiGetNoToken('users/cms/state/village/' + id);
  const res = await req.json();
  return res;
}

/* upload proses */

const uploadFiles = async (data) => {
  let req = await fetchApiPostPatch(`merchants/order/processing`, data);
  let res = await req.json();
  return res;
};

// Items

const addItems = async (payload) => {
  const request = await fetchApiAddItems(
    `merchants/products/save_json`,
    payload
  );
  const response = await request.json();
  return response;
};

const editItems = async (payload) => {
  const request = await fetchApiPostPatch(
    `merchants/products/update_json`,
    payload
  );
  const response = await request.json();
  return response;
};

const deleteItems = async (id) => {
  const payload = {
    id: parseInt(id),
  };
  const request = await fetchApiDelete(
    `merchants/products/delete/${id}`,
    payload
  );
  const response = await request.json();
  return response;
};

const addVariant = async (payload) => {
  const request = await fetchApiAddItems(
    `merchants/products/detail/save`,
    payload
  );
  const response = await request.json();
  return response;
};

const updateVariant = async (payload) => {
  const request = await fetchApiAddItems(
    `merchants/products/detail/update`,
    payload
  );
  const response = await request.json();
  return response;
};

const addVariantStock = async (payload) => {
  const request = await fetchApiPostToken(
    `merchants/products/stock/update`,
    payload
  );
  const response = await request.json();
  return response;
};

const deleteVariant = async (idVariance) => {
  const request = await fetchApiDeleteToken(`variant/delete/${idVariance}`, {
    id: idVariance,
  });
  const response = await request.json();
  return response;
};

const detailProduct = async (id) => {
  const request = await fetchApiGet(`merchants/products/${id}`);
  const response = await request.json();
  return response;
};

const variantList = async (id, search) => {
  const request = await fetchApiGet(
    `merchants/products/detail/lists/${id}?search=${search}`
  );
  const response = await request.json();
  return response;
};

const detailVariant = async (id) => {
  const request = await fetchApiGet(`merchants/products/variance/detail/${id}`);
  const response = await request.json();
  return response;
};

//Item Review

const getItemReview = async (payload) => {
  let req = await fetchApiGet(
    `merchants/products/review/list/${
      payload?.rating
    }?query=${encodeURIComponent(payload?.query)}&offset=${encodeURIComponent(
      payload?.offset
    )}&limit=${encodeURIComponent(
      payload?.limit
    )}&startDate=${encodeURIComponent(
      payload?.startDate
    )}&endDate=${encodeURIComponent(payload?.endDate)}`
  );
  let res = await req.json();
  return res;
};

const getProjectReview = async (payload) => {
  let req = await fetchApiGet(
    `merchants/designer/review/list/${
      payload?.rating
    }?query=${encodeURIComponent(payload?.query)}&offset=${encodeURIComponent(
      payload?.offset
    )}&limit=${encodeURIComponent(
      payload?.limit
    )}&startDate=${encodeURIComponent(
      payload?.startDate
    )}&endDate=${encodeURIComponent(payload?.endDate)}`
  );
  let res = await req.json();
  return res;
};

const replyItemReview = async (id, product_id, comment) => {
  const payload = {
    product_id: parseInt(product_id),
    product_review_id: parseInt(id),
    comment_reply: String(comment),
  };
  const request = await fetchApiPostToken(
    `merchants/products/review/reply`,
    payload
  );
  const response = await request.json();
  return response;
};

const replyProjecteview = async (id, product_id, comment) => {
  const payload = {
    review_id: parseInt(id),
    comment_reply: String(comment),
  };
  const request = await fetchApiPostToken(
    `merchants/designer/review/reply`,
    payload
  );
  const response = await request.json();
  return response;
};

// Portfolio
const getListPortfolio = async () => {
  const request = await fetchApiGet(`merchant/designer/list`);
  const response = await request.json();
  return response;
};

const getDetailPortfolio = async (id) => {
  const payload = {
    portfolioId: parseInt(id),
  };
  const request = await fetchApiPostToken(
    `merchant/designer/detailById`,
    payload
  );
  const response = await request.json();
  return response;
};

const setCoverPortfolio = async (id) => {
  const request = await fetchApiPostPatch(
    `merchant/designer/mainPortfolio/${id}/apply`,
    ''
  );
  const response = await request.json();
  return response;
};

const getListStyle = async () => {
  const payload = {
    productId: 0,
    isActive: false,
  };
  const request = await fetchApiPostToken(`merchant/product/tag`, payload);
  const response = await request.json();
  return response;
};

const getListRoomStyle = async () => {
  const request = await fetchApiGet(`merchants/master/room_style`);
  const response = await request.json();
  return response;
};

const deletePortfolio = async (id) => {
  const payload = {
    portfolioId: parseInt(id),
  };
  const request = await fetchApiPostToken(
    `merchant/designer/deleteById`,
    payload
  );
  const response = await request.json();
  return response;
};

const addPortfolio = async (payload) => {
  const request = await fetchApiPostToken(`merchant/designer/insert`, payload);
  const response = await request.json();
  return response;
};

const updatePortfolio = async (payload) => {
  const request = await fetchApiPostToken(`merchant/designer/update`, payload);
  const response = await request.json();
  return response;
};

const getListProject = async (payload) => {
  const data = {
    grupbyOrder: payload.grupbyOrder,
    search: payload.search,
    status: payload.status,
    date: payload.date,
    offset: payload.offset,
    limit: payload.limit,
  };
  const request = await fetchApiPostToken(
    `merchant/designer/project/list`,
    data
  );
  const response = await request.json();
  return response;
};

const getListProjectLatest = async (payload) => {
  const data = {
    search: payload.search,
    status: payload.status,
    date: payload.date,
    offset: payload.offset,
    limit: payload.limit,
  };
  const request = await fetchApiPostToken(
    `merchant/designer/project/latestProject`,
    data
  );
  const response = await request.json();
  return response;
};

const getListDesignConceptLatest = async () => {
  const request = await fetchApiGet(
    `merchant/designer/project/latestDesignConsept`
  );
  const response = await request.json();
  return response;
};

const getDetailProject = async (payload) => {
  const data = {
    orderNo: payload.orderNo,
    id: parseInt(payload.id),
  };
  const request = await fetchApiPostToken(
    `merchant/designer/project/detailByOrderId`,
    data
  );
  const response = await request.json();
  return response;
};

const getDetailProjectByOrderNo = async (payload) => {
  const data = {
    orderNo: payload.orderNo,
  };
  const request = await fetchApiPostToken(
    `merchant/designer/project/projectDetailByOrderNumber`,
    data
  );
  const response = await request.json();
  return response;
};

const addDesignConceptProject = async (payload) => {
  const data = {
    IdProject: parseInt(payload.IdProject),
    orderNo: payload.orderNo,
    title: payload.title,
    description: payload.description,
    designType: payload.designType,
    photos: payload.photos,
    docs: payload.docs,
  };
  const request = await fetchApiPostToken(
    `merchant/designer/project/createNewDesignConceptByOrderId`,
    data
  );
  const response = await request.json();
  return response;
};

const updateProjectNote = async (payload) => {
  const data = {
    orderNo: payload.orderNo,
    note: payload.note,
    id: parseInt(payload.id),
  };
  const request = await fetchApiPostToken(
    `merchant/designer/project/updateNotedByOrderId`,
    data
  );
  const response = await request.json();
  return response;
};

const updateProjectStatus = async (payload) => {
  const data = {
    orderNo: payload.orderNo,
    status: payload.status, // 0: reject, 1: accept
  };
  const request = await fetchApiPostToken(
    `merchant/designer/project/updateStatusByOrderId`,
    data
  );
  const response = await request.json();
  return response;
};

const approveProjectBudgetCustom = async (payload) => {
  const data = {
    order_number: payload.order_number,
  };
  const request = await fetchApiPostToken(
    `merchants/order/custom/approve`,
    data
  );
  const response = await request.json();
  return response;
};

const getDesignPlanList = async (payload) => {
  const data = {
    id: parseInt(payload.id),
    orderNo: payload.orderNo,
    designType: payload.designType ?? null,
  };
  const request = await fetchApiPostToken(
    `merchant/designer/project/detailDesignPlanByOrderId`,
    data
  );
  const response = await request.json();
  return response;
};

const getDesignPlanListCustom = async (payload) => {
  const data = {
    id: parseInt(payload.id),
    orderNo: payload.orderNo,
    designType: payload.designType ?? null,
  };
  const request = await fetchApiPostToken(
    `merchant/designer/project/custom/detailDesignPlanByOrderId`,
    data
  );
  const response = await request.json();
  return response;
};

const getDetailDesignConcept = async (payload) => {
  const data = {
    idProject: parseInt(payload.idProject),
    orderNo: payload.orderNo,
    id: parseInt(payload.id),
  };
  const request = await fetchApiPostToken(
    `merchant/designer/project/subDetailDesignPlanByOrderId`,
    data
  );
  const response = await request.json();
  return response;
};

const updateDesignConceptProject = async (payload) => {
  const data = {
    id: parseInt(payload.id),
    IdProject: parseInt(payload.IdProject),
    orderNo: payload.orderNo,
    title: payload.title,
    description: payload.description,
    photos: payload.photos,
    docs: payload.docs,
  };
  const request = await fetchApiPostToken(
    `merchant/designer/project/updateDesignConceptByOrderId`,
    data
  );
  const response = await request.json();
  return response;
};

const submitDesignConcept = async (payload) => {
  const data = {
    orderNo: payload.orderNo,
    onSubmit: payload.onSubmit,
    designType: payload.designType ?? 0,
    id: payload.id,
  };
  const request = await fetchApiPostToken(
    `merchant/designer/project/updateSubmitConcept`,
    data
  );
  const response = await request.json();
  return response;
};

const getFinalDesignList = async (payload) => {
  const data = {
    id: parseInt(payload.id),
    orderNo: payload.orderNo,
  };
  const request = await fetchApiPostToken(
    `merchant/designer/project/detailDesignFinalByOrderId`,
    data
  );
  const response = await request.json();
  return response;
};

const updateProjectQuotation = async (payload) => {
  const data = {
    orderNo: payload.orderNo,
    price: payload.price,
  };
  const request = await fetchApiPostToken(
    `merchant/designer/project/updatePriceByOrderId`,
    data
  );
  const response = await request.json();
  return response;
};

const getFinalDesignMaterial = async (payload) => {
  const data = {
    id: parseInt(payload.id),
    orderNo: payload.orderNo,
    designType: 4,
  };
  const request = await fetchApiPostToken(
    `merchant/designer/project/custom/detailDesignPlanByOrderId`,
    data
  );
  const response = await request.json();
  return response;
};

const createMaterialList = async (payload) => {
  const data = {
    IdProject: parseInt(payload.IdProject),
    orderNo: payload.orderNo,
    title: payload.title,
    description: payload.description,
    designType: payload.designType,
    photos: payload.photos,
    docs: payload.docs,
    materials: payload.materials,
  };
  const request = await fetchApiPostToken(
    `merchant/designer/project/createNewDesignConceptByOrderId`,
    data
  );
  const response = await request.json();
  return response;
};

const updateMaterialList = async (payload) => {
  const data = {
    id: parseInt(payload.id),
    IdProject: parseInt(payload.IdProject),
    orderNo: payload.orderNo,
    title: payload.title,
    description: payload.description,
    designType: payload.designType,
    photos: payload.photos,
    docs: payload.docs,
    materials: payload.materials,
  };
  const request = await fetchApiPostToken(
    `merchant/designer/project/updateDesignConceptByOrderId`,
    data
  );
  const response = await request.json();
  return response;
};

const getProjectRoomTypes = async (payload) => {
  const data = {
    orderNo: payload.orderNo,
  };
  const request = await fetchApiPostToken(
    `merchant/designer/project/roomType`,
    data
  );
  const response = await request.json();
  return response;
};

const getDataDashboardDesigner = async () => {
  const request = await fetchApiGet(
    `merchant/designer/project/countDataDashBoard`
  );
  const response = await request.json();
  return response;
};

const getFeedbackList = async (payload) => {
  const request = await fetchApiGet(
    `merchant/express_package/feedback/history/${payload?.id}`
  );
  const response = await request.json();
  return response;
};

const updateUserPassword = async (payload) => {
  const request = await fetchApiPostToken(
    `merchants/session/update/password`,
    payload
  );
  const response = await request.json();
  return response;
};

const generateUrl = (path) => {
  let mainUrl = getMainUrl();
  return `${mainUrl}/${path}`;
};

const getListTransactionVendor = async (payload) => {
  const request = await fetchApiGet(
    `merchants/design/transaction/vendor?orderType=${encodeURIComponent(
      payload?.orderType
    )}&type=${encodeURIComponent(payload?.type)}&query=${encodeURIComponent(
      payload?.query
    )}&startDate=${encodeURIComponent(
      payload?.date
    )}&endDate=${encodeURIComponent(
      payload?.dateEnd
    )}&sort=${encodeURIComponent(payload?.sort)}&offset=${encodeURIComponent(
      payload?.offset
    )}&limit=${encodeURIComponent(payload?.limit)}`
  );
  const response = await request.json();
  return response;
};

const getListTransactionDesigner = async (payload) => {
  const request = await fetchApiGet(
    `merchants/design/transaction?type=${encodeURIComponent(
      payload?.type
    )}&query=${encodeURIComponent(
      payload?.query
    )}&startDate=${encodeURIComponent(
      payload?.date
    )}&endDate=${encodeURIComponent(
      payload?.dateEnd
    )}&sort=${encodeURIComponent(payload?.sort)}&offset=${encodeURIComponent(
      payload?.offset
    )}&limit=${encodeURIComponent(payload?.limit)}`
  );
  const response = await request.json();
  return response;
};

const getListDisbursementVendor = async (payload) => {
  const request = await fetchApiGet(
    `merchants/design/disbursement?type=${encodeURIComponent(
      payload?.type
    )}&query=${encodeURIComponent(
      payload?.query
    )}&startDate=${encodeURIComponent(
      payload?.date
    )}&endDate=${encodeURIComponent(
      payload?.dateEnd
    )}&sort=${encodeURIComponent(payload?.sort)}&offset=${encodeURIComponent(
      payload?.offset
    )}&limit=${encodeURIComponent(payload?.limit)}`
  );
  const response = await request.json();
  return response;
};

const getListDisbursementVendorReal = async (payload) => {
  const request = await fetchApiGet(
    `merchants/design/disbursement/vendor?orderType=${encodeURIComponent(
      payload?.orderType
    )}&type=${encodeURIComponent(payload?.type)}&query=${encodeURIComponent(
      payload?.query
    )}&startDate=${encodeURIComponent(
      payload?.date
    )}&endDate=${encodeURIComponent(
      payload?.dateEnd
    )}&sort=${encodeURIComponent(payload?.sort)}&offset=${encodeURIComponent(
      payload?.offset
    )}&limit=${encodeURIComponent(payload?.limit)}`
  );
  const response = await request.json();
  return response;
};

const getDetailTransactionVendor = async (payload) => {
  const request = await fetchApiPostToken(
    `merchants/design/transaction/balance/vendor?orderType=${encodeURIComponent(
      payload?.orderType
    )}&startDate=${encodeURIComponent(
      payload?.date
    )}&endDate=${encodeURIComponent(payload?.dateEnd)}`,
    {
      orderType: payload?.orderType,
      startDate: payload?.date,
      endDate: payload?.dateEnd,
    }
  );
  const response = await request.json();
  return response;
};

const getDetailTransactionDesigner = async (payload) => {
  const request = await fetchApiGet(
    `merchants/design/transaction/balance?startDate=${encodeURIComponent(
      payload?.date
    )}&endDate=${encodeURIComponent(payload?.dateEnd)}`
  );
  const response = await request.json();
  return response;
};

const getDetailListTransactionVendor = async (payload) => {
  const request = await fetchApiPostToken(
    `merchants/design/transaction/balance/vendor?orderType=${encodeURIComponent(
      payload?.orderType
    )}&startDate=${encodeURIComponent(
      payload?.date
    )}&endDate=${encodeURIComponent(payload?.dateEnd)}`,
    {
      orderType: payload?.orderType,
      startDate: payload?.date,
      endDate: payload?.dateEnd,
    }
  );
  const response = await request.json();
  return response;
};

const getDetailListTransactionDesigner = async (payload) => {
  const request = await fetchApiGet(
    `merchants/design/transaction/balance?startDate=${encodeURIComponent(
      payload?.date
    )}&endDate=${encodeURIComponent(payload?.dateEnd)}`
  );
  const response = await request.json();
  return response;
};

const getDetailBalanceDesigner = async () => {
  const request = await fetchApiGet(`merchants/design/disbursement/balance`);
  const response = await request.json();
  return response;
};

const getDetailBalanceVendor = async () => {
  const data = {
    orderType: 3,
  };
  const request = await fetchApiPostToken(
    `merchants/order/disbursement/balance`,
    data
  );
  const response = await request.json();
  return response;
};

const requestDisbursementDesigner = async (payload) => {
  let data = {
    request: payload?.request,
    amount_transfer: payload?.amount_transfer,
  };
  const request = await fetchApiPostToken(
    `merchant/design/request_disbursement`,
    data
  );
  const response = await request.json();
  return response;
};

const requestDisbursementVendor = async (payload) => {
  let data = {
    request: payload?.request,
    orderType: payload?.orderType,
    amount_transfer: payload?.amount_transfer,
  };
  const request = await fetchApiPostToken(
    `merchant/design/request_disbursement/vendor`,
    data
  );
  const response = await request.json();
  return response;
};

const getTrackingListOrder = async (shipperOrderId) => {
  const request = await fetchApiGet(`shipper/order_status/${shipperOrderId}`);
  const response = await request.json();
  return response;
};

const getDialogChatList = async () => {
  let req = await fetchApiPostToken(`merchants/chat/dialog/get`);
  let res = await req.json();
  return res;
};

const joinPromo = async (payload) => {
  let data = {
    promo_id: parseInt(payload?.promo_id),
    products: payload?.products,
    type: payload?.type,
  };
  let req = await fetchApiPostToken(`merchants/cms/promo_apply_product`, data);
  let res = await req.json();
  return res;
};

const productForJoinPromoList = async (payload) => {
  let req = await fetchApiGet(
    `merchants/products/promo/join?promoId=${
      payload?.promo_id
    }&query=${encodeURIComponent(payload?.query)}&offset=${encodeURIComponent(
      payload?.offset
    )}&limit=${encodeURIComponent(payload?.limit)}`
  );
  let res = await req.json();
  return res;
};

const getListFaq = async (payload) => {
  let req = await fetchApiGet(`merchants/cms/faqByType/merchant`, payload);
  let res = await req.json();
  return res;
};

const getAllPromotions = async (payload) => {
  const request = await fetchApiGet(
    `merchants/cms/all_promo?type=${encodeURIComponent(
      payload?.type
    )}&query=${encodeURIComponent(payload?.query)}&sort=${encodeURIComponent(
      payload?.sort
    )}&offset=${encodeURIComponent(payload?.offset)}&limit=${encodeURIComponent(
      payload?.limit
    )}`
  );
  const response = await request.json();
  return response;
};

const getMyPromotions = async (payload) => {
  const request = await fetchApiGet(
    `merchants/cms/my_promo?type=${encodeURIComponent(
      payload?.type
    )}&query=${encodeURIComponent(payload?.query)}&sort=${encodeURIComponent(
      payload?.sort
    )}&offset=${encodeURIComponent(payload?.offset)}&limit=${encodeURIComponent(
      payload?.limit
    )}`
  );
  const response = await request.json();
  return response;
};

const getDetailDisbursement = async (id) => {
  const request = await fetchApiGet(
    `merchants/design/disbursement/detail/${parseInt(id)}`
  );
  const response = await request.json();
  return response;
};

const getDetailTransaction = async (id) => {
  const request = await fetchApiGet(
    `merchants/design/transaction/detail/${parseInt(id)}`
  );
  const response = await request.json();
  return response;
};

const updateStatusActiveItem = async (payload) => {
  let data = {
    isActive: payload?.isActive,
    productId: payload?.productId,
  };
  let req = await fetchApiPostToken(`merchants/product/active`, data);
  let res = await req.json();
  return res;
};

const getDetailPromotion = async (id) => {
  const request = await fetchApiGet(`merchants/cms/promo/${parseInt(id)}`);
  const response = await request.json();
  return response;
};

export {
  replyItemReview,
  getItemReview,
  shippedStatusOrder,
  packedStatusOrder,
  getListProject,
  addPortfolio,
  getListPortfolio,
  getDetailPortfolio,
  deletePortfolio,
  updatePortfolio,
  getProfile,
  getDashboard,
  getNotification,
  updateNotification,
  switchActive,
  getOrders,
  getOrderDetail,
  uploadBanner,
  uploadProfilePhoto,
  updateProfile,
  getProvinces,
  getCities,
  getDistrict,
  getSubdistrict,
  getCourier,
  getVendorCourier,
  addCourier,
  editCourier,
  activateCourier,
  deleteCourier,
  getBankList,
  getVendorBank,
  addVendorBank,
  getVendorBrand,
  getKecamatan,
  getKelurahan,
  rejectOrder,
  confirmOrder,
  updateStatusOrder,
  getItems,
  getVendorCategories,
  uploadFiles,
  addItems,
  addVariant,
  getRoomType,
  getCategory,
  getSubCategory,
  getSubSubCategory,
  deleteItems,
  getDetailProject,
  addDesignConceptProject,
  updateProjectNote,
  updateProjectStatus,
  getDesignPlanList,
  getDetailDesignConcept,
  updateDesignConceptProject,
  submitDesignConcept,
  getFinalDesignList,
  updateProjectQuotation,
  getFinalDesignMaterial,
  updateMaterialList,
  getProjectRoomTypes,
  getDataDashboardDesigner,
  getListProjectLatest,
  getListDesignConceptLatest,
  detailProduct,
  variantList,
  editItems,
  addVariantStock,
  getVendorBrandList,
  updateSettingProfile,
  updateUserPassword,
  getTimeSlotList,
  updateVendorBank,
  deleteVendorBank,
  generateUrl,
  getListStyle,
  getDetailProjectByOrderNo,
  getListTransactionVendor,
  getFeedbackList,
  getDetailTransactionVendor,
  getListRoomStyle,
  activateVendorBank,
  getDesignPlanListCustom,
  approveProjectBudgetCustom,
  getTrackingListOrder,
  detailVariant,
  updateVariant,
  getDialogChatList,
  createMaterialList,
  getListDisbursementVendor,
  getListTransactionDesigner,
  getDetailTransactionDesigner,
  requestDisbursementDesigner,
  requestDisbursementVendor,
  getListDisbursementVendorReal,
  getProjectReview,
  replyProjecteview,
  getAllPromotions,
  joinPromo,
  getListFaq,
  getMyPromotions,
  activateAllCouriers,
  getDetailDisbursement,
  productForJoinPromoList,
  requestResolveOrder,
  getDetailBalanceDesigner,
  getDetailBalanceVendor,
  updateStatusActiveItem,
  readAllNotification,
  deleteVariant,
  getDetailListTransactionVendor,
  getDetailListTransactionDesigner,
  getDetailPromotion,
  getDetailTransaction,
  getNotificationTop,
  setCoverPortfolio,
  getBenefits,
  addBenefit,
  updateBenefit,
  deleteBenefit,
};
