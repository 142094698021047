import React from 'react';
import { useEffect, useState } from 'react';
import { getOrders } from '../../helper/function';
import { useSearchParams } from 'react-router-dom';

import $ from 'jquery';
import { connect } from 'react-redux';
import SwiperInstance from 'swiper';
import OrdersTable from '../../organism/Order/OrdersTable';
import moment from 'moment';
import 'moment/locale/id';

$.datepicker = require('bootstrap-datepicker');
moment.locale('id');

const OrdersList = (props) => {
  const [queryParameters] = useSearchParams();
  const [navSwiper, setNewsSwiper] = useState();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalCurrent, setTotalCurrent] = useState(0);
  const [limit, setLimit] = useState(10);
  const [type, setType] = useState('');
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [search, setSearch] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [dateFilterEnd, setDateFilterEnd] = useState('');

  const handleSearchData = (e) => {
    if ($(e.currentTarget).prop('id') === 'button-search') {
      setSearch(
        $(e.currentTarget).parent().siblings('input#search-field').val()
      );
      setPage(0);
    }

    if (e?.key === 'Enter') {
      setSearch($('input#search-field').val());
    }

    if ($(e.currentTarget).prop('id') === 'search-field') {
      $(e.currentTarget).on('keyup', function () {});
    }
  };

  const getOrdersData = async (type, page = 0, limit = 10, search = '') => {
    if (!type) {
      return;
    }
    setIsLoadingData(true);
    const response = await getOrders(
      type,
      page,
      limit,
      search,
      dateFilter,
      dateFilterEnd
    );
    if (response.message === 'Success' && response.meta.total > 0) {
      setData(response.data);
      setTotal(response.meta.total);
      setTotalCurrent(response.data.length);
      setIsLoadingData(false);
    } else {
      setData([]);
      setIsLoadingData(false);
    }
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleChangeLimit = (limit) => {};

  const handleChangeType = (type) => {
    setType(type);
    setPage(0);
  };

  const handleResetFilter = () => {
    setType('all');
    setPage(0);
    setLimit(10);
    setSearch('');
    setDateFilter('');
    setDateFilterEnd('');
    $(document)
      .find("#filterType > li button[data-value='all']")
      .trigger('click');
    $('input[name="filterDate"]').val('');
  };

  useEffect(() => {
    getOrdersData(type, page, limit, search);
  }, [page, limit, type, search, dateFilter, dateFilterEnd]);

  useEffect(() => {
    // tab filter clicked
    $(document).find('#filterType > button').unbind('click');
    $(document).on('click', '#filterType > li button', function () {
      console.log($(this).data('value'));
      handleChangeType($(this).data('value'));
    });

    $('#datepicker').datepicker({
      templates: {
        leftArrow: '<img src="../../dist/img/custom/chevron-left.svg"></img>',
        rightArrow: '<img src="../../dist/img/custom/chevron-right.svg"></img>',
      },
    });

    $('#datepicker').on('changeDate', function (e) {
      let date = $('input[name="filterDate"]').val();
      setDateFilter(
        moment(date?.replaceAll('/', '-'), 'MM/DD/YYYY').format('YYYY-MM-DD')
      );
      setDateFilterEnd(
        moment(date?.replaceAll('/', '-'), 'MM/DD/YYYY')
          .add(1, 'days')
          .format('YYYY-MM-DD')
      );
    });

    if (!navSwiper) {
      let swiperInstance = new SwiperInstance('.swiper-nav', {
        slidesPerView: 'auto',
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });

      setNewsSwiper(swiperInstance);
    }

    $(document).find(`input[type='search'][aria-controls="table_id2"]`).hide();
    $(document)
      .find(`input[type='search'][aria-controls="table_id2"]`)
      .parent()
      .hide();

    let typeParam = queryParameters.get('type');
    if (typeParam) {
      $(document)
        .find("[data-value='" + typeParam + "']")
        .trigger('click');
    } else {
      $(document).find("[data-value='all']").trigger('click');
    }
  }, []);

  return (
    <div className="container-fluid mb-4 px-0 tab-navigasi">
      <div className="w-100 pb-3 pb-lg-4 mb-3 mb-lg-4 border-bottom border-lg-0">
        <div className="w-100 swiper-nav swiper">
          <ul
            className="nav nav-pills ff2 nav-wrap swiper-wrapper"
            id="filterType"
            role="tablist"
          >
            <li className="nav-item swiper-slide" role="presentation">
              <button
                data-value="all"
                className="nav-link"
                id="pills-all-order-tab"
                data-bs-toggle="pill"
                type="button"
                role="tab"
                aria-controls="pills-all-order"
                aria-selected="true"
                disabled={isLoadingData}
              >
                All Status
              </button>
            </li>
            <li className="nav-item swiper-slide" role="presentation">
              <button
                data-value="new"
                className="nav-link"
                id="pills-new-order-tab"
                data-bs-toggle="pill"
                type="button"
                role="tab"
                aria-controls="pills-new-order"
                aria-selected="false"
                disabled={isLoadingData}
              >
                New Order
              </button>
            </li>
            <li className="nav-item swiper-slide" role="presentation">
              <button
                data-value="confirmed"
                className="nav-link"
                id="pills-confirmed-tab"
                data-bs-toggle="pill"
                type="button"
                role="tab"
                aria-controls="pills-confirmed"
                aria-selected="false"
                disabled={isLoadingData}
              >
                Confirmed
              </button>
            </li>
            <li className="nav-item swiper-slide" role="presentation">
              <button
                data-value="processing"
                className="nav-link"
                id="pills-packed-tab"
                data-bs-toggle="pill"
                type="button"
                role="tab"
                aria-controls="pills-packed"
                aria-selected="false"
                disabled={isLoadingData}
              >
                Processing
              </button>
            </li>
            <li className="nav-item swiper-slide" role="presentation">
              <button
                data-value="pickup"
                className="nav-link"
                id="pills-pickup-tab"
                data-bs-toggle="pill"
                type="button"
                role="tab"
                aria-controls="pills-pickup"
                aria-selected="false"
                disabled={isLoadingData}
              >
                Waiting For Pickup
              </button>
            </li>
            <li className="nav-item swiper-slide" role="presentation">
              <button
                data-value="shipped"
                className="nav-link"
                id="pills-shipped-tab"
                data-bs-toggle="pill"
                type="button"
                role="tab"
                aria-controls="pills-shipped"
                aria-selected="false"
                disabled={isLoadingData}
              >
                On Delivery
              </button>
            </li>
            <li className="nav-item swiper-slide" role="presentation">
              <button
                data-value="received"
                className="nav-link"
                id="pills-shipped-tab"
                data-bs-toggle="pill"
                type="button"
                role="tab"
                aria-controls="pills-shipped"
                aria-selected="false"
                disabled={isLoadingData}
              >
                Received
              </button>
            </li>
            <li className="nav-item swiper-slide" role="presentation">
              <button
                data-value="cancelled"
                className="nav-link"
                id="pills-final-canceled-service-tab"
                data-bs-toggle="pill"
                type="button"
                role="tab"
                aria-controls="pills-final-canceled-service"
                aria-selected="false"
                disabled={isLoadingData}
              >
                Cancelled
              </button>
            </li>
            <li className="nav-item swiper-slide" role="presentation">
              <button
                data-value="complain"
                className="nav-link"
                id="pills-final-canceled-service-tab"
                data-bs-toggle="pill"
                type="button"
                role="tab"
                aria-controls="pills-final-canceled-service"
                aria-selected="false"
                disabled={isLoadingData}
              >
                Complain
              </button>
            </li>
            <li className="nav-item swiper-slide" role="presentation">
              <button
                data-value="completed"
                className="nav-link"
                id="pills-final-complete-service-tab"
                data-bs-toggle="pill"
                type="button"
                role="tab"
                aria-controls="pills-final-complete-service"
                aria-selected="false"
                disabled={isLoadingData}
              >
                Complete
              </button>
            </li>
            <li className="nav-item swiper-slide" role="presentation">
              <button
                data-value="refund"
                className="nav-link"
                id="pills-final-refund-service-tab"
                data-bs-toggle="pill"
                type="button"
                role="tab"
                aria-controls="pills-final-refund-service"
                aria-selected="false"
                disabled={isLoadingData}
              >
                Refund
              </button>
            </li>
          </ul>
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
        </div>
      </div>

      <div className="fs-5 fw-bold mt-2 mb-4">Order List</div>

      <div className="tab-content" id="pills-tabContent">
        {/* <!-- All Status --> */}
        <div
          className="tab-pane fade show active"
          id="pills-all-service"
          role="tabpanel"
          aria-labelledby="pills-all-service-tab"
        >
          {/* Loadigin data */}

          <div className="w-100 d-flex flex-column flex-lg-row justify-content-end align-items-center">
            <div className="pe-0 pe-lg-3 w-lg-100 mb-2 mb-lg-0">
              <div className="mb-2 mb-lg-0">
                <div
                  className="input-group search-product flex-nowrap border rounded-pill overflow-hidden w-lg-100"
                  style={{ width: '235px' }}
                >
                  <input
                    type="text"
                    onKeyUp={handleSearchData}
                    id="search-field"
                    className="form-control border-0 fz-14 box-shadow-focus-0"
                    placeholder="Search by order number"
                    aria-label="Username"
                    aria-describedby="addon-wrapping"
                  />
                  <div className="py-1 px-1">
                    <button
                      onClick={handleSearchData}
                      className="btn btn-secondary border-0 rounded-circle p-0 d-flex align-items-center justify-content-center"
                      type="button"
                      id="button-search"
                      style={{ width: '1.8rem', height: '1.7rem' }}
                    >
                      <img
                        alt=""
                        src={'/dist/img/custom/search.svg'}
                        style={{ width: '.9rem' }}
                        className="filter-white"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="pe-0 pe-lg-4 w-lg-100 mb-2 mb-lg-0">
              <div
                className="input-group bg-white border rounded password date w-lg-100"
                id="datepicker"
              >
                <input
                  type="text"
                  className="form-control border-0 box-shadow-focus-0"
                  placeholder="Date"
                  aria-describedby="basic-addon1"
                  style={{ fontFamily: 'Poppins' }}
                  name="filterDate"
                />
                <span className="input-group-append">
                  <span
                    className="input-group-text bg-white border-0"
                    id="basic-addon1"
                  >
                    <img
                      alt=""
                      src={'dist/img/custom/calendar.svg'}
                      className="icon-3 pt-1"
                    ></img>
                  </span>
                </span>
              </div>
            </div>
            <div className="ps-0 ps-lg-4 w-lg-100 mt-2 mt-lg-0 border-start border-lg-0">
              <button
                onClick={handleResetFilter}
                className="btn btn-line fw-bold w-lg-100"
              >
                Reset
              </button>
            </div>
          </div>
          {/* <!-- Ada Isinya --> */}
          {(data.length > 0 || isLoadingData) && (
            <div className="w-100 mt-3">
              <OrdersTable
                items={data}
                total={total}
                page={page}
                limit={limit}
                isLoadingData={isLoadingData}
                onChangePage={handleChangePage}
                onChangeLimit={handleChangeLimit}
              />
            </div>
          )}

          {/* <!-- Tidak Ada Isinya --> */}
          {data.length === 0 && isLoadingData === false && (
            <>
              {/* <!-- Empty --> */}
              <div className="w-100 empty-wrap d-flex align-items-center justify-content-center">
                <div className="d-grid px-3 px-sm-0">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="img-wrap img-wrap-empty">
                      <img alt="" src={'/dist/img/empty/empty-product.png'} />
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center mt-4">
                    <div className="fs-6 fw-bold text-center">
                      No Order Found!
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => {
  return {
    loadingGeneral: state.loadingGeneral,
  };
};

const actionCreators = (dispatch) => {
  return {
    setLoading: (data) => dispatch({ type: 'CHANGE_LOADING', payload: data }),
  };
};

export default connect(mapState, actionCreators)(OrdersList);
