import React, { useState } from 'react';
import { IsAlert } from '../../atoms/IsAlert';
import Image from '../../assets/img/custom/image.svg';

const DocUpload = ({
  id = 'file-upload',
  upload,
  samplePreviews,
  maxFile,
  maxSizePerFile,
}) => {
  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = Array.from(e.dataTransfer.files);

    if (files.length <= Number(maxFile)) {
      upload(files);
    } else {
      IsAlert.fire({
        title: 'Error',
        text: `Maximum ${maxFile} files allowed.`,
        icon: 'error',
      });
    }
  };

  const handleUpload = (e) => {
    const files = Array.from(e.target.files);
    if (files.length <= Number(maxFile)) {
      upload(files);
    } else {
      IsAlert.fire({
        title: 'Error',
        text: `Maximum ${maxFile} files allowed.`,
        icon: 'error',
      });
      e.target.value = null;
    }
  };

  return (
    <div
      className="w-100"
      id="box-images"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <label htmlFor={id} className="upload-file-custom">
        <div>
          <div className="w-100 d-flex align-items-center justify-content-center">
            <div className="img-wrap">
              <img src={Image} className="w-4rem" alt={id} />
            </div>
          </div>
          <div className="w-100 d-flex align-items-center justify-content-center mt-4">
            {isDragOver ? (
              <p className="mb-0 text-center">Drop the cv here...</p>
            ) : (
              <p className="mb-0 text-center">
                Drop your cv here, or <strong>browse</strong>
              </p>
            )}
          </div>
          <div className="w-100 d-flex align-items-center justify-content-center mt-1">
            <p className="mb-0 text-center text-secondary fz-14">
              Max. {maxSizePerFile}MB {Number(maxFile) > 1 ? ' per ' : ', 1 '}
              file with format .pdf
              {Number(maxFile) > 1 ? ` (Max. ${maxFile} files)` : ''}
            </p>
          </div>
        </div>
      </label>
      <input
        className="visually-hidden"
        id={id}
        type="file"
        accept={'application/pdf'}
        onChange={handleUpload}
        disabled={samplePreviews.length >= Number(maxFile)}
        multiple
      />
    </div>
  );
};

export default DocUpload;
